import React from "react";
import { Form, Button, Typography, Popconfirm, message } from "antd";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { updateUserInStore } from "../../redux/user/action";
import { User } from "../../types/User";

type Props = {};
const PauseAccount = (props: Props) => {
  const API = useAPI();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const paused = useAppSelector((state) => state.user.user?.paused)!;
  const [submitting, setSubmitting] = React.useState(false);
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      const data = await API.put<User>(`/users/${userId}`, {
        paused: !paused,
      });
      if (data) dispatch(updateUserInStore(data));
      setSubmitting(false);
      const word = paused ? "resumed" : "paused";
      messageApi.success(`Successfully ${word} your account`);
    } catch (error) {
      setSubmitting(false);
      console.error("Failed to pause account", error);
      messageApi.error(
        "Failed to pause account. Please try again later or contact support.",
      );
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      {contextHolder}
      <Typography.Title level={4}>Pause Account</Typography.Title>
      <Typography.Paragraph>
        {paused
          ? "You can resume your account at any time. This will resume all activity from your Duome clone."
          : `You can pause your account at any time. This will stop all activity from
        your Duome clone.`}
      </Typography.Paragraph>
      <Form.Item>
        <Popconfirm
          title="Are you sure?"
          onConfirm={form.submit}
          okText="Yes"
          okType={!paused ? "danger" : "primary"}
        >
          <Button type="primary" ghost danger={!paused} loading={submitting}>
            {paused ? "Resume Account" : "Pause Account"}
          </Button>
        </Popconfirm>
      </Form.Item>
    </Form>
  );
};

export default PauseAccount;
