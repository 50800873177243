import React from "react";
import {
  Button,
  Row,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { TweetReply } from "../../types/TweetReply";
import UserPill from "../UserPill";
import { ThemedApp } from "../../theme";
import ExportIcon from "../../icons/ExportIcon";
import moment from "moment";
import SubRightDownIcon from "../../icons/SubRightDownIcon";
import TweetTrainButton from "../TweetTrainButton";
import mixpanel from "mixpanel-browser";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import useAPI from "../../hooks/useAPI";
import TweetReplyForm from "../TweetReplyForm";
import { useAppSelector } from "../../hooks/redux";

type Props = {
  tweet?: TweetReply;
  interactive?: boolean;
  loading?: boolean;
  onUpdate?: (tweet: Partial<TweetReply>) => void;
  previewMode?: boolean;
  batchApproved?: boolean;
};
const TweetItem = ({
  tweet,
  loading,
  onUpdate,
  interactive = true,
  previewMode,
  batchApproved,
}: Props) => {
  const [deleting, setDeleting] = React.useState(false);
  const discarded =
    !previewMode &&
    (tweet?.status === "deleted" ||
      !!tweet?.deleted_at ||
      tweet?.preference_signal === false);
  const softDiscard =
    !previewMode &&
    tweet?.status === "deleted" &&
    tweet?.preference_signal === null;
  const [messageApi, contextHolder] = message.useMessage();
  const API = useAPI();
  const billingPlan = useAppSelector((state) => state.user.user?.billing_plan);
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const isProPlus = billingPlan === "pro+";
  const makeLinksExternal = (html: string) => {
    // Update all the links in the HTML code to open in a new tab
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");
    links.forEach((link, i) => {
      link.setAttribute("target", "_blank");
      if (i === links.length - 1) {
        link.style.display = "none";
      }
    });

    let finalHtml = doc.documentElement.innerHTML;
    if (tweet?.twitterUser) {
      const authorshipRegx = /(—\s.*)<a/;
      finalHtml = finalHtml.replace(authorshipRegx, "<a");
    }

    return finalHtml;
  };
  /**
   *  Highlights links, hashtags, and mentions in the given text by wrapping them in <a> tags.
   * @param content
   */
  const highlightSpecialElements = React.useCallback((content: string) => {
    const urlPattern = /(\bhttps?:\/\/\S+\b)/g;
    const hashtagPattern = /(#\w+)/g;
    const mentionPattern = /(@\w+)/g;

    // Replace URLs with <a> tags
    let highlightedContent = content.replace(
      urlPattern,
      '<a href="$1" target="_blank">$1</a>',
    );
    // Replace hashtags with <a> tags
    highlightedContent = highlightedContent.replace(
      hashtagPattern,
      '<a href="#">$1</a>',
    );
    // Replace mentions with <a> tags
    highlightedContent = highlightedContent.replace(
      mentionPattern,
      '<a href="#">$1</a>',
    );

    // Return the highlighted content
    return highlightedContent;
  }, []);
  const batchJobId = tweet?.batch_job_id;
  const handleDiscard = React.useCallback(
    async (replyId: number) => {
      try {
        setDeleting(true);
        await API.delete(`/replies/${replyId}`);
        mixpanel.track("delete_tweet_from_job", {
          tweet_reply_id: replyId,
          batch_id: batchJobId,
        });
        onUpdate?.({ id: replyId, status: "deleted" });
        setDeleting(false);
        messageApi.success("Successfully removed tweet from reply batch");
      } catch (err) {
        console.error("Failed to delete tweet", err);
        setDeleting(false);
        const error = err as any;
        const status = error.response?.status;
        if (status !== 401) {
          messageApi.error("Failed to delete tweet. Please try again later.");
        }
      }
    },
    [API, batchJobId, messageApi, onUpdate],
  );
  const handleSignalTracked = React.useCallback(
    async (replyId: number, preferenceSignal: boolean) => {
      try {
        mixpanel.track("track_preference_signal", {
          batch_id: batchJobId,
          tweet_reply_id: replyId,
          preferenceSignal,
        });
        messageApi.success("Got it! Preference successfully tracked");
        onUpdate?.({ id: replyId, preference_signal: preferenceSignal });
      } catch (err) {
        console.error("Failed to track preference signal", err);
      }
    },
    [messageApi, batchJobId, onUpdate],
  );
  const handleMuteUser = React.useCallback(async () => {
    const twitterId = tweet?.twitterUser?.twitter_id;
    const handle = tweet?.twitterUser?.handle;
    if (!twitterId || !handle) {
      return;
    }
    mixpanel.track("mute_user", {
      twitter_id: twitterId,
      handle,
    });

    if (!isProPlus) {
      messageApi.warning(
        "You must be a Pro+ customer to use the user blacklist features",
      );
      return;
    }

    try {
      await API.post(`/users/${userId}/twitter-team/mute-user`, {
        twitterId,
      });
      messageApi.success(`Successfully muted @${handle} for future batches`);
      await handleDiscard(tweet.id);
    } catch (e) {
      const err = e as any;
      const msg = err.response?.data?.message || err.message;
      console.error("Failed to mute user", err);
      messageApi.error(`Failed to mute user: ${msg}`);
    }
  }, [
    messageApi,
    isProPlus,
    handleDiscard,
    tweet?.id,
    API,
    userId,
    tweet?.twitterUser?.twitter_id,
    tweet?.twitterUser?.handle,
  ]);
  const tweetContentFromList = React.useMemo(() => {
    if (!tweet?.from_list) {
      return null;
    }

    return (
      <>
        <Typography.Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: highlightSpecialElements(tweet?.content),
            }}
          />
        </Typography.Paragraph>

        {!tweet?.twitterUser ? (
          <Typography.Paragraph>— (@{tweet?.authors})</Typography.Paragraph>
        ) : null}
      </>
    );
  }, [
    tweet?.from_list,
    tweet?.content,
    tweet?.authors,
    tweet?.twitterUser,
    highlightSpecialElements,
  ]);
  const actions = tweet
    ? [
        <TweetTrainButton
          type="like"
          key="train-like"
          replyId={tweet.id}
          pressed={tweet.preference_signal === true}
          onTracked={handleSignalTracked}
        />,
        <TweetTrainButton
          type="dislike"
          key="train-dislike"
          replyId={tweet.id}
          pressed={tweet.preference_signal === false}
          onTracked={handleSignalTracked}
        />,
        <Tooltip title="Discard this tweet">
          <Button
            type="text"
            onClick={() => handleDiscard(tweet.id)}
            disabled={batchApproved}
            icon={
              deleting ? (
                <Spin />
              ) : softDiscard ? (
                <DeleteFilled />
              ) : (
                <DeleteOutlined key="delete" />
              )
            }
            style={{ opacity: deleting ? 1 : 0.5 }}
          />
        </Tooltip>,
      ]
    : [];

  return (
    <div className="tweet-item" style={{ opacity: discarded ? 0.5 : 1 }}>
      <div className="body full-width-tweet">
        <Row align="top">
          {!loading && !tweet?.twitterUser ? null : (
            <UserPill
              author={tweet?.twitterUser}
              loading={loading}
              interactive={interactive && !discarded}
              onMute={handleMuteUser}
            />
          )}
          {interactive && !loading ? (
            <a href={tweet?.original_url} target="_blank" rel="noreferrer">
              <ThemedApp version="neutral">
                <Button
                  type="link"
                  shape="circle"
                  size="small"
                  icon={<ExportIcon style={{ color: "#656565" }} />}
                  style={{ backgroundColor: "#f8f8f8" }}
                />
              </ThemedApp>
            </a>
          ) : null}
        </Row>
        <div>
          {tweet?.under_thread ? (
            <Tag icon={<SubRightDownIcon size={11} />}>Under thread</Tag>
          ) : null}
        </div>
        <Skeleton
          active
          title={false}
          loading={loading}
          paragraph={{ rows: 3 }}
          style={{ marginTop: 12 }}
        >
          <div
            className={`iframe-tweet ${discarded ? "discarded" : ""}`}
            dangerouslySetInnerHTML={
              tweet?.from_audience
                ? {
                    __html: makeLinksExternal(tweet?.original_html),
                  }
                : undefined
            }
          >
            {tweetContentFromList}
          </div>
          {tweet?.mediaAttachments?.[0] ? (
            <img
              src={tweet?.mediaAttachments[0].url}
              alt="Tweet media"
              style={{
                display: "block",
                width: "80%",
                minWidth: 200,
                maxHeight: 400,
                objectFit: "contain",
                margin: "0 auto",
                marginTop: 8,
                marginBottom: 16,
                borderRadius: 8,
              }}
            />
          ) : null}
        </Skeleton>
        {tweet?.published_at && !loading && interactive ? (
          <Typography.Text className="smaller-text" type="secondary">
            Posted {moment(tweet?.published_at).format("lll")}
          </Typography.Text>
        ) : null}
        {contextHolder}
      </div>

      {tweet && !loading && interactive ? (
        <Row className="footer">
          {actions.map((action, i) => (
            <div className="action-button" key={i}>
              {action}
            </div>
          ))}
        </Row>
      ) : null}
      {interactive && !loading && !discarded ? (
        <TweetReplyForm
          tweet={tweet}
          onUpdate={onUpdate}
          disabled={batchApproved}
        />
      ) : null}
    </div>
  );
};

export default TweetItem;
