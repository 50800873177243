import React, { useState } from "react";
import {
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
} from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd";
import useAPI from "../../hooks/useAPI";

type Props = {
  replyId: number;
  type: "like" | "dislike";
  pressed?: boolean;
  onTracked: (replyId: number, liked: boolean) => Promise<void>;
  disabled?: boolean;
};
const TweetTrainButton = ({
  replyId,
  type,
  pressed,
  onTracked,
  disabled,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const API = useAPI();
  const handleTrackSignal = React.useCallback(async () => {
    try {
      setLoading(true);
      const preferenceSignal = type === "like";
      await API.put(`/replies/${replyId}`, {
        preferenceSignal,
      });
      onTracked(replyId, preferenceSignal);
      setLoading(false);
    } catch (err) {
      console.error("Failed to track preference signal", err);
      setLoading(false);
    }
  }, [API, onTracked, replyId, type]);
  const UnusedIcon = type === "dislike" ? DislikeOutlined : LikeOutlined;
  const UsedIcon = type === "dislike" ? DislikeFilled : LikeFilled;

  return (
    <Tooltip
      title={
        type === "like" ? "More tweets like this" : "Less tweets like this"
      }
    >
      <Button
        type="text"
        style={{ opacity: loading ? 1 : 0.5 }}
        onClick={handleTrackSignal}
        disabled={disabled}
        icon={
          loading ? (
            <Spin />
          ) : pressed ? (
            <UsedIcon type={type} />
          ) : (
            <UnusedIcon key={type} />
          )
        }
      />
    </Tooltip>
  );
};

export default TweetTrainButton;
