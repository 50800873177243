import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const ExportIcon = ({ size = 18, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83353 2.91659H4.78353C4.13013 2.91659 3.80319 2.91659 3.55363 3.04374C3.33411 3.1556 3.15576 3.33394 3.04391 3.55347C2.91675 3.80303 2.91675 4.12997 2.91675 4.78337V9.2167C2.91675 9.87009 2.91675 10.1966 3.04391 10.4462C3.15576 10.6657 3.33411 10.8444 3.55363 10.9562C3.80295 11.0833 4.12949 11.0833 4.78161 11.0833H9.21855C9.87067 11.0833 10.1967 11.0833 10.4461 10.9562C10.6656 10.8444 10.8445 10.6655 10.9564 10.446C11.0834 10.1967 11.0834 9.8705 11.0834 9.21839V8.16659M11.6667 5.24992V2.33325M11.6667 2.33325H8.75008M11.6667 2.33325L7.58341 6.41659"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {...props}
    />
  );
};

export default ExportIcon;
