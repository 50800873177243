import { useEffect, useRef } from "react";

type Callback = () => void;
export function useInterval(callback: Callback, delay?: number) {
  // Creating a ref
  const savedCallback = useRef<Callback>();

  // To remember the latest callback .
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // combining the setInterval and
  //clearInterval methods based on delay.
  useEffect(() => {
    function func() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      let id = setInterval(func, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
