import React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Typography,
  Row,
  Select,
  Space,
  Skeleton,
  Alert,
  Switch,
  Col,
} from "antd";
import useAPI from "../../hooks/useAPI";
import { TwitterAudience } from "../../types/TwitterAudience";
import { useAppSelector } from "../../hooks/redux";
import { CheckOutlined, ReloadOutlined } from "@ant-design/icons";
import mixpanel from "mixpanel-browser";
import { TwitterList } from "../../types/TwitterList";
import TweetItem from "../TweetItem";
import { Helmet } from "react-helmet";

const CUSTOM_AUDIENCE_ID = -99;
type Props = {
  onboarding?: boolean;
  onBack?: () => void;
  onNext?: () => void;
};
const PickTargetAudience = ({ onboarding, onBack, onNext }: Props) => {
  const [form] = Form.useForm();
  const [data, setData] = React.useState<TwitterAudience[]>([]);
  const [selected, setSelected] = React.useState<number | null>(null);
  const [active, setActive] = React.useState<number[]>([]);
  const [activeLists, setActiveLists] = React.useState<number[]>([]);
  const [loadingFocused, setLoadingFocused] = React.useState(false);
  const [focusedAudience, setFocusedAudience] =
    React.useState<TwitterAudience | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [subscribing, setSubscribing] = React.useState(false);
  const API = useAPI();
  const firstname = useAppSelector((state) => state.user.user?.firstname);
  const userId = useAppSelector((state) => state.user.user?.id);
  const onboardedAt = useAppSelector((state) => state.user.user?.onboarded_at);
  const billingPlan = useAppSelector((state) => state.user.user?.billing_plan);
  const isPaying = billingPlan === "pro" || billingPlan === "pro+";
  const isCustom = selected === CUSTOM_AUDIENCE_ID;
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get("/twitter-audiences");
      setData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = err as any;
      const status = error.response?.status;
      console.error("failed to load audiences", error);
      if (status !== 401) {
        message.error("Failed to load audiences");
      }
    }
  }, [API]);
  const loadUserAudience = React.useCallback(async () => {
    try {
      const data = await API.get<TwitterAudience[]>(
        `/users/${userId}/audiences`,
      );
      const firstId = data[0]?.id;
      setSelected(firstId);
      setActive(data.map((audience) => audience.id));
      if (firstId) {
        form.setFieldsValue({ audienceId: firstId });
      }
    } catch (error) {
      console.error("failed to load selected audience", error);
    }
  }, [API, form, userId]);
  const handdleToggle = (changed: any, values: any) => {
    if (changed.audienceId) setSelected(changed.audienceId);
  };
  const subscribed = React.useMemo(() => {
    return selected && active.includes(selected);
  }, [active, selected]);
  const loadUserLists = React.useCallback(async () => {
    try {
      setLoading(true);
      setActiveLists([]);
      const data = await API.get<TwitterList[]>(
        `/users/${userId}/twitter-lists`,
      );
      setLoading(false);
      setActiveLists(data.map((lists) => lists.id));
    } catch (error) {
      setLoading(false);
      console.error("failed to load subscribed Twitter Lists", error);
    }
  }, [API, userId]);
  const handleLoadFocusedAudience = React.useCallback(
    async (reload = false) => {
      try {
        if (reload) {
          mixpanel.track("reload_focused_audience", {
            audienceId: selected,
          });
        }
        setLoadingFocused(true);
        setFocusedAudience(null);
        const data = await API.get<TwitterAudience>(
          `/twitter-audiences/${selected}`,
        );
        setFocusedAudience(data);
        setLoadingFocused(false);
      } catch (error) {
        setLoadingFocused(false);
        const err = error as any;
        const msg = err.response?.data?.message || err.message;
        message.error(`Failed to load audience: ${msg}`);
        console.error("failed to load selected audience", error);
      }
    },
    [API, selected],
  );
  const handleFormSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      const data = await API.post(`/users/${userId}/audiences/onboard`, values);
      message.success(data.message);
      onNext?.();
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      const error = err as any;
      const status = error.response?.status;
      console.error("failed to set target audience", error);
      if (status !== 401) {
        message.error("Failed to set target audience");
      }
    }
  };
  const handleUnsubscribe = async () => {
    try {
      if (!selected) return;
      setSubscribing(true);
      const data = await API.delete<number[]>(
        `/users/${userId}/audiences/${selected}`,
      );
      const name = selectedAudience?.name || "audience";
      message.success(`Successfully unsubscribed to ${name}`);
      setSubscribing(false);
      if (data) setActive(data);
    } catch (err) {
      setSubscribing(false);
      const error = err as any;
      const msg = error.response?.data?.message || error.message;
      const status = error.response?.status;
      console.error("failed to set target audience", error);
      if (status !== 401) {
        message.error(`Failed to unsubscribe to audience: ${msg}`);
      }
    }
  };
  const handleSubscibe = async () => {
    try {
      if (!selected) return;
      setSubscribing(true);
      const data = await API.post<number[]>(`/users/${userId}/audiences`, {
        id: selected,
      });
      const name = selectedAudience?.name || "audience";
      message.success(`Successfully subscribed to ${name}`);
      setSubscribing(false);
      if (data) {
        setActive(data);
        if (data.length > 1) {
          mixpanel.track("subscribed_to_multiple_audiences", {
            count: data.length,
          });
        }
      }
    } catch (err) {
      setSubscribing(false);
      const error = err as any;
      const msg = error.response?.data?.message || error.message;
      const status = error.response?.status;
      console.error("failed to set target audience", error);
      if (status !== 401) {
        message.error(`Failed to subscribe: ${msg}`);
      }
      if (error.response?.data?.needsUpgrade) {
        mixpanel.track("needs_plan_upgrade", {
          feature: "audience_subscription",
        });
      }
    }
  };

  const selectedAudience = React.useMemo(() => {
    return data.find((audience) => audience.id === selected);
  }, [data, selected]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);
  React.useEffect(() => {
    if (!onboarding) {
      loadUserAudience();
      loadUserLists();
    }
  }, [loadUserAudience, onboarding, loadUserLists]);
  React.useEffect(() => {
    if (selected && !isCustom) {
      handleLoadFocusedAudience();
    }
  }, [handleLoadFocusedAudience, selected, isCustom]);
  const hasNoAudience =
    !onboarding && active.length === 0 && activeLists.length === 0;
  const notLoading = !submitting && !subscribing && data.length > 0;

  return (
    <div className="target-audience">
      <Helmet>
        <title>Target Audience - Duome</title>
      </Helmet>
      {hasNoAudience && notLoading ? (
        <Alert
          message="You are not subscribed to any audience"
          description="This means you will not get any batches or replies generated. Please subscribe to at least one"
          type="warning"
          showIcon
        />
      ) : null}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFormSubmit}
        onValuesChange={handdleToggle}
      >
        <div>
          <Typography.Paragraph>Audience:</Typography.Paragraph>
          <Row>
            <Form.Item name="audienceId" style={{ flex: 1, marginRight: 24 }}>
              <Select
                placeholder="Select target audience"
                loading={loading}
                disabled={submitting || subscribing}
              >
                {data.map((audience) => (
                  <Select.Option key={audience.id} value={audience.id}>
                    {active.includes(audience.id) ? (
                      <CheckOutlined style={{ fontSize: 12, marginRight: 8 }} />
                    ) : (
                      ""
                    )}
                    {audience.user_id ? "-- " : ""}
                    {audience.name}
                  </Select.Option>
                ))}
                <Select.Option
                  key={CUSTOM_AUDIENCE_ID}
                  value={CUSTOM_AUDIENCE_ID}
                >
                  + Request new audience
                </Select.Option>
              </Select>
            </Form.Item>
            {!onboarding && !isCustom ? (
              <Button
                type="primary"
                htmlType="button"
                ghost={!!subscribed}
                loading={subscribing}
                disabled={!selected}
                onClick={subscribed ? handleUnsubscribe : handleSubscibe}
                icon={subscribed ? <CheckOutlined /> : null}
              >
                {subscribed ? "Subscribed" : "Subscribe"}
              </Button>
            ) : null}
          </Row>
          <Row>
            <Typography.Text>{selectedAudience?.description}</Typography.Text>
            {selectedAudience?.user_id ? (
              <Typography.Text type="secondary">
                (Created exclusively for you, {firstname}. No one else has
                access to it.)
              </Typography.Text>
            ) : null}
          </Row>
        </div>
        {isCustom ? (
          <div>
            <Form.Item
              name="request"
              label="Audience request:"
              rules={[
                {
                  required: true,
                  message: "Please describe the audience you want to reach",
                },
                {
                  min: 80,
                  message:
                    "Please provide more details about your audience. Such as topics frequently discussed, etc...",
                },
                {
                  max: 200,
                  message: "Please keep it under 200 characters",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="I am focused on engaging with nature enthusiasts and people who are into mountain activities. They typically are talking about biking races, biking trails, hikes and camping"
                showCount
                maxLength={200}
              />
            </Form.Item>
            {onboardedAt ? (
              <Row>
                <Form.Item name="isPrivateAudience" valuePropName="checked">
                  <Switch defaultChecked={false} />
                </Form.Item>
                <Col style={{ marginLeft: 8 }}>
                  <Typography.Text>Private audience</Typography.Text>
                  <Typography.Paragraph type="secondary">
                    Only visible to you. Useful to promote your services, etc...
                  </Typography.Paragraph>
                </Col>
              </Row>
            ) : null}
            {!isPaying && isCustom ? (
              <Alert
                message="Only available for premium users"
                description="Requesting custom audiences is a paid-only feature. If you sign up on a free plan, your request may still be processed if it is popular, but there is no guarantee."
                type="warning"
                showIcon
              />
            ) : null}
          </div>
        ) : null}
        <br />
        {onboarding || isCustom ? (
          <Form.Item>
            <Row justify="space-between">
              {onboarding ? (
                <Button type="default" disabled={!onBack}>
                  Back
                </Button>
              ) : null}
              <Button
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={onboarding && !onNext}
              >
                {isCustom
                  ? "Submit request"
                  : onboarding
                  ? "Save and continue"
                  : "Save"}
              </Button>
            </Row>
          </Form.Item>
        ) : null}
      </Form>
      {selected && !isCustom ? (
        <div>
          <Space direction="vertical">
            <Row>
              <Space align="center">
                <Typography.Text strong>Examples</Typography.Text>
                <Button
                  onClick={() => handleLoadFocusedAudience(true)}
                  icon={<ReloadOutlined />}
                  loading={loadingFocused}
                  size="small"
                />
              </Space>
            </Row>
            <Typography.Paragraph type="secondary">
              Your personal filter rules are not applied to the sample below.
            </Typography.Paragraph>
          </Space>
          <Space
            className="full-width-tweet"
            direction="vertical"
            style={{ marginTop: 24, width: "100%" }}
          >
            {loadingFocused
              ? Array.from({ length: 3 }).map(() => {
                  const rows = Math.round(Math.random() * 2) + 1;
                  return <Skeleton active paragraph={{ rows }} />;
                })
              : null}
            {focusedAudience?.examples?.map((example) => (
              <TweetItem
                key={example.id}
                interactive={false}
                previewMode
                tweet={example as any}
              />
            ))}
          </Space>
        </div>
      ) : null}
    </div>
  );
};

export default PickTargetAudience;
