import React from "react";
import { Button, List } from "antd";
import useAPI from "../../hooks/useAPI";
import { BatchJob } from "../../types/BatchJob";
import BatchHeader from "../../components/BatchHeader";
import TweetItem from "../../components/TweetItem";
import { PaginationMeta } from "../../types/PaginatonMeta";
import { Link } from "react-router-dom";
import { ThemedApp } from "../../theme";
import { Helmet } from "react-helmet";

const BatchJobsList: React.FC = () => {
  const API = useAPI();
  const [batches, setBatches] = React.useState<BatchJob[]>([]);
  const [meta, setMeta] = React.useState<PaginationMeta | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [paginationLoad, setPaginationLoad] = React.useState({
    page: 1,
    limit: 10,
  });
  const loadBatches = React.useCallback(async () => {
    setLoading(true);
    try {
      const { data, meta } = await API.get(`/batch-jobs`, {
        params: paginationLoad,
      });
      setBatches(data);
      setMeta(meta);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to load batch jobs", error);
    }
  }, [API, paginationLoad]);
  React.useEffect(() => {
    loadBatches();
  }, [loadBatches]);

  return (
    <div>
      <Helmet>
        <title>Batch History - Duome</title>
      </Helmet>
      <Link to="/feed">
        <ThemedApp version="neutral">
          <Button type="primary" style={{ marginTop: 12, marginBottom: 12 }}>
            Back to feed
          </Button>
        </ThemedApp>
      </Link>
      <List
        renderItem={(item) => <BatchHeader batch={item} clickable />}
        rowKey={(item) => item.id}
        dataSource={batches}
        itemLayout="vertical"
        split={false}
        loading={loading}
        pagination={{
          current: meta?.current_page || paginationLoad.page,
          total: meta?.total || 0,
          pageSize: meta?.per_page || paginationLoad.limit,
          showSizeChanger: true,
          onChange: (page, limit) => {
            setPaginationLoad({ page, limit });
            setTimeout(
              () => window.scrollTo({ top: 0, behavior: "smooth" }),
              100,
            );
          },
        }}
        footer={
          <div className="sub-footer">
            <Link to="/feed">
              <ThemedApp version="neutral">
                <Button type="primary">Back to feed</Button>
              </ThemedApp>
            </Link>
          </div>
        }
      />
      {loading && batches.length === 0 ? (
        <List
          renderItem={() => <TweetItem loading />}
          dataSource={[1, 2, 3, 4]}
          rowKey={(item) => item}
          itemLayout="vertical"
          className="tweet-item-list"
          split={false}
          style={{ marginTop: 24 }}
        />
      ) : null}
    </div>
  );
};

export default BatchJobsList;
