import React from "react";
import { Row, Col, Card, Typography, Button } from "antd";
import { Helmet } from "react-helmet";

const GrowMore: React.FC = () => {
  return (
    <div className="settings-container" style={{ padding: 24 }}>
      <Helmet>
        <title>Grow More - Duome</title>
      </Helmet>
      <Row justify="start">
        <Col offset={1} sm={20} md={18} lg={14} xl={12} xxl={10}>
          <Card title="Growing further">
            <Typography.Paragraph>
              Join an exclusive community of growth hackers and become part of
              an engagement group that is guaranteed to skyrocket your reach to
              millions of users on X.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Use promo code <b>DUOMEVIP</b> to get 20% discount.
            </Typography.Paragraph>
            <a
              href="https://gumroad.com/a/397141011/rdzxx"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primary">Open now</Button>
            </a>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GrowMore;
