import React from "react";
import {
  Button,
  Card,
  Col,
  Layout,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import useAPI from "../../hooks/useAPI";
import { useLocation, useNavigate } from "react-router";
import { ThemedApp } from "../../theme";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import SettingsCard from "../../components/SettingsCard";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";

const { Title, Paragraph, Text } = Typography;

const AcceptInvite: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [showDemoVideo, setShowDemoVideo] = React.useState(false);
  const [friendName, setFriendName] = React.useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const API = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const inviteToken = searchParams.get("ref");
  const verify = React.useCallback(async () => {
    try {
      setLoading(true);
      setFriendName("");
      const data = await API.get(`/verify-referral?ref=${inviteToken}`);
      setLoading(false);
      setFriendName(data.firstname);
      mixpanel.track("load_invite_credit", {
        referred_by: data.id,
      });
    } catch (error) {
      setFriendName("");
      setLoading(false);
      const obj = error as any;
      const err = obj.response?.data || obj;
      const msg = err.message || "Failed to verify invite";
      console.error("Failed to verify invite", err);
      messageApi.error(`Cannot verify invite: ${msg}`, 5);
    }
  }, [API, inviteToken, messageApi]);
  const handleClaimCredit = () => {
    mixpanel.track("claim_invite_credit", {
      code: inviteToken,
    });
    localStorage.setItem("referralCode", inviteToken!);
    navigate("/signup");
  };
  React.useEffect(() => {
    verify();
  }, [verify]);
  const videoCard = (
    <Col xxl={20} sm={22} style={{ margin: "0 auto" }}>
      <SettingsCard
        title="What is Duome?"
        subtitle="This is a quick demo of Duome, the AI clone for driving 5x engagement on Twitter"
      >
        <div>
          <div
            style={{
              position: "relative",
              paddingBottom: "64.5933014354067%",
              height: 0,
            }}
          >
            <iframe
              title="Demo video"
              src="https://www.loom.com/embed/cdbc05a8604b4f88bbf10b5e9939a14c?sid=b99cb217-6b53-4ba0-8aee-7e30f32d199a"
              frameBorder="0"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </div>
        <Row justify="center">
          <Button
            type="primary"
            onClick={handleClaimCredit}
            style={{ marginTop: 16 }}
          >
            Sign up now
          </Button>
        </Row>
      </SettingsCard>
    </Col>
  );
  const validCard = (
    <Col xxl={13} lg={15} md={15} sm={16} style={{ margin: "0 auto" }}>
      <Card
        hoverable
        cover={
          <img
            src="/img/high-five.gif"
            alt="High Five"
            style={{ maxHeight: 300, objectFit: "cover" }}
          />
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            Welcome to Duome!
          </Typography.Title>
          <Text style={{ lineHeight: 1.75 }}>
            <b>{friendName}</b> has gifted you{" "}
            <Tag className="credit-amount" style={{ bottom: 2 }}>
              <b>$15</b> credit
            </Tag>{" "}
            to get started. Press below to claim your gift now and starting
            growing your Twitter audience.
          </Text>
          <Space direction="vertical" style={{ width: "100%", marginTop: 12 }}>
            <Button type="primary" block onClick={handleClaimCredit}>
              Claim my credits
            </Button>
            <ThemedApp version="neutral">
              <Button
                type="primary"
                block
                onClick={() => setShowDemoVideo(true)}
              >
                What is Duome?
              </Button>
            </ThemedApp>
          </Space>
        </Space>
      </Card>
    </Col>
  );

  const invalidCard = (
    <Col xxl={13} lg={15} md={15} sm={16} style={{ margin: "0 auto" }}>
      <Card
        loading={loading}
        hoverable
        cover={
          <img
            src="/img/confused.gif"
            alt="Confused"
            style={{ maxHeight: 300, objectFit: "cover" }}
          />
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            Something is not right...
          </Typography.Title>
          <Text style={{ lineHeight: 1.75 }}>
            Ohoh! We couldn't verify your invite. Please check the link or
            contact your friend to resend it.
          </Text>
          <Space direction="vertical" style={{ width: "100%", marginTop: 12 }}>
            <ThemedApp version="neutral">
              <Button
                type="primary"
                block
                onClick={() => setShowDemoVideo(true)}
              >
                What is Duome?
              </Button>
            </ThemedApp>
          </Space>
        </Space>
      </Card>
    </Col>
  );

  return (
    <Layout style={{ minHeight: "100vh" }} className="settings-container">
      <Helmet>
        <title>Your VIP invite to Duome</title>
      </Helmet>
      <Row justify="center">
        <Col xxl={11} xl={12} lg={14} md={16} sm={20} xs={22}>
          <div style={{ textAlign: "center" }}>
            <Title>Having friends in high places pays.</Title>
            <Paragraph>
              Your friend {friendName} is inviting you to start growing your
              Twitter using Duome. Since they personally vouched for you, here
              is a little gift from us.
            </Paragraph>
          </div>
          {showDemoVideo ? videoCard : friendName ? validCard : invalidCard}
          <Row justify="center">
            {!showDemoVideo ? (
              <a href="https://duome.ai">
                <Button type="link" style={{ marginTop: 16 }}>
                  Open home page
                </Button>
              </a>
            ) : (
              <Button
                type="link"
                icon={<ArrowLeftIcon />}
                style={{ marginTop: 16 }}
                onClick={() => setShowDemoVideo(false)}
              >
                Back to invite
              </Button>
            )}
          </Row>
        </Col>
        {contextHolder}
      </Row>
    </Layout>
  );
};

export default AcceptInvite;
