import { ResponseCategory } from "../../types/ResponseCategory";
import { TwitterSettings } from "../../types/TwitterSetting";
import { User } from "../../types/User";
import { ActivityData, UserUsageLimits } from "../../types/ActivityData";
import { TwitterUser } from "../../types/TwitterUser";

export type UserState = {
  user: null | User;
  twitterSettings: null | TwitterSettings;
  categories: ResponseCategory[];
  lastSeenRelease?: string;
  pipelineIsFull?: boolean;
  scheduledForToday?: number;
  usageLimits?: UserUsageLimits | null;
  isWithinSchedule?: boolean;
  lastHideActivity?: string;
  activityOverviewCollapsed?: boolean;
  activity?: ActivityData;
  twitterUser?: TwitterUser;
  defaultChunkSize?: number;
  lastOutage?: string | null;
};

export enum UserActionTypes {
  SET_USER = "SET_USER",
  SET_TWITTER_SETTING = "SET_TWITTER_SETTING",
  UPDATE_TWITTER_SETTING = "UPDATE_TWITTER_SETTING",
  UPDATE_USER = "UPDATE_USER",
  LOG_OUT = "LOG_OUT",
  ONBOARDED = "ONBOARDED",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_LAST_SEEN_RELEASE = "SET_LAST_SEEN_RELEASE",
  SET_PIPELINE_STATUS = "SET_PIPELINE_STATUS",
  SET_SCHEDULED_FOR_TODAY = "SET_SCHEDULED_FOR_TODAY",
  SET_USAGE_LIMITS = "SET_USAGE_LIMITS",
  SET_IS_WITHIN_SCHEDULE = "SET_IS_WITHIN_SCHEDULE",
  SET_LAST_HIDE_ACTIVITY = "SET_LAST_HIDE_ACTIVITY",
  SET_ACTIVITY_OVERVIEW_COLLAPSED = "SET_ACTIVITY_OVERVIEW_COLLAPSED",
  SET_ACTIVITY_DATA = "SET_ACTIVITY_DATA",
  SET_TWITTER_USER = "SET_TWITTER_USER",
  SET_DEFAULT_CHUNK_SIZE = "SET_DEFAULT_CHUNK_SIZE",
  SET_LAST_OUTAGE = "SET_LAST_OUTAGE",
}

export const setUserInStore = (user: User) => ({
  type: UserActionTypes.SET_USER,
  payload: user,
});

export const updateUserInStore = (user: Partial<User>) => ({
  type: UserActionTypes.UPDATE_USER,
  payload: user,
});

export const logOutInStore = () => ({
  type: UserActionTypes.LOG_OUT,
});

export const setCategoriesInStore = (categories: ResponseCategory[]) => ({
  type: UserActionTypes.SET_CATEGORIES,
  payload: categories,
});

export const setLastSeenReleaseInStore = (releaseDate: string) => ({
  type: UserActionTypes.SET_LAST_SEEN_RELEASE,
  payload: releaseDate,
});

export const setPipelineStatusInStore = (isFull: boolean) => ({
  type: UserActionTypes.SET_PIPELINE_STATUS,
  payload: isFull,
});

export const setScheduledForTodayInStore = (count: number) => ({
  type: UserActionTypes.SET_SCHEDULED_FOR_TODAY,
  payload: count,
});

export const setIsWithinScheduleInStore = (isWithinSchedule: boolean) => ({
  type: UserActionTypes.SET_IS_WITHIN_SCHEDULE,
  payload: isWithinSchedule,
});

export const setUsageLimitsInStore = (limits: UserUsageLimits | null) => ({
  type: UserActionTypes.SET_USAGE_LIMITS,
  payload: limits,
});

export const setTwitterSettingInStore = (settings: TwitterSettings) => ({
  type: UserActionTypes.SET_TWITTER_SETTING,
  payload: settings,
});

export const updateTwitterSettingInStore = (
  settings: Partial<TwitterSettings>,
) => ({
  type: UserActionTypes.UPDATE_TWITTER_SETTING,
  payload: settings,
});

export const setLastHideActivityInStore = (date: string) => ({
  type: UserActionTypes.SET_LAST_HIDE_ACTIVITY,
  payload: date,
});

export const setActivityOverviewCollapsedInStore = (collapsed: boolean) => ({
  type: UserActionTypes.SET_ACTIVITY_OVERVIEW_COLLAPSED,
  payload: collapsed,
});

export const setActivityDataInStore = (data: ActivityData) => ({
  type: UserActionTypes.SET_ACTIVITY_DATA,
  payload: data,
});

export const setTwitterUserInStore = (user: TwitterUser) => ({
  type: UserActionTypes.SET_TWITTER_USER,
  payload: user,
});

export const setDefaultChunkSizeInStore = (size: number) => ({
  type: UserActionTypes.SET_DEFAULT_CHUNK_SIZE,
  payload: size,
});

export const setLastOutageInStore = (date: string | null) => ({
  type: UserActionTypes.SET_LAST_OUTAGE,
  payload: date,
});
