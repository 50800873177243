import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { logOutInStore, setUserInStore } from "../../redux/user/action";
import mixpanel from "mixpanel-browser";

type Props = {
  children?: React.ReactNode;
};
const GatedForLoggedUser: React.FC = ({ children }: Props) => {
  const navigate = useNavigate();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const loadUserData = React.useCallback(
    async (userId: number) => {
      try {
        const user = await API.get(`/users/me`);
        dispatch(setUserInStore(user));
        if (!user.onboarded_at) {
          navigate(`/onboarding`);
        }
      } catch (error) {
        console.error("Failed to validate auth cookie", error);
        dispatch(logOutInStore());
        navigate(`/login`);
      }
    },
    [dispatch, navigate, API],
  );
  useEffect(() => {
    if (user?.id) {
      loadUserData(user.id);
    } else {
      navigate(`/login`);
    }
  }, [user?.id, loadUserData, navigate]);
  React.useEffect(() => {
    if (user?.id) {
      mixpanel.identify(user.id.toString());
      mixpanel.people.set({
        user_id: user.id,
        $email: user.email,
        $first_name: user.firstname,
        $last_name: user.lastname,
        $created: user.created_at,
        $avatar: user.profile_image_url,
        usage_mode: user.usage_mode,
        plan: user.billing_plan,
        auto_approve: user.auto_approve,
      });
    }
  }, [
    user?.id,
    user?.email,
    user?.firstname,
    user?.lastname,
    user?.created_at,
    user?.profile_image_url,
    user?.usage_mode,
    user?.billing_plan,
    user?.auto_approve,
  ]);

  return user ? <Outlet /> : null;
};

export default GatedForLoggedUser;
