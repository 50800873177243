import React from "react";
import {
  Form,
  Upload,
  Button,
  UploadProps,
  message,
  Typography,
  Divider,
  Row,
  Alert,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  UploadRequestOption,
  UploadProgressEvent,
} from "rc-upload/lib/interface";
import useAPI from "../../hooks/useAPI";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { useAppSelector } from "../../hooks/redux";

type Props = {
  onBack: () => void;
  onNext: () => void;
};
const UploadTrainingData = ({ onBack, onNext }: Props) => {
  const API = useAPI();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState(false);
  const [skipping, setSkipping] = React.useState(false);
  const userId = useAppSelector((state) => state.user.user!.id);
  const getUploadedUri = React.useCallback((field: any) => {
    try {
      return field.fileList.map((file: any) => file.response.path) as string[];
    } catch (error) {
      console.error("failed to get upload url", error);
      return [];
    }
  }, []);
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      const files = getUploadedUri(values.csvFiles);
      if (files.length === 0) {
        message.error("Please upload at least one file");
        return;
      }
      await API.post("/twitter/fine-tune", { files });
      setSubmitting(false);
      message.success(
        "Training job has started... It will finish within 5-10 minutes",
      );
      onNext();
    } catch (err) {
      setSubmitting(false);
      const error = err as any;
      const status = error.response?.status;
      console.error("failed to set upload training data", error);
      if (status !== 401) {
        message.error(
          "Failed to set upload training data. Please try again or contact support.",
        );
      }
    }
  };
  const handleSkipTraining = React.useCallback(async () => {
    try {
      setSkipping(true);
      await API.post("/twitter/skip-fine-tune");
      setSkipping(false);
      message.info(
        "Training skipped! You will receive our default clone's writing style.",
      );
      onNext?.();
    } catch (err) {
      setSkipping(false);
      const error = err as any;
      const status = error.response?.status;
      console.error("failed to set upload training data", error);
      if (status !== 401) {
        message.error(
          "Failed to set training parameters. Please try again or contact support.",
        );
      }
    }
  }, [API, onNext]);
  const handleUploadFiles = async (option: UploadRequestOption<any>) => {
    progressiveFileUpload(option);
  };
  const progressiveFileUpload = React.useCallback(
    (option: UploadRequestOption<any>) => {
      const { file } = option;
      try {
        const storage = getStorage();
        const path = ["private/tweet_history", userId, (file as any).name].join(
          "/",
        );
        const imgRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(imgRef, file as any, {
          contentType: (file as any).type,
        });
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const uploadEvent: UploadProgressEvent = {
              percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            };
            option.onProgress?.(uploadEvent);
          },
          option.onError,
          async () => {
            try {
              const url = await getDownloadURL(imgRef);
              option.onSuccess?.({ url, path });
            } catch (err) {
              const error = err as Error;
              option.onError?.(error);
            }
          },
        );
      } catch (err) {
        const error = err as Error;
        console.error(error);
        option.onError?.(error);
      }
    },
    [userId],
  );
  const props: UploadProps = {
    name: "history",
    multiple: true,
    customRequest: handleUploadFiles,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const isNotDesktop = window.innerWidth < 768;

  if (isNotDesktop) {
    const message = `This feature is not available on mobile devices. Please use a desktop to upload your tweet history later.`;
    return (
      <div>
        <Typography.Title level={3}>Train your AI clone</Typography.Title>
        <Alert message={message} type="warning" showIcon />
        <Button
          type="primary"
          onClick={handleSkipTraining}
          loading={skipping}
          disabled={submitting}
        >
          Continue
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Typography.Title level={3}>Train your AI clone</Typography.Title>
      <Typography.Text>
        Uploading your tweet history will help your clone learn how you write
        and replicate your style as close as possible. Watch the video below to
        see how that's done.
      </Typography.Text>
      <div style={{ position: "relative", paddingBottom: "62.5%", height: 0 }}>
        <iframe
          title="Loom tutorial video"
          src="https://www.loom.com/embed/c23ecc2595f24a6ebf3670ddd6f451e2?sid=52f5b8e0-641f-4d64-9da5-9eace907b6c3"
          frameBorder={0}
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "70%",
            height: "100%",
          }}
        ></iframe>
      </div>
      <Divider />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="csvFiles"
          label="Tweet history:"
          rules={[
            {
              required: true,
              message: "Please upload a .csv file of your tweet history",
            },
          ]}
        >
          <Upload.Dragger name="csvFiles" accept=".csv" {...props}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              You may upload one or more .csv file(s)
            </p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item>
          <Row justify="space-between">
            <Button
              type="default"
              onClick={onBack}
              disabled={skipping || submitting}
            >
              Back
            </Button>
            <Space>
              <Button
                onClick={handleSkipTraining}
                loading={skipping}
                disabled={submitting}
              >
                Skip for now
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={skipping}
              >
                Save and continue
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UploadTrainingData;
