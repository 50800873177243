import React from "react";
import { ConfigProvider, ThemeConfig } from "antd";
import { Outlet } from "react-router";
import merge from "lodash/merge";

type Props = {
  version?: "primary" | "secondary" | "neutral";
  children?: React.ReactNode;
};
export const ThemedApp = ({ version, children }: Props) => {
  if (!version) {
    return <ConfigProvider>{children ? children : <Outlet />}</ConfigProvider>;
  }
  const defaultTheme: ThemeConfig = {
    token: {
      colorPrimary: "#64d16e",
      colorInfo: "#64d16e",
      colorTextBase: "#212121",
      colorSuccess: "#64d16e",
      sizeStep: 6,
      sizeUnit: 4,
      borderRadius: 8,
      fontFamily: "Albert Sans",
    },
    components: {
      Button: {
        borderRadius: 1600,
        borderRadiusLG: 1600,
        paddingContentHorizontal: 32,
        controlHeight: 42,
        controlHeightLG: 55,
        controlHeightSM: 27,
        fontSizeLG: 16,
        paddingInline: 24,
        fontWeight: 500,
        marginXS: 8,
        paddingXS: 8,
        /*
        defaultShadow: "none",
        defaultBorderColor: "rgb(241, 241, 241)",
        defaultBg: "rgb(241, 241, 241)",
        defaultGhostColor: "rgb(82, 196, 26)",

        // Hover default
        defaultHoverColor: "#212121",
        defaultHoverBorderColor: "#ebebeb",
        defaultHoverBg: "#ebebeb",

        // active default
        defaultActiveColor: "#212121",
        defaultActiveBorderColor: "#dddddd",
        defaultActiveBg: "#dddddd",*/
      },
      Alert: {
        withDescriptionIconSize: 18,
        defaultPadding: "12px 16px",
        withDescriptionPadding: "12px 16px",
        marginSM: 12,
        marginXS: 12,
      },
      Space: {
        paddingXS: 8,
      },
      Segmented: {
        controlHeightLG: 50,
        fontSizeLG: 16,
      },
      Input: {
        paddingBlock: 10,
        paddingBlockLG: 8,
        controlHeightLG: 50,
        controlHeight: 43,
        inputFontSize: 14,
      },
      Select: {
        controlHeight: 38,
      },
      Card: {
        colorBorderSecondary: "transparent",
        fontWeightStrong: 500,
        headerFontSize: 32,
        borderRadiusLG: 24,
      },
      Layout: {
        // TODO: The below is only for feed pages. In normal times, we use F8F8F8
        bodyBg: "#fbfbfb",
        footerBg: "#fbfbfb",
        headerBg: "#232323",
        headerPadding: "0 32px",
        siderBg: "#062900",
        triggerBg: "#004010",
      },
      Pagination: {
        marginSM: 20,
        marginXXS: 4,
        marginXS: 8,
      },
      Form: {
        labelHeight: 42,
        marginXXS: 4,
        marginLG: 16,
        verticalLabelPadding: "0 0 8px",
      },
      Tag: {
        defaultBg: "#f8f8f8",
        defaultColor: "#4f4f4f",
        colorBorder: "#dddddd",
        borderRadiusSM: 8,
        fontSizeIcon: 11,
        fontSizeSM: 12,
      },
      Menu: {
        controlHeightLG: 50,
        itemMarginBlock: 8,
        darkItemBg: "#232323",
      },
      Checkbox: {
        borderRadiusSM: 4,
      },
    },
  };
  const tokenMap: Record<typeof version, ThemeConfig> = {
    primary: {
      token: {
        colorPrimary: "#64d16e",
        colorInfo: "#64d16e",
        colorTextBase: "#212121",
        colorTextSecondary: "#ababab",
      },
    },
    neutral: {
      token: {
        colorPrimary: "#ebebeb",
        colorTextBase: "#212121",
      },
      components: {
        Button: {
          colorPrimaryActive: "#f1f1f1",
          colorTextLightSolid: "#212121",
        },
      },
    },
    secondary: {
      token: {
        colorPrimary: "#212121",
      },
      components: {
        Button: {
          defaultShadow: "none",
          primaryShadow: "none",
        },
        Segmented: {
          borderRadiusLG: 1600,
          borderRadius: 1600,
          itemSelectedBg: "#64d16e",
          itemSelectedColor: "#fff",
          trackBg: "#212121",
          itemHoverColor: "#ddd",
        },
      },
    },
  };
  const theme = merge(defaultTheme, tokenMap[version]);

  return (
    <ConfigProvider theme={theme}>
      {children ? children : <Outlet />}
    </ConfigProvider>
  );
};
