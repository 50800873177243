import React from "react";
import { Button, Space, Spin, Typography } from "antd";
import { useNavigate } from "react-router";
import useAPI from "../../hooks/useAPI";
import { BatchAnalytic } from "../../types/BatchAnalytic";
import { ThemedApp } from "../../theme";
import BatchQualityChart from "../../components/BatchQualityChart";
import { useAppSelector } from "../../hooks/redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;

const BatchPerformance: React.FC = () => {
  const API = useAPI();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<BatchAnalytic[]>([]);
  const userId = useAppSelector((state) => state.user.user?.id);
  const LIMIT = 5;
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get<BatchAnalytic[]>(
        `/users/${userId}/analytics?limit=${LIMIT}&sort=desc`,
      );
      setData(data.reverse());
      setLoading(false);
    } catch (error) {
      console.error("Failed to load batch performance", error);
      setLoading(false);
      navigate("/feed");
    }
  }, [API, userId, navigate]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);
  const averageAudienceQuality = React.useMemo(() => {
    // calculate approved_for_posting_count / replies_generated_count = audience quality
    const total = data.reduce((acc, item) => {
      return acc + (item.approved_for_posting_count || 0);
    }, 0);
    const replies = data.reduce((acc, item) => {
      return acc + (item.replies_generated_count || 0);
    }, 0);

    return Number((total / replies) * 100).toFixed(1);
  }, [data]);

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Helmet>
        <title>Batch's Performance - Duome</title>
      </Helmet>
      <Title level={2}>Your AI Clone's Performance</Title>
      <Text
        type="secondary"
        className="smaller-text"
        style={{ textTransform: "uppercase" }}
      >
        On average, your audience selection is about{" "}
        <b>{averageAudienceQuality || 0}%</b> on target. As this number gets
        higher, your writing will automatically improve.
      </Text>
      <Space style={{ margin: "24px auto" }}>
        <ThemedApp version="secondary">
          <Link to="/analytics">
            <Button type="primary">View analytics</Button>
          </Link>
        </ThemedApp>
        <Link to="/feed/history">
          <Button type="primary">See batch history</Button>
        </Link>
      </Space>
      {loading ? <Spin /> : null}
      {!loading ? <BatchQualityChart data={data} maxRows={LIMIT} /> : null}
    </div>
  );
};

export default BatchPerformance;
