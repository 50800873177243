import React from "react";
import {
  Space,
  Typography,
  Row,
  Switch,
  message,
  Col,
  Button,
  Skeleton,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Schedule, Weekday } from "../../types/Schedule";
import TimeSlotField from "../TimeSlotField";
import startCase from "lodash/startCase";
import { CreationMode } from "../../types/CreationMode";
import useAPI from "../../hooks/useAPI";
import { useAppSelector } from "../../hooks/redux";
import moment from "moment";
import CopyIcon from "../../icons/CopyIcon";

type Props = {
  day: Weekday;
  records: CreationMode<Schedule>[];
  onReload: () => void;
  loading?: boolean;
};
const ScheduleDailyHours = ({ day, records, onReload, loading }: Props) => {
  const API = useAPI();
  const displayDay = startCase(day);
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const [values, setValues] = React.useState<CreationMode<Schedule>[]>([]);
  const dayRecords = React.useMemo(() => {
    return records.filter((r) => r.day_of_week === day);
  }, [day, records]);
  const isOpen = values.length > 0;
  const onToggle = async (day: Weekday, checked: boolean) => {
    if (checked && !isOpen) {
      createDefaultPerDay(day);
    } else if (!checked && isOpen) {
      deletePerDay(day);
    }
  };
  const createDefaultPerDay = async (day: Weekday) => {
    try {
      const record = await API.post(`/schedules`, {
        dayOfWeek: day,
        startTime: "10:00",
        endTime: "17:00",
      });
      setValues((prev) => [...prev, record]);
      message.success(`Turned on ${displayDay}'s schedule`);
      onReload();
    } catch (error) {
      console.error("Failed to add new schedule", error);
      const err = error as any;
      const msg =
        err.response?.data?.message || err?.message || "Unknown error";
      message.error(`Failed to activate ${displayDay}: ${msg}`);
    }
  };
  const deletePerDay = async (day: Weekday) => {
    try {
      await API.delete(`/schedules/${userId}/by_day/${day}`);
      setValues([]);
      message.success(`Turned off ${displayDay}'s schedule`);
      onReload();
    } catch (error) {
      console.error("Failed to delete schedule", error);
      const err = error as any;
      const msg =
        err.response?.data?.message || err?.message || "Unknown error";
      message.error(`Failed to deactivate ${displayDay}: ${msg}`);
    }
  };
  const handleDelete = async (id: number) => {
    try {
      await API.delete(`/schedules/${id}`);
      setValues((prev) => prev.filter((r) => r.id !== id));
      message.success("Successfully deleted time slot");
      onReload();
    } catch (error) {
      console.error("Failed to delete schedule", error);
      const err = error as any;
      const msg =
        err.response?.data?.message || err?.message || "Unknown error";
      message.error(`Failed to delete time slot: ${msg}`);
    }
  };
  const handleAdd = async () => {
    try {
      const startTime = [...values].pop()?.start_time || "10:00";
      const endTime = [...values].pop()?.end_time || "17:00";
      const record = await API.post(`/schedules`, {
        dayOfWeek: day,
        startTime,
        endTime,
      });
      setValues((prev) => [...prev, record]);
      onReload();
    } catch (error) {
      console.error("Failed to add new schedule", error);
    }
  };
  const handleUpdate = async (fields: Partial<CreationMode<Schedule>>) => {
    try {
      const updated = await API.put(`/schedules/${fields.id}`, {
        startTime: fields.start_time,
        endTime: fields.end_time,
      });
      setValues((prev) =>
        prev.map((record) =>
          record.id === fields.id ? { ...record, ...updated } : record,
        ),
      );

      message.success("Successfully updated schedule");
    } catch (error) {
      console.error("Failed to update schedule", error);
      const err = error as any;
      const msg =
        err.response?.data?.message || err?.message || "Unknown error";
      message.error(`Failed to update: ${msg}`);
    }
  };
  const handleCopyToAll = async () => {
    try {
      await API.post(`/schedules/copy-to-all-days`, {
        day,
      });
      message.success("Successfully copied schedule to all days");
      onReload();
    } catch (error) {
      console.error("Failed to copy schedule to all days", error);
      const err = error as any;
      const msg =
        err.response?.data?.message || err?.message || "Unknown error";
      message.error(`Failed to copy schedule to all days: ${msg}`);
    }
  };
  const hasOverlaps = React.useMemo(() => {
    const sorted = values.sort((a, b) =>
      a.start_time.localeCompare(b.start_time),
    );
    for (let i = 0; i < sorted.length - 1; i++) {
      const current = sorted[i];
      const next = sorted[i + 1];
      if (current.end_time > next.start_time) {
        return true;
      }
    }
    return false;
  }, [values]);
  const errorMessage = React.useMemo(() => {
    return values
      .map((record) => {
        const start = moment(record.start_time, "HH:mm");
        const end = moment(record.end_time, "HH:mm");
        if (start.isAfter(end)) {
          return "Error: Start time cannot be after end time";
        } else if (start.isSame(end)) {
          return "Error: Start time cannot be identical to end time";
        } else {
          return null;
        }
      })
      .filter((msg) => msg !== null)
      .pop();
  }, [values]);
  const firstLoad = loading && values.length === 0;
  React.useEffect(() => {
    // sort by start time
    setValues(
      dayRecords.sort((a, b) => a.start_time.localeCompare(b.start_time)),
    );
  }, [dayRecords]);

  return (
    <div>
      <Row align="top">
        <Skeleton
          loading={firstLoad}
          title={{ width: "100%" }}
          paragraph={{ rows: 1 }}
        >
          <Col xxl={4} xl={5} md={5} xs={11}>
            <Typography.Title
              level={5}
              style={{ margin: 0, marginTop: 3, marginRight: 24 }}
            >
              {displayDay}
            </Typography.Title>
            {isOpen ? (
              <Button
                size="small"
                type="text"
                onClick={handleCopyToAll}
                style={{ marginTop: 3, opacity: 0.65 }}
                icon={<CopyIcon />}
              >
                Copy to all
              </Button>
            ) : null}
          </Col>
          <Col xxl={5} xl={6} md={7} xs={12}>
            <Space style={{ marginTop: 3, marginRight: 24 }}>
              <Switch
                checked={isOpen}
                onChange={(checked) => onToggle(day, checked)}
              />
              <Typography.Text>{isOpen ? "Active" : "Offline"}</Typography.Text>
            </Space>
          </Col>
          <Col xl={12} md={12} xs={24}>
            {values.map((record, index) => (
              <Row key={record.id} style={{ marginBottom: 2 }}>
                <TimeSlotField record={record} handleUpdate={handleUpdate} />
                {values.length > 1 ? (
                  <Button
                    size="small"
                    type="text"
                    onClick={() => handleDelete(record.id!)}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    className="list-action-icon"
                    style={{ marginLeft: 4 }}
                  />
                ) : null}
                {values.length - 1 === index ? (
                  <Button
                    size="small"
                    type="text"
                    onClick={handleAdd}
                    shape="circle"
                    icon={<PlusOutlined />}
                    className="list-action-icon"
                    style={{ marginLeft: 4 }}
                  />
                ) : null}
              </Row>
            ))}
          </Col>
        </Skeleton>
      </Row>
      {errorMessage ? (
        <Row justify="center">
          <Typography.Text type="danger">{errorMessage}</Typography.Text>
        </Row>
      ) : null}
      {hasOverlaps ? (
        <Row justify="center">
          <Typography.Text type="warning">
            Your time slots are overlapping which may cause issues
          </Typography.Text>
        </Row>
      ) : null}
    </div>
  );
};

export default ScheduleDailyHours;
