import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Row,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import useAPI from "../../hooks/useAPI";
import { TwitterTeam } from "../../types/TwitterTeam";
import { useAppSelector } from "../../hooks/redux";
import { TweetReply } from "../../types/TweetReply";
import OriginalTweetPreview from "../OriginalTweetPreview";
import UpdateReplyForm from "../UpdateReplyForm";
import mixpanel from "mixpanel-browser";
import SettingsCard from "../SettingsCard";
import { Helmet } from "react-helmet";

const RULES_LIMIT = 17;
type Props = {};
const WritingRules = (props: Props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [overLimit, setOverLimit] = React.useState(false);
  const [testing, setTesting] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const [team, setTeam] = React.useState<TwitterTeam | null>(null);
  const [preview, setPreview] = React.useState<TweetReply | null>(null);
  const [form] = Form.useForm();
  const API = useAPI();
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      mixpanel.track("update_writing_rules");
      const writer = JSON.stringify(
        values.rules.map((rule: { value: string }) => rule.value),
      );
      await API.post(`/users/${userId}/twitter-team`, { writer });
      setSubmitting(false);
      message.success("Successfully updated rules");
    } catch (err) {
      setSubmitting(false);
      const error = err as any;
      const status = error.response?.status;
      const msg = error.response?.data?.message || error.message;
      if (status !== 401) {
        message.error(`Failed to update rules: ${msg}`);
      }
      console.error("Failed to save settings", error);
    }
  };
  const handleTestRules = async () => {
    try {
      setTesting(true);
      mixpanel.track("test_writing_rules");
      const rules = form.getFieldValue("rules");
      const writer = JSON.stringify(
        rules.map((rule: { value: string }) => rule.value),
      );
      const data = await API.post<TweetReply>(
        `/users/${userId}/twitter-team/test`,
        {
          writer,
        },
      );
      setPreview(data || null);
      setTesting(false);
    } catch (error) {
      const err = error as any;
      const msg = err.response?.data?.message || err.message;
      message.error(`Failed to test rules: ${msg}`);
      console.error("Failed to test rules", error);
      setTesting(false);
    }
  };
  const handleReset = () => {
    const rules =
      team?.writer?.map((rule, i) => ({ id: i, value: rule })) || [];
    setOverLimit(rules.length > RULES_LIMIT);
    form.setFieldsValue({ rules });
    setPreview(null);
    setChanged(false);
  };
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      setTeam(null);
      const data = await API.get<TwitterTeam>(`/users/${userId}/twitter-team`);
      setTeam(data);
      setTimeout(() => {
        const rules = data.writer?.map((rule, i) => ({ id: i, value: rule }));
        form.setFieldsValue({ rules });
        setLoading(false);
      }, 200);
    } catch (error) {
      console.error("Failed to load settings", error);
      setLoading(false);
    }
  }, [API, form, userId]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const loadingIcon = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
  );

  return (
    <SettingsCard
      title="Writing rules"
      subtitle="The rules defined here will be used to guide the AI responses
      generated. They are most-effective when they are specific and
      actionable."
      extra={
        <Tooltip title="Reload">
          <Button
            icon={loading ? loadingIcon : <ReloadOutlined />}
            loading={loading}
            onClick={loadData}
          />
        </Tooltip>
      }
    >
      <Helmet>
        <title>Writing rules - Duome</title>
      </Helmet>
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={(changed, fields) => {
          setChanged(true);
          if (fields.length > RULES_LIMIT) {
            setOverLimit(true);
          } else {
            setOverLimit(false);
          }
        }}
      >
        <Form.List
          name="rules"
          rules={[
            {
              validator: async (_, rules) => {
                if (!rules || rules.length === 0) {
                  return Promise.reject(
                    new Error("You must have at least one category"),
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <Col md={18} sm={24} xl={18} xxl={15}>
              {fields.map((field, index) => (
                <Form.Item required={false} key={field.key}>
                  <Form.Item
                    {...field}
                    name={[field.name, "value"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        min: 1,
                        max: 100,
                        message:
                          "Please enter a writing rule or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input.TextArea
                      placeholder="Avoid using hashtags"
                      style={{ width: "90%" }}
                      rows={2}
                      maxLength={100}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <DeleteOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                      style={{ marginLeft: 8 }}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item
                help={
                  overLimit
                    ? `Cannot add more than ${RULES_LIMIT + 1} rules`
                    : null
                }
              >
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  disabled={overLimit}
                >
                  Add rule
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </Col>
          )}
        </Form.List>
        <Form.Item style={{ paddingTop: 24 }}>
          <Row justify="space-between">
            <Space>
              <Button onClick={handleReset} disabled={!changed}>
                Reset
              </Button>
              <Button
                icon={<ToolOutlined />}
                loading={testing}
                onClick={handleTestRules}
              >
                Test rules
              </Button>
            </Space>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Row>
        </Form.Item>
      </Form>
      <div>
        {preview ? (
          <Row
            key={preview.id}
            align="top"
            className="full-width-tweet"
            justify="center"
            gutter={[16, 16]}
            style={{
              marginBottom: 16,
            }}
          >
            <Col sm={24} md={8}>
              <OriginalTweetPreview tweet={preview} interactive={false} />
            </Col>

            <Col sm={24} md={16} style={{ paddingTop: "2rem" }}>
              <List.Item key={preview.id} style={{ flex: 1 }}>
                <UpdateReplyForm
                  item={preview}
                  onUpdate={() => {}}
                  disabled
                  preview
                />
              </List.Item>
            </Col>
          </Row>
        ) : null}
      </div>
    </SettingsCard>
  );
};

export default WritingRules;
