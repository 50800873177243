import React from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Divider,
  message,
  Col,
  Row,
} from "antd";
import useAPI from "../../hooks/useAPI";
import { LinkOutlined } from "@ant-design/icons";

type Props = {
  onBack: () => void;
  onNext: () => void;
};
const TwitterPostingPermission = ({ onBack, onNext }: Props) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState(false);
  const [verifyUrl, setVerifyUrl] = React.useState("");
  const API = useAPI();
  const onStartConnecting = React.useCallback(async () => {
    try {
      setSubmitting(true);
      const data = await API.post("/twitter/setup/start");
      setSubmitting(false);
      setVerifyUrl(data.authorizeUrl);
      message.info(data.message, 5);
    } catch (error) {
      setSubmitting(false);
      console.error("failed to start connecting", error);
      message.error(
        "Failed to get your Twitter connect link. Please try again or contact support.",
        5,
      );
    }
  }, [API]);
  const onFinishConnecting = async (values: any) => {
    try {
      if (!verifyUrl) return;
      setSubmitting(true);
      console.error("Received values of form:", values);
      await API.post("/twitter/setup/finish", values);
      setSubmitting(false);
      message.success("Twitter account successfully connected!", 3, onNext);
    } catch (err) {
      setSubmitting(false);
      const error = err as any;
      const status = error.response?.status;
      console.error("failed to finish connecting", error);
      if (status !== 401) {
        message.error(
          "Failed to connect your Twitter account. Please try again or contact support.",
          5,
        );
      }
    }
  };
  React.useEffect(() => {
    onStartConnecting();
  }, [onStartConnecting]);

  return (
    <div>
      <Typography.Title level={3}>
        Connect your AI Clone to your Twitter
      </Typography.Title>
      <Typography.Text>
        Follow the button below to grant your AI clone access to your Twitter.
      </Typography.Text>
      <Divider />
      <br />
      <Form form={form} layout="vertical" onFinish={onFinishConnecting}>
        <Typography.Text>
          Please click the button below and authorize the app to post on your
          behalf. Then copy the verification code and paste it below.
        </Typography.Text>
        <Row align="bottom">
          <Form.Item style={{ marginRight: 24 }}>
            <a href={verifyUrl} target="_blank" rel="noreferrer">
              <Button icon={<LinkOutlined />}>Connect to Twitter</Button>
            </a>
          </Form.Item>
          <Col xl={4} lg={8} md={12} sm={24}>
            <Form.Item label="Verification code" name="verifier" required>
              <Input placeholder="3924013" maxLength={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Form.Item>
            <Button type="default" onClick={onBack}>
              Back
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={!verifyUrl}
            >
              Validate
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default TwitterPostingPermission;
