import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const CopyIcon = ({ size = 15, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.25 5.75V4.11678C5.25 3.46339 5.25 3.13645 5.37716 2.88688C5.48901 2.66736 5.66736 2.48901 5.88688 2.37716C6.13645 2.25 6.46339 2.25 7.11678 2.25H10.3834C11.0368 2.25 11.3634 2.25 11.6129 2.37716C11.8325 2.48901 12.0111 2.66736 12.123 2.88688C12.2501 3.13645 12.2501 3.46314 12.2501 4.11654V7.38322C12.2501 8.03661 12.2501 8.36331 12.123 8.61287C12.0111 8.8324 11.8323 9.01111 11.6128 9.12297C11.3634 9.25 11.0373 9.25 10.3851 9.25H8.75M5.25 5.75H3.61678C2.96339 5.75 2.63645 5.75 2.38688 5.87716C2.16736 5.98901 1.98901 6.16736 1.87716 6.38688C1.75 6.63645 1.75 6.96339 1.75 7.61678V10.8834C1.75 11.5368 1.75 11.8634 1.87716 12.1129C1.98901 12.3325 2.16736 12.5111 2.38688 12.623C2.6362 12.75 2.96275 12.75 3.61486 12.75H6.8854C7.53752 12.75 7.86361 12.75 8.11293 12.623C8.33245 12.5111 8.51111 12.3323 8.62297 12.1128C8.75 11.8634 8.75 11.5373 8.75 10.8851V9.25M5.25 5.75H6.88345C7.53684 5.75 7.86337 5.75 8.11293 5.87716C8.33245 5.98901 8.51111 6.16736 8.62297 6.38688C8.75 6.6362 8.75 6.96275 8.75 7.61488L8.75 9.25"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {...props}
    />
  );
};

export default CopyIcon;
