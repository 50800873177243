import React, { ReactNode } from "react";
import { Card, CardProps, Space, Typography } from "antd";

type Props = {
  subtitle?: string | null;
  children?: ReactNode;
} & CardProps;

const SettingsCard: React.FC<Props> = ({
  title,
  subtitle,
  children,
  ...props
}) => {
  return (
    <Card
      title={
        <Space
          direction="vertical"
          style={{ paddingTop: 32, paddingBottom: 12 }}
        >
          <Typography.Title level={3} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
          {subtitle ? (
            <Typography.Paragraph
              type="secondary"
              style={{ fontWeight: 400, whiteSpace: "pre-wrap" }}
            >
              {subtitle}
            </Typography.Paragraph>
          ) : null}
        </Space>
      }
      {...props}
    >
      {children}
    </Card>
  );
};

export default SettingsCard;
