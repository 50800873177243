import React from "react";
import { Button, Col, Form, Row, Select, Typography, message } from "antd";
import moment from "moment-timezone";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { updateUserInStore } from "../../redux/user/action";
import ScheduleDailyHours from "../ScheduleDailyHours";
import { Schedule, Weekday } from "../../types/Schedule";
import { CreationMode } from "../../types/CreationMode";
import _ from "lodash";
import startCase from "lodash/startCase";
import mixpanel from "mixpanel-browser";
import { UserUsageLimits } from "../../types/ActivityData";
import { Helmet } from "react-helmet";

const WorkSchedule = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState(false);
  const [schedule, setSchedule] = React.useState<CreationMode<Schedule>[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [evaluating, setEvaluating] = React.useState(false);
  const [limits, setLimits] = React.useState<UserUsageLimits | null>();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user)!;
  const onLoadSchedule = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get("/schedules");
      setSchedule(data);
      // form into an object where key is day of week
      const scheduleObject = data.reduce((acc: any, record: any) => {
        acc[record.day_of_week] = {
          start_time: record.start_time,
          end_time: record.end_time,
        };
        return acc;
      }, {});
      form.setFieldsValue({ items: scheduleObject });
      setLoading(false);
    } catch (error) {
      console.error("Failed to load work schedule", error);
      setLoading(false);
      const err = error as any;
      const message =
        err?.response?.data?.message || err?.message || "Unknown error";
      message.error(`Failed to load work schedule: ${message}`);
    }
  }, [API, form]);
  const handleEvaluate = React.useCallback(async () => {
    try {
      mixpanel.track("evaluate_work_schedule");
      setEvaluating(true);
      const limits = await API.get("/schedules/evaluate");
      setLimits(limits);
      setEvaluating(false);
    } catch (error) {
      setEvaluating(false);
      console.error("Failed to evaluate schedule", error);
    }
  }, [API]);
  const onFinish = React.useRef(
    _.debounce(async (values: any) => {
      try {
        mixpanel.track("update_work_schedule");
        setSubmitting(true);
        const data = await API.put("/users/me", values);
        setSubmitting(false);
        dispatch(updateUserInStore(data));
        message.success("Timezone updated", 2);
      } catch (err) {
        setSubmitting(false);
        const error = err as any;
        const status = error.response?.status;
        console.error("failed to set work schedule", error);
        if (status !== 401) {
          message.error("Failed to set work schedule");
        }
      }
    }, 100),
  ).current;
  const timezoneValues = moment.tz.names();
  React.useEffect(() => {
    form.setFieldsValue({
      timezone: user.timezone,
    });
  }, [form, user]);
  React.useEffect(() => {
    onLoadSchedule();
  }, [onLoadSchedule]);
  const weekdays: Weekday[] = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  return (
    <div>
      <Helmet>
        <title>Work Schedule - Duome</title>
      </Helmet>
      <Form layout="vertical" form={form} onValuesChange={onFinish}>
        <Col xxl={6} lg={7} md={12} sm={24}>
          <Form.Item
            label="Your timezone"
            name="timezone"
            rules={[{ required: true, message: "Please select timezone" }]}
          >
            <Select
              placeholder="America/New_York"
              showSearch
              loading={submitting}
            >
              {timezoneValues.map((tz) => (
                <Select.Option key={tz} value={tz}>
                  {tz}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Form>
      <br />
      {weekdays.map((weekday) => (
        <div style={{ marginBottom: 24 }}>
          <ScheduleDailyHours
            loading={loading}
            day={weekday}
            records={schedule}
            onReload={onLoadSchedule}
          />
        </div>
      ))}
      <br />
      <Row justify="end">
        <Button type="primary" onClick={handleEvaluate} loading={evaluating}>
          Save
        </Button>
      </Row>
      {limits ? (
        <div>
          <Typography.Title level={5}>Schedule evaluation</Typography.Title>
          <Typography.Paragraph>
            Here is what you can expect from your clone's usage pattern
          </Typography.Paragraph>
          <Typography.Paragraph>
            <ul>
              {Object.keys(limits?.schedule_adjusted || {}).map((key: any) => {
                const weekday = key as Weekday;
                const limitValues = limits?.schedule_adjusted[weekday];
                const batchCount = limitValues?.batchesForDay;
                const replyCount = limitValues?.maxPerBatch;
                return (
                  <li>
                    <strong>{startCase(key)}:</strong> Will try to produce{" "}
                    {batchCount} batches with about {replyCount} replies{" "}
                    {batchCount ? "in each" : "in total"}.
                  </li>
                );
              })}
            </ul>
          </Typography.Paragraph>
        </div>
      ) : null}
    </div>
  );
};

export default WorkSchedule;
