import React from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  Segmented,
  Tag,
  Typography,
  message,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useAPI from "../../hooks/useAPI";
import { updateUserInStore } from "../../redux/user/action";
import mixpanel from "mixpanel-browser";
import { StripeSubscriptionProduct } from "../../types/StripeSubscriptionProduct";
import { ThemedApp } from "../../theme";
import SettingsCard from "../SettingsCard";
import CheckIcon from "../../icons/CheckIcon";

type Props = {
  onBack?: () => void;
  onNext?: () => void;
};
const BillingSubscription = ({ onBack, onNext }: Props) => {
  const user = useAppSelector((state) => state.user.user);
  const API = useAPI();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = React.useState(false);
  const [starting, setStarting] = React.useState("");
  const [products, setProducts] = React.useState<StripeSubscriptionProduct[]>(
    [],
  );
  const [period, setPeriod] = React.useState<"month" | "year">("year");
  const wasReferred = user?.referred_by;
  const userId = user?.id;
  const subscribePromoter = React.useCallback(async () => {
    try {
      const data = await API.post(`/users/${userId}/promoter/billing`);
      dispatch(updateUserInStore(data.user));
      mixpanel.track("promoter_subscribed");
      messageApi.success("Thank you for signing up as a Promoter!", 3);
    } catch (err) {
      console.error("Failed to subscribe promoter", err);
      throw err;
    }
  }, [API, userId, messageApi, dispatch]);
  const handleStartSession = React.useCallback(
    async (priceId: string, level: string) => {
      try {
        setStarting(priceId);
        if (level === "promoter") {
          await subscribePromoter();
          setStarting("");
          return;
        }

        mixpanel.track("start_checkout_session", { period, level });
        const data = await API.post(`/billing/session/checkout`, {
          priceId,
          level,
        });
        setStarting("");
        window.location.href = data.url;
      } catch (err) {
        setStarting("");
        console.error("Failed to check subscription status", err);
      }
    },
    [API, period, subscribePromoter],
  );
  const loadProducts = React.useCallback(async () => {
    try {
      setLoading(true);
      const data =
        await API.get<StripeSubscriptionProduct[]>(`/billing/products`);
      setProducts(data);
      setLoading(false);
      mixpanel.track("load_subscription_products");
    } catch (err) {
      setLoading(false);
      console.error("Failed to check subscription status", err);
    }
  }, [API]);
  const handleCheckStatus = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get(`/billing/verify`);
      if (data.active) {
        message.success("Payment successful! Thank you for your purchase.", 3);
        mixpanel.track("subscription_verified");
        dispatch(updateUserInStore(data.user));
      } else {
        message.error(data.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Failed to check subscription status", err);
    }
  }, [API, dispatch]);
  const showPriceMonthly = React.useCallback(
    (product: StripeSubscriptionProduct) => {
      const planLevel = product.metadata.plan_level;
      let cost = product.prices[period]?.unit_amount || 0;
      if (planLevel === "promoter") {
        cost = 0;
      }
      const value = cost / 100;

      const monthly = period === "year" ? value / 12 : value;

      return (
        <div>
          <Row align="middle">
            <Typography.Title
              level={2}
              className="space-grotesk"
              style={{ margin: 0 }}
            >
              ${monthly}
            </Typography.Title>
            <Typography.Text
              type="secondary"
              className="space-grotesk smaller-text"
            >
              /mo
            </Typography.Text>
          </Row>
          {period === "year" && planLevel !== "promoter" ? (
            <Typography.Text type="secondary" className="smaller-text">
              When billed annually at ${value} per year
            </Typography.Text>
          ) : null}
        </div>
      );
    },
    [period],
  );
  React.useEffect(() => {
    loadProducts();
  }, [loadProducts]);
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pay = urlParams.get("pay");
    if (pay === "done") {
      handleCheckStatus();
    }
  }, [handleCheckStatus]);
  React.useEffect(() => {
    // @ts-ignore
    window.gtag?.("event", "open_subscribe_page", {
      app_name: "duome",
      screen_name: "BillingSubscription",
    });
  }, []);

  return (
    <div>
      <Typography.Title level={3}>Pick a plan</Typography.Title>
      <Typography.Paragraph>
        You can change your plan at any time by going into your settings. If you
        received a promo code, you can enter it on the next screen.
      </Typography.Paragraph>
      {wasReferred ? (
        <Alert
          message="Don't worry about the price!"
          description="You will see the discount apply on the next screen"
          type="info"
          showIcon
        />
      ) : null}
      <Row justify="center">
        <Col lg={8} md={12} sm={14} xs={18}>
          <ThemedApp version="secondary">
            <Segmented
              options={["Yearly (40% off)", "Monthly"]}
              block
              value={period === "year" ? "Yearly (40% off)" : "Monthly"}
              onChange={(value) =>
                setPeriod(value === "Monthly" ? "month" : "year")
              }
              size="large"
              style={{ marginBottom: 32, fontWeight: 500 }}
            />
          </ThemedApp>
        </Col>
      </Row>
      <Row justify="center" gutter={[24, 24]}>
        {loading
          ? Array.from({ length: 2 }).map((_, i) => (
              <Col xxl={10} md={12} sm={24} key={i}>
                <SettingsCard
                  title="Plan"
                  subtitle="Details loading, please wait..."
                  loading
                  style={{ border: "1px solid #ddd" }}
                />
              </Col>
            ))
          : null}
        {products.map((product) => {
          const planLevel = product.metadata.plan_level;
          const priceId = product.prices[period]?.id || product.prices.month.id;
          return (
            <Col xxl={10} md={12} sm={24} key={product.id}>
              <SettingsCard
                title={
                  <Row justify="space-between" align="top">
                    <Typography.Title level={3} style={{ margin: 0 }}>
                      {product.name}
                    </Typography.Title>
                    {planLevel === "pro" ? (
                      <Tag color="success">Most Popular</Tag>
                    ) : null}
                  </Row>
                }
                subtitle={product.description}
                style={{
                  border: `1px solid ${
                    planLevel === "pro" ? "#64d16e" : "#ddd"
                  }`,
                }}
              >
                <Form.Item>{showPriceMonthly(product)}</Form.Item>
                <Form.Item>
                  {product.features.map((feature) => (
                    <Typography.Paragraph
                      key={feature.name}
                      style={{ display: "block" }}
                    >
                      <CheckIcon style={{ marginRight: 6 }} />
                      {feature.name}
                    </Typography.Paragraph>
                  ))}
                </Form.Item>
                <Form.Item>
                  <Button
                    type={planLevel !== "promoter" ? "primary" : undefined}
                    style={{ marginTop: "auto" }}
                    onClick={() => handleStartSession(priceId, planLevel)}
                    loading={priceId === starting}
                    block
                  >
                    Subscribe
                  </Button>
                </Form.Item>
              </SettingsCard>
            </Col>
          );
        })}
      </Row>
      {contextHolder}
    </div>
  );
};

export default BillingSubscription;
