import React from "react";
import { Button, Card, Col, Row, Typography, message } from "antd";
import useAPI from "../../hooks/useAPI";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router";
import { updateUserInStore } from "../../redux/user/action";

const { Title, Text } = Typography;

const PromoterOnboard = () => {
  const API = useAPI();
  const [starting, setStarting] = React.useState(false);
  const userId = useAppSelector((state) => state.user.user?.id);
  const firstname = useAppSelector((state) => state.user.user?.firstname);
  const promoter = useAppSelector((state) => state.user.user?.promoter);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const handleStart = React.useCallback(async () => {
    try {
      setStarting(true);
      const data = await API.post(`/users/${userId}/promoter/start`);
      dispatch(updateUserInStore(data));
      setStarting(false);
    } catch (e) {
      const err = e as any;
      const msg = err.response?.data?.message || "Failed to start promoter";
      setStarting(false);
      messageApi.error(`Error occured: ${msg}`, 3);
      console.error("Failed to start promoter", err);
    }
  }, [API, messageApi, userId, dispatch]);
  React.useEffect(() => {
    if (promoter?.sent_at) {
      navigate("/promoter/pending");
    } else if (promoter?.started_at) {
      navigate("/promoter/start");
    }
  }, [promoter, navigate]);

  return (
    <div className="settings-container">
      <Helmet>
        <title>Starting off as a Promoter - Duome</title>
      </Helmet>
      <Row justify="center">
        <Col xxl={10} xl={14} lg={18} sm={22} xs={23}>
          <Card>
            <Title level={2}>Welcome to Duome, {firstname}!</Title>
            <Text style={{ lineHeight: 1.75 }}>
              As a promoter you must earn credits every month to continue using
              Duome for free. Click the button below to get started and earn
              your starting credits.
            </Text>
            <div style={{ marginTop: 24 }}>
              <Button
                type="primary"
                loading={starting}
                onClick={handleStart}
                block
              >
                Get started
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
};

export default PromoterOnboard;
