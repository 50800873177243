import React from "react";
import { Alert, Button, Col, Form, Input, Row, message } from "antd";
import useAPI from "../../hooks/useAPI";
import { TwitterTeam } from "../../types/TwitterTeam";
import { useAppSelector } from "../../hooks/redux";
import mixpanel from "mixpanel-browser";
import SettingsCard from "../SettingsCard";
import { Helmet } from "react-helmet";

type Props = {};
const Blacklists = (props: Props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const API = useAPI();
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const billingPlan = useAppSelector((state) => state.user.user?.billing_plan);
  const hasAccess = billingPlan === "pro+";
  const RULES_LIMIT = 20;
  const validateFields = (values: any) => {
    const { blacklistPosts, blacklistUsers } = values;
    const cleanUpLists = (list?: string) =>
      list
        ?.split(",")
        .map((s: string) => s.trim())
        .filter(Boolean) || [];
    const posts = cleanUpLists(blacklistPosts);
    const users = cleanUpLists(blacklistUsers);

    if (posts.length > RULES_LIMIT) {
      form.setFields([
        {
          name: "blacklistPosts",
          value: posts.join(","),
          errors: [
            `You can only have ${RULES_LIMIT} rules for posts. You have ${posts.length}`,
          ],
        },
      ]);
    }

    if (users.length > RULES_LIMIT) {
      form.setFields([
        {
          name: "blacklistUsers",
          value: users.join(","),
          errors: [
            `You can only have ${RULES_LIMIT} rules for users. You have ${users.length}`,
          ],
        },
      ]);
    }

    if (posts.length > RULES_LIMIT || users.length > RULES_LIMIT) {
      return false;
    }

    // Trim the values and remove empty strings
    values.blacklistPosts = posts.join(",");
    values.blacklistUsers = users.join(",");
    form.setFieldValue("blacklistPosts", values.blacklistPosts);
    form.setFieldValue("blacklistUsers", values.blacklistUsers);
    return true;
  };

  const onFinish = async (values: any) => {
    try {
      if (!validateFields(values)) {
        return;
      }

      setSubmitting(true);
      mixpanel.track("update_blacklists");
      await API.post(`/users/${userId}/twitter-team`, values);
      setSubmitting(false);
      message.success("Successfully updated blacklists!");
    } catch (err) {
      setSubmitting(false);
      const error = err as any;
      const status = error.response?.status;
      const msg = error.response?.data?.message || error.message;
      if (status !== 401) {
        message.error(`Failed to update blacklists: ${msg}`);
      }
      console.error("Failed to save settings", error);
    }
  };
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get<TwitterTeam>(`/users/${userId}/twitter-team`);
      form.setFieldsValue({
        blacklistPosts: data.blacklist_posts,
        blacklistUsers: data.blacklist_users,
      });
      setLoading(false);
    } catch (error) {
      console.error("Failed to load settings", error);
      setLoading(false);
    }
  }, [API, form, userId]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SettingsCard
      title="Blacklists"
      subtitle="The rules defined here will be used filter out the posts pulled from the Twitter based on the post itself or its author."
      loading={loading}
    >
      <Helmet>
        <title>Blacklists - Duome</title>
      </Helmet>
      {!hasAccess ? (
        <Alert
          message="Upgrade to Pro+ to access this feature"
          type="warning"
          showIcon
        />
      ) : null}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Col md={18} sm={24} xl={18} xxl={15}>
          <Form.Item
            label="Blacklist for posts:"
            name="blacklistPosts"
            rules={[
              {
                min: 5,
                message:
                  "Your post blacklist must be at least 5 characters long",
              },
              {
                max: 200,
                message:
                  "Your post blacklist must be at most 200 characters long",
              },
              {
                // allow letters, numbers, spaces, and commas, hashtags and @, or '
                pattern: /^[a-zA-Z0-9\s,#_!@']*$/,
                message:
                  "Enter a comma-separated list. Only letters, numbers, spaces, commas, hashtags, _, @, and ' are allowed",
              },
            ]}
          >
            <Input.TextArea
              placeholder="advanced software engineering talks, podcast promotions, crypto trading, stocks, hiring, etc..."
              style={{ width: "90%" }}
              rows={4}
              maxLength={200}
            />
          </Form.Item>
          <Form.Item
            name="blacklistUsers"
            label="Blacklist for users:"
            rules={[
              {
                min: 5,
                message:
                  "Your user blacklist must be at least 5 characters long",
              },
              {
                max: 200,
                message:
                  "Your user blacklist must be at most 200 characters long",
              },
              {
                // allow letters, numbers, spaces, and commas, hashtags and @, or '
                pattern: /^[a-zA-Z0-9\s,#_!@']*$/,
                message:
                  "Enter a comma-separated list. Only letters, numbers, spaces, commas, hashtags, _, @, and ' are allowed",
              },
            ]}
          >
            <Input.TextArea
              placeholder="background is not startup related, is not based in USA or EU, has less than 100 followers, etc.."
              style={{ width: "90%" }}
              rows={4}
              maxLength={200}
            />
          </Form.Item>
        </Col>
        <Form.Item style={{ paddingTop: 24 }}>
          <Row justify="space-between">
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={!hasAccess}
            >
              Save
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </SettingsCard>
  );
};

export default Blacklists;
