import React from "react";
import { Button, Space, Typography, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import useAPI from "../../hooks/useAPI";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BatchJob } from "../../types/BatchJob";
import mixpanel from "mixpanel-browser";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  setActivityOverviewCollapsedInStore,
  setLastHideActivityInStore,
} from "../../redux/user/action";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const FinalApproveBatch: React.FC = () => {
  const API = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isCollapsed = useAppSelector(
    (state) => state.user.activityOverviewCollapsed,
  );
  const handleCollapseActivityOverview = React.useCallback(() => {
    if (isCollapsed) {
      return;
    }

    dispatch(setLastHideActivityInStore(dayjs().toISOString()));
    dispatch(setActivityOverviewCollapsedInStore(true));
  }, [dispatch, isCollapsed]);
  const [messageApi, contextHolder] = message.useMessage();
  const { batchId } = useParams<{ batchId: string }>();
  const state = location.state as Partial<{ batch: BatchJob }>;
  const [loading, setLoading] = React.useState(false);
  const [approving, setApproving] = React.useState(false);
  const [batch, setBatch] = React.useState<BatchJob | null>(
    state?.batch || null,
  );
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get<BatchJob>(`/batch-jobs/${batchId}`);
      setBatch(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to load batch performance", error);
      setLoading(false);
    }
  }, [API, batchId]);
  const handleApprove = async () => {
    try {
      setApproving(true);
      mixpanel.track("approved_job", {
        batch_id: batchId,
      });
      await API.post(`/batch-jobs/${batchId}/approve`);
      setApproving(false);
      message.success("Batch scheduled for sending!");
      navigate(`/feed/performance`);
    } catch (e) {
      const err = e as Error;
      console.error("Failed to approve batch", err);
      setApproving(false);
      messageApi.error(`Failed to approve batch: ${err.message}`);
    }
  };
  React.useEffect(() => {
    if (!state?.batch?.id) {
      loadData();
    }
  }, [loadData, state?.batch?.id]);
  React.useEffect(() => {
    handleCollapseActivityOverview();
  }, [handleCollapseActivityOverview]);

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Helmet>
        <title>Approve and send batch - Duome</title>
      </Helmet>
      <Title level={3}>Approve and send batch</Title>
      <Text>
        You are about to schedule {batch?.goodRepliesCount} replies to be
        posted. Do you want to approve them for sending now?
      </Text>
      <Space style={{ margin: "24px auto" }}>
        <Link to={`/feed/history/${batchId}?review=true&offset=0&limit=120`}>
          <Button loading={loading}>Review replies</Button>
        </Link>
        <Button type="primary" onClick={handleApprove} loading={approving}>
          Approve and send
        </Button>
      </Space>
      {contextHolder}
    </div>
  );
};

export default FinalApproveBatch;
