import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router";
import router from "./router";
import mixpanel from "mixpanel-browser";
import "./libs/firebase";

const {
  REACT_APP_MIXPANEL_TOKEN: mixPanelToken,
  REACT_APP_MIXPANEL_PROXY_URL: proxyUrl,
  REACT_APP_ENVIRONMENT: env,
} = process.env;

mixpanel.init(mixPanelToken!, {
  api_host: proxyUrl,
  debug: env !== "production",
  track_pageview: true,
  persistence: "localStorage",
});

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById("root") as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
