import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { useAppSelector } from "../../hooks/redux";

type Props = {
  children?: React.ReactNode;
};
const GatedForGuestUser: React.FC = ({ children }: Props) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  useEffect(() => {
    if (user?.id) {
      navigate("/home");
    }
  }, [user?.id, navigate]);

  return !user ? <Outlet /> : null;
};

export default GatedForGuestUser;
