import React from "react";
import {
  Button,
  Empty,
  List,
  Popconfirm,
  Space,
  Typography,
  message,
} from "antd";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { BatchJob } from "../../types/BatchJob";
import BatchHeader from "../../components/BatchHeader";
import TweetItem from "../../components/TweetItem";
import { setTwitterUserInStore } from "../../redux/user/action";
import { TweetReply } from "../../types/TweetReply";
import { useNavigate, useParams } from "react-router";
import mixpanel from "mixpanel-browser";
import BatchFooter from "../../components/BatchFooter";
import { ThemedApp } from "../../theme";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

const FocusedFeedPosts: React.FC = () => {
  const API = useAPI();
  const { batchId } = useParams<{ batchId: string }>();
  const location = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [batch, setBatch] = React.useState<BatchJob | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [purging, setPurging] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.user?.id);
  const defaultChunkSize =
    useAppSelector((state) => state.user.defaultChunkSize) || 10;
  const subscribed_count = useAppSelector(
    (state) => state.user.user?.subscribed_count,
  );
  const isPaying = useAppSelector((state) => state.user.user?.is_paying);
  const hasAudience = subscribed_count && subscribed_count > 0;
  const count = batch?.tweetRepliesCount || 0;
  const limit = Number(searchParams.get("limit")) || defaultChunkSize;
  const offset = !isNaN(Number(searchParams.get("offset") || "null"))
    ? Number(searchParams.get("offset"))
    : undefined;
  const approvedButNotSent =
    !!batch?.approved_at && batch?.status === "approved";
  const reviewMode =
    searchParams.get("review") === "true" || approvedButNotSent;
  const isLastChunk = (offset || 0) + limit >= count;
  const scrollToTop = React.useCallback(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 50);
  }, []);
  const loadBatch = React.useCallback(async () => {
    setLoading(true);
    try {
      const param = batchId || "feed";
      const data = await API.get<BatchJob>(`/batch-jobs/${param}`, {
        params: {
          limit,
          offset,
        },
      });

      const hasValidOffset = typeof offset === "number" && offset >= 0;
      let newOffset = hasValidOffset ? offset : data.last_chunk_offset;

      if (!data.is_editable) {
        newOffset = 0;
      }

      setBatch(data);
      navigate(
        `?limit=${limit}&offset=${newOffset}` +
          (reviewMode ? "&review=true" : ""),
        { replace: true },
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to load batch", error);
    }
  }, [API, batchId, limit, offset, navigate, reviewMode]);
  const handleProgressBatch = React.useCallback(async () => {
    try {
      setLoading(true);
      setBatch(null);
      scrollToTop();
      mixpanel.track("progress_batch_job", {
        batch_id: batch?.id,
        offset: batch?.last_chunk_offset,
        limit,
        isLastChunk,
      });
      const data = await API.post<BatchJob>(
        `/batch-jobs/${batch?.id}/progress`,
        {
          limit,
        },
      );

      if (isLastChunk) {
        // Show AI performance for the batch
        navigate(`/feed/${batch?.id}/approve`, {
          state: { batch: data },
        });
        return;
      }

      setBatch(data || null);
      setLoading(false);
      navigate(`?limit=${limit}&offset=${data?.last_chunk_offset}`, {});
    } catch (error) {
      console.error("Failed to progress batch", error);
    }
  }, [
    API,
    scrollToTop,
    navigate,
    batch?.id,
    isLastChunk,
    limit,
    batch?.last_chunk_offset,
  ]);
  const handlePurgeAiResponses = React.useCallback(async () => {
    try {
      mixpanel.track("purge_ai_replies", {
        batch_id: batch?.id,
        count: batch?.aiRepliesCount,
      });
      setPurging(true);
      await API.post<BatchJob>(`/batch-jobs/${batch?.id}/purge-ai`);
      messageApi.success("Successfully purged AI replies from batch");
      setPurging(false);
    } catch (err) {
      setPurging(false);
      console.error("Failed to discard batch", err);
      const error = err as any;
      const status = error.response?.status;
      if (status !== 401) {
        messageApi.error("Failed to purge AI replies. Please try again later.");
      }
    }
  }, [API, messageApi, batch?.id, batch?.aiRepliesCount]);
  const handleDiscardBatch = React.useCallback(async () => {
    try {
      mixpanel.track("discard_batch_job", {
        batch_id: batch?.id,
      });
      await API.put<BatchJob>(`/batch-jobs/${batch?.id}`, {
        status: "ignored",
        approvedAt: new Date().toISOString(),
      });
      messageApi.success("Successfully discarded batch");
      navigate(-1);
    } catch (err) {
      console.error("Failed to discard batch", err);
      const error = err as any;
      const status = error.response?.status;
      if (status !== 401) {
        messageApi.error("Failed to discard batch. Please try again later.");
      }
    }
  }, [API, messageApi, navigate, batch?.id]);
  const handleReplyUpdated = React.useCallback(
    (item: Partial<TweetReply>) => {
      if (!batch?.id) return;

      setBatch((b) => ({
        ...b!,
        tweetReplies: b!.tweetReplies?.map((reply) => {
          if (reply.id === item.id) {
            return { ...reply, ...item };
          }
          return reply;
        }),
      }));
    },
    [batch?.id],
  );
  const loadTwitterUser = React.useCallback(async () => {
    try {
      const twitterUser = await API.get(`/users/${userId}/twitter-user`);
      dispatch(setTwitterUserInStore(twitterUser));
    } catch (error) {
      console.error("Failed to load twitter user", error);
    }
  }, [API, dispatch, userId]);
  React.useEffect(() => {
    if (typeof offset !== "number") {
      navigate(`?limit=${limit}&offset=-1`, { replace: true });
      return;
    }

    loadBatch();
    loadTwitterUser();
  }, [loadTwitterUser, batchId, loadBatch, limit, navigate, offset]);
  const isOnFeedPage = !batchId;
  const alreadyApproved = !!batch?.approved_at;
  const showPagination = reviewMode || (!isOnFeedPage && alreadyApproved);
  const noChunksLeft = React.useMemo(() => {
    if (!batch?.last_chunk_offset || !batch?.tweetRepliesCount) return false;
    return batch?.last_chunk_offset >= batch?.tweetRepliesCount;
  }, [batch?.last_chunk_offset, batch?.tweetRepliesCount]);
  const recentlyApproved = React.useMemo(() => {
    if (!batch?.approved_at) return false;
    return dayjs().diff(dayjs(batch.approved_at), "hour") < 2;
  }, [batch?.approved_at]);
  const hasAiReplies = batch?.aiRepliesCount && batch.aiRepliesCount > 0;

  return (
    <div>
      <Helmet>
        <title>
          {isOnFeedPage ? "Feed" : `View batch #${batchId}`} - Duome
        </title>
      </Helmet>
      {!isOnFeedPage ? (
        <Space style={{ marginTop: 16 }}>
          <ThemedApp version="neutral">
            <Button type="primary" onClick={() => navigate(-1)}>
              Go back
            </Button>
          </ThemedApp>
          {recentlyApproved && hasAiReplies ? (
            <Popconfirm
              title={`Confirm deleting ${batch?.aiRepliesCount} replies?`}
              description="They will be removed from Twitter if already posted."
              onConfirm={handlePurgeAiResponses}
              okText="Yes"
              cancelText="No"
              okType="danger"
            >
              <Button type="primary" danger loading={purging}>
                Unsend all AI replies
              </Button>
            </Popconfirm>
          ) : null}
        </Space>
      ) : null}
      {!batch && !loading ? (
        <Empty
          description={
            <div>
              <Typography.Paragraph>
                {hasAudience
                  ? "Welcome! Seems like you have no batches yet. Just give it a few moments and they will appear here shortly."
                  : `Welcome! If you made a request for a new custom audience, it may take a few days to process (or only a few hours for paying customers).`}
              </Typography.Paragraph>
              {!isPaying && !hasAudience ? (
                <Typography.Paragraph type="secondary">
                  Requests from free users are not guaranteed fulfillment but
                  are handled on an adhoc basis.
                </Typography.Paragraph>
              ) : null}
            </div>
          }
          style={{ marginTop: 32 }}
        >
          <Space size="large">
            <ThemedApp version="secondary">
              <Button
                type={hasAudience ? "primary" : "default"}
                onClick={() => loadBatch()}
                loading={loading}
              >
                Refresh
              </Button>
            </ThemedApp>
            {!hasAudience ? (
              <>
                <Link to="/settings/audience">
                  <Button>Change audience</Button>
                </Link>
                <ThemedApp version="secondary">
                  <Link to="/account/billing">
                    <Button
                      type="primary"
                      onClick={() => loadBatch()}
                      loading={loading}
                    >
                      Upgrade plan
                    </Button>
                  </Link>
                </ThemedApp>
              </>
            ) : null}
          </Space>
        </Empty>
      ) : null}
      {batch ? (
        <List
          header={<BatchHeader batch={batch} />}
          renderItem={(item) => (
            <TweetItem
              tweet={item}
              onUpdate={(updated) => handleReplyUpdated(updated)}
              batchApproved={alreadyApproved}
            />
          )}
          rowKey={(item) => item.id}
          dataSource={batch.tweetReplies}
          itemLayout="vertical"
          className="tweet-item-list"
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Nothing to see here..."
              />
            ),
          }}
          pagination={
            showPagination
              ? {
                  onChange: () => scrollToTop(),
                }
              : undefined
          }
          footer={
            <BatchFooter
              batch={batch}
              onProgress={handleProgressBatch}
              onDiscard={handleDiscardBatch}
              showProgress={!alreadyApproved && count > 0 && !noChunksLeft}
              reviewMode={reviewMode || noChunksLeft}
              isLastChunk={isLastChunk}
            />
          }
          split={false}
        />
      ) : null}
      {loading ? (
        <List
          renderItem={() => <TweetItem loading />}
          dataSource={[1, 2, 3, 4]}
          rowKey={(item) => item}
          itemLayout="vertical"
          className="tweet-item-list"
          split={false}
          style={{ marginTop: 24 }}
        />
      ) : null}
      {contextHolder}
    </div>
  );
};

export default FocusedFeedPosts;
