import React from "react";
import { Col, Row } from "antd";
import ActivityOverview from "../../components/ActivityOverview";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { useAppSelector } from "../../hooks/redux";

const RepliesFeed: React.FC = () => {
  const location = useLocation();
  const promoter = useAppSelector((state) => state.user.user?.promoter);
  const readyToStart = promoter && (!promoter.sent_at || promoter.expired);
  const navigate = useNavigate();
  const scrollToTop = React.useCallback(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 50);
  }, []);
  React.useEffect(() => {
    if (readyToStart) {
      navigate("/promoter", {
        replace: true,
      });
    }
  }, [readyToStart, navigate]);
  React.useEffect(() => {
    if (location.pathname) {
      scrollToTop();
    }
  }, [location.pathname, scrollToTop]);

  return (
    <div>
      <Helmet>
        <title>Feed - Duome</title>
      </Helmet>
      <ActivityOverview />
      <Row justify="center">
        <Col xxl={10} xl={14} lg={18} sm={22} xs={23}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default RepliesFeed;
