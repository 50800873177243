import React from "react";
import "./App.css";
import { ConfigProvider } from "antd";
import { Outlet, useLocation } from "react-router";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

type Props = {
  children?: React.ReactNode;
};
function App({ children }: Props) {
  const fontFamily =
    "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue,Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji";
  const { pathname } = useLocation();

  React.useEffect(() => {
    const body = document.querySelector("#root");
    body?.scrollIntoView({
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <div className="App">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              fontSize: 16,
              colorPrimary: "#ab74e6",
              fontFamily,
            },
          }}
        >
          <PersistGate loading={null} persistor={persistor}>
            <Elements stripe={stripePromise}>
              {children ? children : <Outlet />}
            </Elements>
          </PersistGate>
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;
