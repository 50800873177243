import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router";
import { Helmet } from "react-helmet";

const ManageAccount: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Manage your account - Duome</title>
      </Helmet>
      <Layout>
        <Layout.Content className="layout-container settings-container">
          <Outlet />
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default ManageAccount;
