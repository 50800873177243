import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import React from "react";
import { TweetReply } from "../../types/TweetReply";
import { DeleteOutlined, ExportOutlined } from "@ant-design/icons";
import useAPI from "../../hooks/useAPI";
import mixpanel from "mixpanel-browser";
import { useParams } from "react-router";
import { useAppSelector } from "../../hooks/redux";
import moment from "moment";
import TweetTrainButton from "../TweetTrainButton";

type Props = {
  firstLoadAt?: string | null;
  approved?: boolean;
  interactive?: boolean;
  tweet: TweetReply;
  onDelete?: (replyId: number) => void;
  onUpdate?: (reply: Partial<TweetReply>) => void;
};

const OriginalTweetPreview = ({
  approved,
  firstLoadAt,
  tweet,
  interactive = true,
  onDelete,
  onUpdate,
}: Props) => {
  const [showMore, setShowMore] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const API = useAPI();
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams<{ id: string }>();
  const user = useAppSelector((state) => state.user.user);
  const makeLinksExternal = (html: string) => {
    // Update all the links in the HTML code to open in a new tab
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");
    links.forEach((link, i) => {
      link.setAttribute("target", "_blank");
      if (i === links.length - 1) {
        link.style.display = "none";
      }
    });

    let finalHtml = doc.documentElement.innerHTML;
    if (tweet.twitterUser) {
      const authorshipRegx = /(—\s.*)<a/;
      finalHtml = finalHtml.replace(authorshipRegx, "<a");
    }

    return finalHtml;
  };
  /**
   *  Highlights links, hashtags, and mentions in the given text by wrapping them in <a> tags.
   * @param content
   */
  const highlightSpecialElements = React.useCallback((content: string) => {
    const urlPattern = /(\bhttps?:\/\/\S+\b)/g;
    const hashtagPattern = /(#\w+)/g;
    const mentionPattern = /(@\w+)/g;

    // Replace URLs with <a> tags
    let highlightedContent = content.replace(
      urlPattern,
      '<a href="$1" target="_blank">$1</a>',
    );
    // Replace hashtags with <a> tags
    highlightedContent = highlightedContent.replace(
      hashtagPattern,
      '<a href="#">$1</a>',
    );
    // Replace mentions with <a> tags
    highlightedContent = highlightedContent.replace(
      mentionPattern,
      '<a href="#">$1</a>',
    );

    // Return the highlighted content
    return highlightedContent;
  }, []);
  const wasAddedAfterRefresh = React.useMemo(() => {
    if (!firstLoadAt) {
      return false;
    }
    return moment(tweet.loaded_at).isAfter(firstLoadAt);
  }, [tweet.loaded_at, firstLoadAt]);
  const handleDelete = React.useCallback(
    async (replyId: number) => {
      try {
        setDeleting(true);
        await API.delete(`/replies/${replyId}`);
        mixpanel.track("delete_tweet_from_job", {
          tweet_reply_id: replyId,
          batch_id: id,
        });
        onDelete?.(replyId);
        setDeleting(false);
        messageApi.success("Successfully removed tweet from reply batch");
      } catch (err) {
        console.error("Failed to delete tweet", err);
        setDeleting(false);
        const error = err as any;
        const status = error.response?.status;
        if (status !== 401) {
          messageApi.error("Failed to delete tweet. Please try again later.");
        }
      }
    },
    [API, id, messageApi, onDelete],
  );
  const handleSignalTracked = React.useCallback(
    async (replyId: number, preferenceSignal: boolean) => {
      try {
        mixpanel.track("track_preference_signal", {
          batch_id: id,
          tweet_reply_id: replyId,
          preferenceSignal,
        });
        messageApi.success("Successfully tracked your tweet preference");
        if (!preferenceSignal) {
          onDelete?.(replyId);
        } else {
          onUpdate?.({ id: replyId, preference_signal: true });
        }
      } catch (err) {
        console.error("Failed to track preference signal", err);
      }
    },
    [messageApi, onDelete, onUpdate, id],
  );
  const tweetContentFromList = React.useMemo(() => {
    if (!tweet.from_list) {
      return null;
    }

    return (
      <>
        <Typography.Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: highlightSpecialElements(tweet.content),
            }}
          />
        </Typography.Paragraph>

        {!tweet.twitterUser ? (
          <Typography.Paragraph>— (@{tweet.authors})</Typography.Paragraph>
        ) : null}
      </>
    );
  }, [
    tweet.from_list,
    tweet.content,
    tweet.authors,
    tweet.twitterUser,
    highlightSpecialElements,
  ]);
  const originalPoster = React.useMemo(() => {
    if (!tweet.twitterUser) {
      return null;
    }

    const { avatar_url, handle, name, is_verified, bio, followers_count } =
      tweet.twitterUser;
    return (
      <div>
        <Row justify="space-between">
          <Space
            onMouseEnter={() => setShowMore(true)}
            onMouseLeave={() => setTimeout(() => setShowMore(false), 3000)}
            onClick={() => setShowMore(!showMore)}
          >
            <Avatar src={avatar_url} size="large" />
            <Col>
              <Row align="middle">
                <Typography.Text strong>{name}</Typography.Text>
                {is_verified ? (
                  <img
                    src="/img/verified.svg"
                    alt="Verified"
                    width="15"
                    height="15"
                    style={{ marginLeft: 6 }}
                  />
                ) : null}
              </Row>
              <Typography.Paragraph
                type="secondary"
                style={{ margin: 0, fontSize: 14 }}
              >
                @{handle}
              </Typography.Paragraph>
            </Col>
          </Space>

          <Typography.Text>
            <b>{followers_count.toLocaleString()}</b> foll.
          </Typography.Text>
        </Row>
        {showMore ? (
          <Typography.Text
            style={{ fontSize: 12, marginTop: 8 }}
            onMouseEnter={() => setShowMore(true)}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: highlightSpecialElements(bio),
              }}
            />
          </Typography.Text>
        ) : null}
      </div>
    );
  }, [tweet.twitterUser, highlightSpecialElements, showMore]);
  const reviewTweetOnly = user?.usage_mode === "refining_audience";

  return (
    <Card
      title={interactive ? "Original Tweet" : null}
      hoverable={!interactive ? false : approved ? !!tweet.sent_at : true}
      extra={
        interactive ? (
          <a
            href={tweet.original_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="text" shape="circle" icon={<ExportOutlined />} />
          </a>
        ) : null
      }
      actions={[
        onUpdate ? (
          <TweetTrainButton
            type="like"
            key="train-like"
            replyId={tweet.id}
            pressed={tweet.preference_signal === true}
            onTracked={handleSignalTracked}
          />
        ) : null,
        onUpdate ? (
          <TweetTrainButton
            type="dislike"
            key="train-dislike"
            replyId={tweet.id}
            pressed={tweet.preference_signal === false}
            onTracked={handleSignalTracked}
          />
        ) : null,
        onDelete ? (
          <Tooltip title="Discard this tweet">
            <Button
              type="text"
              onClick={() => handleDelete(tweet.id)}
              icon={deleting ? <Spin /> : <DeleteOutlined key="delete" />}
              style={{ opacity: deleting ? 1 : 0.5 }}
            />
          </Tooltip>
        ) : null,
      ].filter(Boolean)}
      style={{
        backgroundColor: wasAddedAfterRefresh ? "#fffbf4" : "#FAFAFA",
      }}
    >
      {interactive ? (
        <Typography.Paragraph type="secondary" style={{ fontSize: 14 }}>
          Tweeted on{" "}
          {moment(tweet.published_at).format("MMM D, YYYY [at] hh:mmA")}
        </Typography.Paragraph>
      ) : null}
      {tweet.under_thread ? <Tag>Under a thread</Tag> : null}
      <div
        className={`iframe-tweet ${reviewTweetOnly ? "review-mode" : ""}`}
        dangerouslySetInnerHTML={
          tweet.from_audience
            ? {
                __html: makeLinksExternal(tweet.original_html),
              }
            : undefined
        }
      >
        {tweetContentFromList}
      </div>
      {tweet.mediaAttachments?.[0] ? (
        <img
          src={tweet.mediaAttachments[0].url}
          alt="Tweet media"
          style={{
            display: "block",
            width: "80%",
            minWidth: 200,
            maxHeight: 400,
            objectFit: "contain",
            margin: "0 auto",
            marginTop: 16,
          }}
        />
      ) : null}
      {tweet.twitterUser ? (
        <div>
          <Divider />
          {originalPoster}
        </div>
      ) : null}
      {contextHolder}
    </Card>
  );
};

export default OriginalTweetPreview;
