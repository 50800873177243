import React from "react";
import * as echarts from "echarts";
import { BatchAnalytic } from "../../types/BatchAnalytic";
import { Typography } from "antd";
import { useAppSelector } from "../../hooks/redux";
import { AnalyticPerDay } from "../../pages/Analytics/Analytics";

type Props = {
  card?: boolean;
  data: BatchAnalytic[];
  groupByDay?: (analytics: BatchAnalytic[], maxDays?: number) => AnalyticPerDay;
  maxRows?: number;
};
const BatchQualityChart = ({
  card = true,
  data,
  groupByDay,
  maxRows = 10,
}: Props) => {
  const inTraining = useAppSelector((state) => state.user.user?.inTraining);
  const slimData = React.useMemo(
    () =>
      data.filter((item) => {
        const hasApproved = typeof item.approved_for_posting_count === "number";
        if (!hasApproved) {
          return false;
        }

        // Filter out expired batches
        const values = [
          item.ai_approved_count,
          item.edited_by_user_count,
          item.removed_by_user_count,
        ];
        return values.every((value) => value && value > 0);
      }),
    [data],
  );
  const shouldGroupByDay = slimData.length > maxRows && !inTraining;
  const shouldGetMostRecent = slimData.length > maxRows || inTraining;
  const addBarBorderRadius = React.useCallback((option) => {
    for (var dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
      moreValue = false;
      // Stack order is the same as series declaration order.
      for (var i = option.series.length - 1; i >= 0; i--) {
        var data = option.series[i].data;
        if (dataIndex < data.length) {
          moreValue = true;
          var val = data[dataIndex];
          if (!isNaN(val) && val != null && val !== 0) {
            data[dataIndex] = {
              value: val,
              itemStyle: { barBorderRadius: [10, 10, 0, 0] },
            };
            break;
          }
        }
      }
    }
  }, []);
  const chartData = React.useMemo(() => {
    let source = slimData;
    let xAxis = source.map((item) => `Batch #${item.batch_job_id}`);

    if (shouldGroupByDay && groupByDay) {
      const replies = groupByDay(source, 7);
      // group by day and sum them up to reduce the number of data points
      source = Object.values(replies).map((item, i) => ({
        id: i,
        batch_job_id: i,
        ...item,
      }));
      xAxis = Object.values(replies).map((item) => item.date);
    } else if (shouldGetMostRecent) {
      // Get the most recent maxRows batches
      source = source.slice(-maxRows);
      xAxis = xAxis.slice(-maxRows);
    }
    return { source, xAxis };
  }, [maxRows, slimData, shouldGroupByDay, shouldGetMostRecent, groupByDay]);

  React.useEffect(() => {
    // Show how many by batch are Removed by user, edited, then ai-written in a area line chart
    const chart = echarts.init(
      document.getElementById("work-breakdown-by-batch") as HTMLDivElement,
    );
    const { source, xAxis } = chartData;
    const removedData = source.map((item) => {
      const total = item.replies_generated_count!;
      const removed = item.removed_by_user_count || 0;
      return Math.round((removed / total) * 100) || 0;
    });
    const editedData = source.map((item) => {
      const total = item.replies_generated_count!;
      const edited = item.edited_by_user_count || 0;
      return Math.round((edited / total) * 100) || 0;
    });
    const aiOnlyData = source.map((item) => {
      const total = item.replies_generated_count!;
      const aiOnly = item.ai_approved_count || 0;
      return Math.round((aiOnly / total) * 100) || 0;
    });
    const options = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Accepted", "Edited", "Removed"],
      },
      xAxis: {
        type: "category",
        data: xAxis,
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 100,
        axisLabel: {
          formatter: "{value}%",
        },
      },
      series: [
        {
          name: "Accepted",
          data: aiOnlyData,
          type: "bar",
          stack: "total",
          // set color to green
          itemStyle: {
            color: "#64d16e",
          },
        },
        {
          name: "Edited",
          data: editedData,
          type: "bar",
          stack: "total",
          // Set color to gray
          itemStyle: {
            color: "#dddddd",
          },
        },
        {
          name: "Removed",
          data: removedData,
          type: "bar",
          stack: "total",
          // Set color to red
          itemStyle: {
            color: "#FF573E",
          },
        },
      ],
    };

    addBarBorderRadius(options);

    // Render chart with options
    chart.setOption(options);

    // Clean up ECharts instance on component unmount
    return () => chart.dispose();
  }, [chartData, addBarBorderRadius]);
  return (
    <div
      style={
        card
          ? { paddingTop: 24, backgroundColor: "#fff", borderRadius: 24 }
          : {}
      }
    >
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        Recent batches's performance
      </Typography.Title>
      <div
        id="work-breakdown-by-batch"
        style={{ width: "100%", height: "400px" }}
      />
    </div>
  );
};

export default BatchQualityChart;
