import React from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useAppDispatch } from "./redux";
import { logOutInStore } from "../redux/user/action";
import { getAuth, signOut } from "firebase/auth";
import { message } from "antd";
const { REACT_APP_API: API } = process.env;

const useAPI = () => {
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const handleApiError = React.useCallback(
    (error: AxiosError) => {
      //console.error("API request failed", error.response?.status);
      if (error.response?.status === 401) {
        // handle unauthorized. Clear local storage and redirect to login
        signOut(auth);
        dispatch(logOutInStore());
        message.error("Your session has expired. Please log in again.");
        return;
      }
      throw error;
    },
    [dispatch, auth],
  );
  const get = React.useCallback(
    async <T = any>(url: string, config?: AxiosRequestConfig) => {
      try {
        const response = await axios.get<T>(`${API}${url}`, {
          ...config,
          withCredentials: true,
        });
        return response.data;
      } catch (error) {
        handleApiError(error as AxiosError);
        throw error;
      }
    },
    [handleApiError],
  );

  const post = React.useCallback(
    async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => {
      try {
        const response = await axios.post<T>(`${API}${url}`, data, {
          ...config,
          withCredentials: true,
        });
        return response.data;
      } catch (error) {
        handleApiError(error as AxiosError);
      }
    },
    [handleApiError],
  );

  const put = React.useCallback(
    async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => {
      try {
        const response = await axios.put<T>(`${API}${url}`, data, {
          ...config,
          withCredentials: true,
        });
        return response.data;
      } catch (error) {
        handleApiError(error as AxiosError);
      }
    },
    [handleApiError],
  );

  const deleteMethod = React.useCallback(
    async <T = any>(url: string, config?: AxiosRequestConfig) => {
      try {
        const response = await axios.delete<T>(`${API}${url}`, {
          ...config,
          withCredentials: true,
        });
        return response.data;
      } catch (error) {
        handleApiError(error as AxiosError);
      }
    },
    [handleApiError],
  );

  const ref = React.useRef({
    get,
    post,
    put,
    delete: deleteMethod,
  });
  return ref.current;
};

export default useAPI;
