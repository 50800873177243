import React from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import useAPI from "../../hooks/useAPI";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router";
import { updateUserInStore } from "../../redux/user/action";
import moment from "moment";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;

const PromoterPending = () => {
  const API = useAPI();
  const [loading, setLoading] = React.useState(false);
  const [restarting, setRestarting] = React.useState(false);
  const userId = useAppSelector((state) => state.user.user?.id);
  const promoter = useAppSelector((state) => state.user.user?.promoter);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const handleRestartFlow = React.useCallback(async () => {
    try {
      setRestarting(true);
      await API.post(`/users/${userId}/promoter/restart`);
      setRestarting(false);
      navigate("/promoter/start");
    } catch (e) {
      const err = e as any;
      const msg = err.response?.data?.message || "Uknown error occured";
      messageApi.error(`Error occured: ${msg}`, 3);
      console.error("Failed to restart promoter", err);
      setRestarting(false);
    }
  }, [API, messageApi, userId, navigate]);
  const handleOpenChat = () => {
    // @ts-ignore
    window.$crisp.push(["do", "chat:open"]);
  };
  const handleLoadUser = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get(`/users/${userId}`);
      dispatch(updateUserInStore(data));
      setLoading(false);
    } catch (e) {
      const err = e as any;
      const msg =
        err.response?.data?.message || err?.message || "Uknnown error occured";
      messageApi.error(`Error occured: ${msg}`, 3);
      console.error("Failed to load user data", err);
      setLoading(false);
    }
  }, [API, messageApi, userId, dispatch]);
  React.useEffect(() => {
    if (promoter?.verified_at) {
      navigate("/feed");
    } else if (promoter?.sent_at) {
      navigate("/promoter/pending");
    } else if (!promoter?.started_at) {
      navigate("/promoter/onboard");
    }
  }, [promoter, navigate]);
  React.useEffect(() => {
    handleLoadUser();
  }, [handleLoadUser]);
  const timeToFinish = moment(promoter?.sent_at)
    .add(12, "hours")
    .fromNow();

  return (
    <div className="settings-container">
      <Helmet>
        <title>Waiting on credits - Duome</title>
      </Helmet>
      <Row justify="center">
        <Col xxl={10} xl={14} lg={18} sm={22} xs={23}>
          <Card loading={loading}>
            <Title level={2}>
              {promoter?.expired
                ? "Failed to grant your starting credits"
                : "Waiting on credit validation"}
            </Title>
            {promoter?.expired ? (
              <div>
                <Paragraph style={{ lineHeight: 1.75 }}>
                  Your post to earn your starting credits has failed our
                  automated verification. Please try again or contact support
                  with the URL to the posted tweet.
                </Paragraph>
                <Alert
                  type="error"
                  showIcon
                  message="Until this issue is resolved, you will no longer receive new batches."
                />
              </div>
            ) : (
              <Form.Item>
                <Paragraph style={{ lineHeight: 1.75 }}>
                  Your post has been sent and is awaiting validation. You should
                  receive your credits
                  <Tag style={{ marginLeft: 4, marginRight: 4 }}>
                    {timeToFinish}
                  </Tag>
                </Paragraph>
                <Paragraph>
                  In the mean time, you can get started on reviewing your first
                  batch.
                </Paragraph>
              </Form.Item>
            )}
            <Space>
              {promoter?.expired ? (
                <>
                  <Popconfirm
                    title="You must send a new post and wait another 12hrs. Continue?"
                    onConfirm={handleRestartFlow}
                    okText="Yes"
                    okType="danger"
                    cancelText="No"
                  >
                    <Button type="primary" danger loading={restarting}>
                      Restart flow
                    </Button>
                  </Popconfirm>
                  <Button onClick={handleOpenChat}>Contact support</Button>
                </>
              ) : null}
              {!promoter?.expired ? (
                <>
                  <Button onClick={handleLoadUser}>Refresh</Button>
                  <Link to="/feed">
                    <Button type="primary">Open feed</Button>
                  </Link>
                </>
              ) : null}
            </Space>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
};

export default PromoterPending;
