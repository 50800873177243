import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const EmailIcon = ({ size = 18, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 5L7.58073 8.4592L7.58225 8.46047C8.09089 8.83346 8.34535 9.02007 8.62407 9.09216C8.87042 9.15588 9.12938 9.15588 9.37573 9.09216C9.6547 9.02001 9.9099 8.83285 10.4194 8.4592C10.4194 8.4592 13.3576 6.20445 15 5M2.25 12.3501V6.65015C2.25 5.81007 2.25 5.38972 2.41349 5.06885C2.5573 4.7866 2.7866 4.5573 3.06885 4.41349C3.38972 4.25 3.81007 4.25 4.65015 4.25H13.3501C14.1902 4.25 14.6097 4.25 14.9305 4.41349C15.2128 4.5573 15.4429 4.7866 15.5867 5.06885C15.75 5.3894 15.75 5.80925 15.75 6.64768V12.3527C15.75 13.1911 15.75 13.6104 15.5867 13.9309C15.4429 14.2132 15.2128 14.4429 14.9305 14.5867C14.61 14.75 14.1908 14.75 13.3523 14.75H4.64768C3.80925 14.75 3.3894 14.75 3.06885 14.5867C2.7866 14.4429 2.5573 14.2132 2.41349 13.9309C2.25 13.61 2.25 13.1902 2.25 12.3501Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {...props}
    />
  );
};

export default EmailIcon;
