import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const LockIcon = ({ size = 18, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.75 7.25H5.40015C4.56007 7.25 4.13972 7.25 3.81885 7.41349C3.5366 7.5573 3.3073 7.7866 3.16349 8.06885C3 8.38972 3 8.81007 3 9.65015V13.8501C3 14.6902 3 15.11 3.16349 15.4309C3.3073 15.7132 3.5366 15.9429 3.81885 16.0867C4.1394 16.25 4.55925 16.25 5.39771 16.25L12.6023 16.25C13.4408 16.25 13.86 16.25 14.1805 16.0867C14.4628 15.9429 14.6929 15.7132 14.8367 15.4309C15 15.1104 15 14.6911 15 13.8527V9.64768C15 8.80924 15 8.3894 14.8367 8.06885C14.6929 7.7866 14.4628 7.5573 14.1805 7.41349C13.8597 7.25 13.4402 7.25 12.6001 7.25H6.75ZM6.75 7.25V5.09009C6.75 3.79774 7.72502 2.75 8.92776 2.75C9.54575 2.75 10.1031 3.02659 10.4995 3.47085"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {...props}
    />
  );
};

export default LockIcon;
