import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Oops, page not found - Duome</title>
      </Helmet>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={[
          <Button onClick={() => navigate(-1)}>Back</Button>,
          <Button type="primary" onClick={() => navigate("/home")}>
            Go Home
          </Button>,
        ]}
      />
    </>
  );
};

export default ErrorPage;
