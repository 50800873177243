import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const DuomeLogo = ({ size = 24, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="none"
          viewBox="0 0 350 350"
        >
          <path
            fill="url(#paint0_linear_760_4247)"
            d="M61.5 227.008h278.603c-13.103 44.5-49.603 106-157.603 109.903-90 .097-121 .097-121 .097v-110z"
          ></path>
          <path
            fill="url(#paint1_linear_760_4247)"
            d="M87.813 13.008h87.4s54.787-1 90.689 19.902c25.322 13.27 45.086 32.092 59.291 56.47 8.807 14.628 14.18 29.628 14.18 29.628H87.813v-106z"
          ></path>
          <path
            fill="url(#paint2_linear_760_4247)"
            d="M20.116 119.008h319.257s2.806 9 5.227 28.5c2.422 19.5 1.9 28.607 1.9 28.607-.461 8.636-.492 18.59-1.697 27.393-1.781 13.008-4.7 23.5-4.7 23.5H20.116v-108z"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_760_4247"
              x1="4.166"
              x2="363.145"
              y1="173.412"
              y2="173.412"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#005E10"></stop>
              <stop offset="1" stopColor="#08721A"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_760_4247"
              x1="62.089"
              x2="310.941"
              y1="114.067"
              y2="146.268"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#64D16E"></stop>
              <stop offset="1" stopColor="#28A533"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear_760_4247"
              x1="29.5"
              x2="314.107"
              y1="174.008"
              y2="174.008"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#64D16E"></stop>
              <stop offset="1" stopColor="#005E10"></stop>
            </linearGradient>
          </defs>
        </svg>
      )}
      {...props}
    />
  );
};

export default DuomeLogo;
