import React from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
  message,
  theme,
} from "antd";
import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";
import useAPI from "../../hooks/useAPI";
import { useAppSelector } from "../../hooks/redux";
import { CreditHistory } from "../../types/CreditHistory";
import CheckIcon from "../../icons/CheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import { Helmet } from "react-helmet";
import mixpanel from "mixpanel-browser";
import { ThemedApp } from "../../theme";

const { Text, Title, Paragraph } = Typography;

const { REACT_APP_BASE_URL: BASE_URL } = process.env;

const ReferFriends: React.FC = () => {
  const { token } = theme.useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const [link, setLink] = React.useState("");
  const [code, setCode] = React.useState("");
  const [history, setHistory] = React.useState<CreditHistory[]>([]);
  const [loading, setLoading] = React.useState(false);
  const API = useAPI();
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const stripeCustomerId = useAppSelector(
    (state) => state.user.user?.stripe_customer_id,
  );
  const referralCode = useAppSelector(
    (state) => state.user.user?.referral_code,
  );
  const handleTrackCreditOpen = () => {
    mixpanel.track("free_credit_open");
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    messageApi.success("Link copied to clipboard");
  };
  const handleCopyCode = () => {
    navigator.clipboard.writeText(code);
    messageApi.success("Code copied to clipboard");
  };
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get<CreditHistory[]>(
        `/users/${userId}/credit-history`,
      );
      setHistory(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to load referral code", error);
    }
  }, [API, userId]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);
  React.useEffect(() => {
    if (referralCode) {
      setCode(referralCode);
      setLink(`${BASE_URL}/invite?ref=${referralCode}`);
    }
  }, [referralCode]);

  return (
    <div className="refer-friends-page">
      <Helmet>
        <title>Earn credits now - Duome</title>
      </Helmet>
      <Row gutter={24} justify="center">
        <Col lg={11} md={10} xs={24}>
          <Title level={1} style={{ marginTop: 0 }}>
            Refer your friends. Grow huge audiences together.
          </Title>
          <Paragraph type="secondary">
            Invite your friends to grow with Duome and receive up to $25 in
            credits applied to your next bill* and achieve VIP status once you
            reach 10 referrals.
          </Paragraph>
          <Paragraph type="secondary" className="smaller-text">
            *Credits are applied within 10 business days of successful payment
            capture.
          </Paragraph>
          <ThemedApp version="secondary">
            <Button
              id="viralbox-panel-trigger"
              onClick={handleTrackCreditOpen}
              type="primary"
            >
              Earn FREE credits
            </Button>
          </ThemedApp>
        </Col>
        <Col lg={9} md={10} xs={24}>
          <Card className="refer-card" title="Your referral code">
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Row
                align="middle"
                className="refer-bonus-container"
                justify="space-around"
                style={{
                  backgroundColor: token.colorPrimaryBg,
                  borderRadius: token.borderRadius,
                }}
              >
                <Row
                  align="middle"
                  justify="center"
                  className="refer-bonus-item"
                >
                  <Text className="label">Friends receive</Text>
                  <Title level={4} className="value">
                    $15
                  </Title>
                </Row>
                <Divider
                  type="vertical"
                  style={{
                    height: "1.5em",
                    borderColor: token.colorPrimaryBorderHover,
                  }}
                />
                <Row
                  align="middle"
                  justify="center"
                  className="refer-bonus-item"
                >
                  <Text className="label">You receive</Text>
                  <Title level={4} className="value">
                    $25
                  </Title>
                </Row>
              </Row>
              <Form layout="vertical">
                <Form.Item label="Invite code">
                  <Input
                    className="refer-input"
                    value={code}
                    disabled
                    suffix={
                      <CopyOutlined
                        onClick={handleCopyCode}
                        style={{ cursor: "pointer", color: token.colorPrimary }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Invite link">
                  <Input
                    className="refer-input"
                    value={link}
                    suffix={
                      <CopyOutlined
                        onClick={handleCopyLink}
                        style={{ cursor: "pointer", color: token.colorPrimary }}
                      />
                    }
                    disabled
                  />
                </Form.Item>
                <Button type="primary" onClick={handleCopyLink} block>
                  Copy link
                </Button>
              </Form>
            </Space>
          </Card>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 48 }}>
        <Col md={20} xs={24}>
          <Typography.Title
            level={3}
            style={{ display: "flex", alignItems: "center", gap: 12 }}
          >
            Past transactions{" "}
            <Button
              onClick={loadData}
              icon={<ReloadOutlined />}
              loading={loading}
              size="small"
            />
          </Typography.Title>
          <Table
            dataSource={history}
            loading={loading}
            columns={[
              {
                title: "Date",
                dataIndex: "created_at",
                key: "created_at",
                render: (date: string) => new Date(date).toLocaleDateString(),
              },
              {
                title: "Description",
                dataIndex: "description",
                key: "description",
              },
              {
                title: "Amount",
                dataIndex: "amount",
                key: "amount",
                // render in USD
                render: (amount: number) => `$${(amount / 100).toFixed(2)}`,
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (_: string, row) => {
                  let status = "Pending";
                  if (row.granted_at) {
                    status = "Granted";
                  } else if (row.denied_at) {
                    status = "Denied";
                  }
                  return (
                    <Space size="small" direction="vertical">
                      <Row align="middle">
                        {status === "Granted" ? (
                          <CheckIcon
                            style={{
                              color: token.colorPrimary,
                              marginRight: 8,
                            }}
                          />
                        ) : null}
                        {status === "Denied" ? (
                          <CloseIcon
                            style={{
                              color: token.colorError,
                              marginRight: 8,
                            }}
                          />
                        ) : null}
                        <Typography.Text>{status}</Typography.Text>
                      </Row>
                      {row.denied_at ? (
                        <Typography.Text
                          type="secondary"
                          className="smaller-text"
                        >
                          Reason: {row.reason}
                        </Typography.Text>
                      ) : null}
                    </Space>
                  );
                },
              },
            ]}
          />
        </Col>
      </Row>
      {contextHolder}
      <Helmet>
        <script
          id="viralbox-widget"
          type="text/javascript"
          src="https://app.viralbox.co/widget.js"
          data-organizationid="973a9e22-767e-4ccf-9a4e-7576801c7453"
          data-stripecustomerid={stripeCustomerId}
        ></script>
      </Helmet>
    </div>
  );
};

export default ReferFriends;
