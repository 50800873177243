import React from "react";
import { ThemedApp } from "../../theme";
import {
  Badge,
  Button,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BatchJob } from "../../types/BatchJob";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setDefaultChunkSizeInStore } from "../../redux/user/action";
import PaperPlaneIcon from "../../icons/PaperPlaneIcon";

const { Text } = Typography;

type Props = {
  batch?: BatchJob;
  onProgress?: () => Promise<void>;
  onDiscard?: () => Promise<void>;
  readyBatches?: number;
  showProgress?: boolean;
  isLastChunk?: boolean;
  reviewMode?: boolean;
};
const BatchFooter = ({
  batch,
  onProgress,
  onDiscard,
  reviewMode,
  isLastChunk,
  showProgress,
  readyBatches = 0,
}: Props) => {
  const [progressing, setProgressing] = React.useState(false);
  const [discarding, setDiscarding] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const defaultChunkSize =
    useAppSelector((state) => state.user.defaultChunkSize) || 10;
  const dispatch = useAppDispatch();
  const handleProgress = async () => {
    setProgressing(true);
    try {
      await onProgress?.();
    } finally {
      setProgressing(false);
    }
  };
  const handlePrepareToSend = () => {
    navigate(`/feed/${batch?.id}/approve`, {
      state: { batch },
    });
  };
  const handleDiscard = async () => {
    setDiscarding(true);
    try {
      await onDiscard?.();
    } finally {
      setDiscarding(false);
    }
  };
  const editable =
    batch?.status === "requested_approval" || batch?.status === "pending";

  const handlePageSizeChange = (value: number) => {
    // Handle the page size change logic here
    dispatch(setDefaultChunkSizeInStore(value));
    location.search = location.search.replace(/limit=\d+/, `limit=${value}`);
  };

  return (
    <div>
      <Row align="middle">
        <Select
          defaultValue={defaultChunkSize || 10}
          onChange={handlePageSizeChange}
        >
          <Select.Option value={10}>10 / page</Select.Option>
          <Select.Option value={20}>20 / page</Select.Option>
          <Select.Option value={50}>50 / page</Select.Option>
          <Select.Option value={120}>120 / page</Select.Option>
        </Select>
        {showProgress ? (
          <ThemedApp version="secondary">
            <Button
              type="primary"
              loading={progressing}
              onClick={handleProgress}
              icon={isLastChunk ? <PaperPlaneIcon /> : null}
              style={{ flex: 1, marginLeft: 16 }}
            >
              {isLastChunk ? "Prepare to send" : "Continue to next chunk"}
            </Button>
          </ThemedApp>
        ) : null}

        {reviewMode ? (
          <ThemedApp version="secondary">
            <Button
              type="primary"
              onClick={handlePrepareToSend}
              icon={<PaperPlaneIcon />}
              block
              style={{ flex: 1, marginLeft: 16 }}
            >
              Prepare to send
            </Button>
          </ThemedApp>
        ) : null}
      </Row>
      <Space direction="vertical" className="sub-footer">
        {readyBatches > 1 ? (
          <Text type="secondary" className="smaller-text">
            You have more batches ready for review
          </Text>
        ) : null}
        <ThemedApp version="neutral">
          <Space>
            <Link to="/feed/history">
              <Badge count={readyBatches > 2 ? "+" : readyBatches}>
                <Button type="primary">See batches history</Button>
              </Badge>
            </Link>
            {editable && onDiscard ? (
              <Popconfirm
                title={`Are you sure? No replies from batch #${batch.id} will be sent`}
                onConfirm={handleDiscard}
                okText="Yes"
              >
                <Button type="primary" loading={discarding}>
                  Discard this batch
                </Button>
              </Popconfirm>
            ) : null}
          </Space>
        </ThemedApp>
      </Space>
    </div>
  );
};

export default BatchFooter;
