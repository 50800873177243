import { Space, TimePicker } from "antd";
import dayjs from "dayjs";
import { CreationMode } from "../../types/CreationMode";
import { Schedule } from "../../types/Schedule";

const FORMAT = "HH:mm";

type Props = {
  record: CreationMode<Schedule>;
  handleUpdate: (fields: Partial<CreationMode<Schedule>>) => void;
};
const TimeSlotField = ({ record, handleUpdate }: Props) => {
  return (
    <Space>
      <TimePicker
        placeholder="Start Time"
        value={dayjs(record.start_time, FORMAT)}
        onChange={(date, str) =>
          handleUpdate({
            id: record.id,
            start_time: Array.isArray(str) ? str[0] : str,
            end_time: record.end_time,
          })
        }
        format={FORMAT}
        minuteStep={30}
        suffixIcon={null}
      />
      <TimePicker
        placeholder="Start Time"
        value={dayjs(record.end_time, FORMAT)}
        onChange={(date, str) =>
          handleUpdate({
            id: record.id,
            start_time: record.start_time,
            end_time: Array.isArray(str) ? str[0] : str,
          })
        }
        format={FORMAT}
        minuteStep={30}
        suffixIcon={null}
      />
    </Space>
  );
};

export default TimeSlotField;
