import React, { useEffect } from "react";
import { Button } from "antd";
import { ThemedApp } from "../../theme";
import UndoLeftIcon from "../../icons/UndoLeftIcon";
import useCountdown from "../../hooks/useCountdown";

type Props = {
  onClick?: () => void;
  onExpire?: () => void;
};
const RevertButton = ({ onClick, onExpire }: Props) => {
  /**
   * On render this component, start the countdown
   * Once timer is up, trigger callback to remove this button
   */
  const timeLeft = useCountdown(10);
  useEffect(() => {
    if (timeLeft === 0) {
      onExpire?.();
    }
  }, [timeLeft, onExpire]);

  return (
    <ThemedApp version="neutral">
      <Button
        type="primary"
        icon={<UndoLeftIcon />}
        style={{ color: "#7b7b7b" }}
        onClick={onClick}
      >
        Revert ({timeLeft}s)
      </Button>
    </ThemedApp>
  );
};

export default RevertButton;
