import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { User } from "../../types/User";
import useAPI from "../../hooks/useAPI";
import { useAppSelector } from "../../hooks/redux";

type Props = {
  placeholder?: string;
  onSelect: (user: User) => void;
};
const UserSelect = ({ placeholder, onSelect }: Props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const API = useAPI();
  const userId = useAppSelector((state) => state.user.user?.id);
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get<User[]>("/users");
      setLoading(false);
      setUsers(data.filter((user) => !user.paused));
    } catch (err) {
      setLoading(false);
      const error = err as any;
      console.error(error);
    }
  }, [API]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Select
      placeholder={placeholder || "Select a user"}
      loading={loading}
      defaultValue={userId}
      style={{ width: "100%", minWidth: 200 }}
      onChange={(userId) => {
        const selectedUser = users.find((user) => user.id === userId);
        if (selectedUser) {
          onSelect(selectedUser);
        }
      }}
    >
      {users.map((user) => (
        <Select.Option key={user.id} value={user.id}>
          {user.id === userId ? `${user.firstname} (Self)` : user.fullName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserSelect;
