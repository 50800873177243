import React from "react";
import { Form, Input, Button, message, Row, Alert } from "antd";
import useAPI from "../../hooks/useAPI";
import { useAppSelector } from "../../hooks/redux";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router";
import { TwitterList } from "../../types/TwitterList";
import { Helmet } from "react-helmet";

type Props = {};
const SubscribeTwitterList = (props: Props) => {
  const [form] = Form.useForm();
  const [active, setActive] = React.useState<TwitterList[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const API = useAPI();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.user?.id);
  const billingPlan = useAppSelector((state) => state.user.user?.billing_plan);
  const hasAccess = billingPlan === "pro+";
  const loadUserLists = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get<TwitterList[]>(
        `/users/${userId}/twitter-lists`,
      );
      setLoading(false);
      setActive(data);
    } catch (error) {
      setLoading(false);
      console.error("failed to load subscribed Twitter Lists", error);
    }
  }, [API, userId]);
  const handleFormSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      mixpanel.track("subscribe_twitter_list");
      const data = await API.post(`/users/${userId}/twitter-lists`, values);
      message.success(data.message);
      setSubmitting(false);
      setActive(data.ids || []);
    } catch (err) {
      setSubmitting(false);
      const error = err as any;
      const status = error.response?.status;
      const msg = error.response?.data?.message || error.message;
      console.error("failed to set target audience", error);
      if (status !== 401) {
        message.error(`Failed to subscribe: ${msg}`);
      }

      if (error.response?.data?.needsUpgrade) {
        mixpanel.track("needs_plan_upgrade", {
          feature: "subscribe_twitter_list",
        });
      }
    }
  };
  React.useEffect(() => {
    loadUserLists();
  }, [loadUserLists]);

  return (
    <div>
      <Helmet>
        <title>Twitter List - Duome</title>
      </Helmet>
      {!hasAccess ? (
        <Alert
          message="Upgrade to Pro+ to access this feature"
          type="warning"
          showIcon
        />
      ) : null}
      <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
        <div>
          <Form.Item
            name="url"
            label="URL:"
            required
            help={
              active[0]
                ? `You are currently subscribed to "${active[0].name}". Making changes will replace it`
                : ""
            }
          >
            <Input
              type="url"
              name="twitter_list_url"
              placeholder="https://twitter.com"
              maxLength={140}
            />
          </Form.Item>
        </div>
        <br />
        <Form.Item>
          <Row justify="space-between">
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading || submitting}
              disabled={!hasAccess}
            >
              {active.length > 0 ? "Update" : "Subscribe"}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubscribeTwitterList;
