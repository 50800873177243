import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const UndoLeftIcon = ({ size = 14, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.08333 8.08334L1.75 5.75001M1.75 5.75001L4.08333 3.41667M1.75 5.75001H9.33333C10.9442 5.75001 12.25 7.05584 12.25 8.66667C12.25 10.2775 10.9442 11.5833 9.33333 11.5833H6.41667"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {...props}
    />
  );
};

export default UndoLeftIcon;
