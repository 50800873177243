import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Segmented,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import useAPI from "../../hooks/useAPI";
import { ResponseCategory } from "../../types/ResponseCategory";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";

type Props = {};
const CloneResponseCategories = (props: Props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fixedCategories, setFixedCategories] = React.useState<boolean | null>(
    null,
  );
  const [form] = Form.useForm();
  const API = useAPI();
  const loadSettings = React.useCallback(async () => {
    try {
      const data = await API.get(`/categories/settings`);
      if (data.isCustomized) {
        setFixedCategories(data.useFixedCategories);
      } else {
        setFixedCategories(null);
      }
    } catch (error) {
      console.error("Failed to load settings", error);
    }
  }, [API]);
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      mixpanel.track("update_fixed_response_categories", {
        count: values.categories?.length || null,
      });
      await API.put(`/categories/mass-update`, values);
      setSubmitting(false);
      message.success("Successfully updated categories");
    } catch (err) {
      setSubmitting(false);
      const error = err as any;
      const status = error.response?.status;
      if (status !== 401) {
        message.error("Failed to update categories");
      }
      console.error("Failed to save settings", error);
    }
  };
  const handleToggleMode = React.useCallback(
    async (fixed: boolean) => {
      try {
        await API.put(`/users/me`, { useFixedCategories: fixed });
        setFixedCategories(fixed);
        message.success("Successfully updated settings");
      } catch (err) {
        const error = err as any;
        const status = error.response?.status;
        if (status !== 401) {
          message.error(
            "Failed to update category settings. Please try again later or contact support.",
          );
        }
        console.error("Failed to update category settings", error);
      }
    },
    [API],
  );
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      await loadSettings();
      const data = await API.get<ResponseCategory[]>(`/categories`);
      const fieldValue = data.map((category) => ({
        id: category.id,
        prompt: category.prompt,
      }));
      setTimeout(() => {
        form.setFieldsValue({ categories: fieldValue });
        setLoading(false);
      }, 200);
    } catch (error) {
      console.error("Failed to load settings", error);
      setLoading(false);
    }
  }, [API, form, loadSettings]);
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const loadingIcon = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
  );

  return (
    <Card
      title="Response categories"
      extra={
        <Tooltip title="Reload">
          <Button
            icon={loading ? loadingIcon : <ReloadOutlined />}
            loading={loading}
            onClick={loadData}
          />
        </Tooltip>
      }
    >
      <Helmet>
        <title>Response Categories - Duome</title>
      </Helmet>
      <Segmented
        options={["Fixed Mode", "Dynamic Mode"]}
        block
        value={fixedCategories ? "Fixed Mode" : "Dynamic Mode"}
        onChange={(value) => handleToggleMode(value === "Fixed Mode")}
        size="large"
        style={{ marginBottom: 16 }}
      />

      {fixedCategories === true || fixedCategories === null ? (
        <Form form={form} onFinish={onFinish}>
          <div style={{ marginBottom: 16 }}>
            <Typography.Text>
              There are the various ways your AI clone will try to respond to
              people's tweets. It will only look for tweets where these types of
              responses are appropriate.
            </Typography.Text>
          </div>
          <Form.List
            name="categories"
            rules={[
              {
                validator: async (_, categories) => {
                  if (!categories || categories.length === 0) {
                    return Promise.reject(
                      new Error("You must have at least one category"),
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <Col md={18} sm={24} xl={18} xxl={15}>
                {fields.map((field, index) => (
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      name={[field.name, "prompt"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            "Please enter a category prompt or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="How could the AI respond to a tweet?"
                        style={{ width: "90%" }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <DeleteOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        style={{ marginLeft: 8 }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add category
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </Col>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Form.Item>
        </Form>
      ) : null}
      {fixedCategories === false ? (
        <div>
          <Typography.Text>
            Your clone will dynamically decide on the ideal response type based
            on your training data and brand positioning.
          </Typography.Text>
        </div>
      ) : null}
    </Card>
  );
};

export default CloneResponseCategories;
