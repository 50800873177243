import React from "react";
import { Col, Collapse, Row, Tag, Typography, message } from "antd";
import QuotaBar from "../QuotaBar";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import moment from "moment";
import {
  setActivityDataInStore,
  setActivityOverviewCollapsedInStore,
  setLastHideActivityInStore,
} from "../../redux/user/action";
import useAPI from "../../hooks/useAPI";
import { useInterval } from "../../hooks/useInterval";
import { Weekday } from "../../types/Schedule";

const { Panel } = Collapse;

const ActivityOverview: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const API = useAPI();
  const lastHideActivity = useAppSelector(
    (state) => state.user.lastHideActivity,
  );
  const isCollapsed = useAppSelector(
    (state) => state.user.activityOverviewCollapsed,
  );
  const userId = useAppSelector((state) => state.user.user?.id);
  const paused = useAppSelector((state) => state.user.user?.paused);
  const data = useAppSelector((state) => state.user.activity);
  const isNewUser = data?.batchCount === 0;
  const dispatch = useAppDispatch();
  const lastCollapsedALongTimeAgo = React.useMemo(() => {
    return (
      !lastHideActivity || moment().diff(moment(lastHideActivity), "hours") > 6
    );
  }, [lastHideActivity]);
  const handleCollapseToggle = (keys: string | string[]) => {
    const isCollapsed = keys.length === 0;
    dispatch(setActivityOverviewCollapsedInStore(isCollapsed));
    if (isCollapsed) {
      dispatch(setLastHideActivityInStore(moment().toISOString()));
    }
  };
  const loadData = React.useCallback(async () => {
    setLoading(true);
    try {
      const data = await API.get(`/users/${userId}/batch-pipeline-status`);
      dispatch(setActivityDataInStore(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const err = error as any;
      const source = err?.response?.data || err;
      console.error("Failed to load activity data", source);
      const msg = source?.message || "Unknown error";
      message.error(`Failed to load activity: ${msg}`);
    }
    setLoading(false);
  }, [API, dispatch, userId]);
  React.useEffect(() => {
    if (lastCollapsedALongTimeAgo) {
      dispatch(setActivityOverviewCollapsedInStore(true));
    }
  }, [dispatch, lastCollapsedALongTimeAgo]);
  useInterval(() => {
    if (moment().minute() % 10 === 0) {
      loadData();
    }
    // every min
  }, 5000 * 60);
  React.useEffect(() => {
    loadData();
  }, [loadData]);
  const countDaily = data?.generatedToday;
  const countWeekly = data?.generatedThisWeek;
  const maxWeekly = data?.limits?.weekly?.maxPerWeek;
  const today = moment().format("dddd").toLowerCase() as Weekday;
  const maxDaily = data?.limits?.schedule_adjusted?.[today]?.maxDaily;
  const lastTime = data?.lastActiveTime
    ? moment(data?.lastActiveTime).fromNow()
    : null;
  const nextTime = data?.nextActiveTime
    ? moment(data?.nextActiveTime)
        .fromNow(true)
        .replace("minutes", "mins")
        .replace("minute", "min")
        .replace("hours", "hrs")
        .replace("hour", "hr")
    : null;

  const introText = React.useMemo(() => {
    if (paused) {
      return (
        <Row align="middle" className="text-row">
          <Typography.Text>
            Your account is currently paused. Your clones will not start working
            until you unpause your account.
          </Typography.Text>
        </Row>
      );
    } else if (isNewUser) {
      return (
        <Row align="middle" className="text-row" style={{ margin: 0 }}>
          <Typography.Text>No recent activity to show yet.</Typography.Text>
        </Row>
      );
    } else {
      return (
        <Row align="middle" className="text-row">
          <Typography.Text>
            Your clone was active {lastTime || "many days ago"} and will start
            working in{" "}
            <Tag icon={<ClockCircleOutlined />} style={{ bottom: 1 }}>
              {nextTime || "99 days"}
            </Tag>{" "}
            on your next batch of replies.
          </Typography.Text>
        </Row>
      );
    }
  }, [lastTime, nextTime, paused, isNewUser]);

  return (
    <Collapse
      accordion
      ghost
      className="activity-overview"
      defaultActiveKey={
        lastCollapsedALongTimeAgo && !isNewUser ? "1" : undefined
      }
      activeKey={isCollapsed ? undefined : "1"}
      onChange={handleCollapseToggle}
    >
      <Panel
        header={
          <Typography.Title level={3} style={{ margin: 0 }}>
            Activity Overview
          </Typography.Title>
        }
        key="1"
      >
        {introText}
        {!paused && !isNewUser ? (
          <Row gutter={24}>
            <Col md={12} sm={24} xs={24}>
              <QuotaBar
                type="daily"
                count={countDaily}
                max={maxDaily}
                loading={loading}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <QuotaBar
                type="weekly"
                count={countWeekly}
                max={maxWeekly}
                loading={loading}
              />
            </Col>
          </Row>
        ) : null}
      </Panel>
    </Collapse>
  );
};

export default ActivityOverview;
