import React from "react";
import { Form, Checkbox, Button, message, Input } from "antd";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setTwitterSettingInStore } from "../../redux/user/action";
import SettingsCard from "../SettingsCard";
import { Helmet } from "react-helmet";

const TwitterSettings: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [form] = Form.useForm();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get(`/users/${userId}/twitter-settings`);
      dispatch(setTwitterSettingInStore(data));
      form.setFieldsValue(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to load settings", error);
    }
  }, [API, form, userId, dispatch]);
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      await API.put(`/users/${userId}/twitter-settings`, {
        autoLike: values.auto_like,
        autoPost: values.auto_post,
        skipReplies: values.skip_replies,
        likeOwnReplies: values.like_own_replies,
        decayHours: values.decay_hours,
      });
      setSubmitting(false);
      message.success("Successfully updated settings");
    } catch (error) {
      setSubmitting(false);
      console.error("Failed to save settings", error);
      message.error(
        "Failed to update settings. Please try again later or contact support.",
      );
    }
  };
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SettingsCard title="Automations" loading={loading}>
      <Helmet>
        <title>Automations - Settings</title>
      </Helmet>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="decay_hours"
          label="How long before re-engaging the same account?"
        >
          <Input type="number" placeholder="12 hours" />
        </Form.Item>
        <Form.Item name="auto_like" valuePropName="checked">
          <Checkbox>Auto-like every post I reply to?</Checkbox>
        </Form.Item>
        <Form.Item name="like_own_replies" valuePropName="checked">
          <Checkbox>Auto-like my own replies?</Checkbox>
        </Form.Item>
        <Form.Item name="skip_replies" valuePropName="checked">
          <Checkbox>Skip posts that are under thread?</Checkbox>
        </Form.Item>
        <Form.Item name="auto_post" valuePropName="checked">
          <Checkbox>
            Post AI replies automatically (do no wait for approval) ?
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </SettingsCard>
  );
};

export default TwitterSettings;
