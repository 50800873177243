import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  logOutInStore,
  setLastSeenReleaseInStore,
} from "../../redux/user/action";
import {
  Alert,
  Avatar,
  Dropdown,
  Grid,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Row,
  Space,
  Typography,
  theme,
} from "antd";
import GiftIcon from "../../icons/GiftIcon";
import { ReleaseNote } from "../../types/ReleaseNote";
import BrandMark from "../BrandMark";
import DuomeLogo from "../../icons/DuomeLogo";
import { Helmet } from "react-helmet";

const { Header, Content, Footer } = Layout;

type Props = {
  children?: React.ReactNode;
};
const DashboardWrapper: React.FC = ({ children }: Props) => {
  const navigate = useNavigate();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { token } = theme.useToken();
  const screens = Grid.useBreakpoint();
  const user = useAppSelector((state) => state.user.user);
  const onboardedAt = user?.onboarded_at;
  const lastSeenRelease = useAppSelector((state) => state.user.lastSeenRelease);
  const promoter = user?.promoter;
  const [release, setRelease] = React.useState<ReleaseNote | null>(null);
  const checkReleaseNotes = React.useCallback(async () => {
    try {
      const data = await API.get<ReleaseNote>(
        `/releases?date=${lastSeenRelease || "2021-01-01"}`,
      );
      setRelease(data);
    } catch (error) {
      console.error("Failed to load latest release notes", error);
    }
  }, [API, lastSeenRelease]);
  const handleLogout = React.useCallback(async () => {
    try {
      API.post(`/auth/logout`);
      dispatch(logOutInStore());
      navigate(`/login`);
    } catch (error) {
      console.error("Failed to logout", error);
    }
  }, [dispatch, navigate, API]);
  React.useEffect(() => {
    checkReleaseNotes();
  }, [checkReleaseNotes]);
  const navbarMenu = React.useMemo(() => {
    let options = [
      {
        key: "promoter",
        label: "Promoter",
      },
      {
        key: "feed",
        label: "Feed",
      },
      {
        key: "analytics",
        label: "Analytics",
      },
      {
        key: "settings",
        label: "Settings",
      },
    ];

    if (promoter?.expired) {
      return [options[0]];
    }

    if (!promoter?.id || promoter.verified_at) {
      options.shift();
    }

    if (!onboardedAt) {
      options = [];
    }

    return options;
  }, [onboardedAt, promoter?.verified_at, promoter?.expired, promoter?.id]);
  const handleNavigate = (key: string) => {
    if (key === "logout") {
      handleLogout();
      return;
    }

    navigate(key);
  };
  const handleDismissAnnouncement = React.useCallback(() => {
    dispatch(setLastSeenReleaseInStore(release?.date_launched || ""));
    setRelease(null);
  }, [dispatch, release]);
  const handleOpenNotes = React.useCallback(
    (url: string) => {
      // open in new tab
      handleDismissAnnouncement();
      window.open(url, "_blank");
    },
    [handleDismissAnnouncement],
  );
  const activeMenu = navbarMenu.find((item) =>
    location.pathname.match(item.key),
  );
  const isMajorVersion = release?.version?.charAt(0) === "V";
  const isMinorVersion = release?.version?.charAt(0) === "v";
  const userMenu: MenuProps["items"] = [
    {
      key: "/account/profile",
      label: "My account",
    },
    {
      key: "/account/notifications",
      label: "Notification preferences",
    },
    {
      key: "/refer-friends",
      label: "Free credits",
      icon: <GiftIcon size={16} style={{ color: token.colorPrimary }} />,
    },
    {
      key: "/grow-more",
      label: "Grow more",
    },
    {
      key: "/account/billing",
      label: "Manage billing",
    },
    {
      key: "logout",
      label: "Log out",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>Dashboard - Duome</title>
      </Helmet>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row align="middle" style={{ flex: 1 }}>
          {screens.md ? (
            <BrandMark style={{ height: 20 }} />
          ) : (
            <DuomeLogo style={{ marginRight: 8 }} />
          )}
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={activeMenu ? [activeMenu.key] : undefined}
            onClick={({ key }) => handleNavigate(key)}
            items={navbarMenu}
            style={{ flex: 1, minWidth: 0 }}
          />
        </Row>
        <Dropdown
          trigger={["click", "hover"]}
          menu={{
            items: userMenu,
            onClick: ({ key }) => handleNavigate(key),
          }}
        >
          <Avatar
            src={user?.profile_image_url}
            alt={user?.fullName || "User"}
            size={40}
            style={{ border: `1px solid ${token.colorPrimary}` }}
          >
            {user?.firstname?.charAt(0) || "U"}
          </Avatar>
        </Dropdown>
      </Header>
      {release && isMinorVersion ? (
        <Alert
          banner
          closable
          type="info"
          onClose={handleDismissAnnouncement}
          message={
            <Space>
              <Typography.Text>
                Hey {user?.firstname}! We're happy to announce we've made some
                recent changes to Duome. Check them out here:
              </Typography.Text>
              <a
                href={release.url}
                target="_blank"
                rel="noreferrer"
                onClick={handleDismissAnnouncement}
              >
                Release notes
              </a>
            </Space>
          }
        />
      ) : null}
      <Content style={{ flex: 1 }}>
        <Outlet />
      </Content>
      {release && isMajorVersion ? (
        <Modal
          title="New version launched!"
          open
          cancelText="Close"
          okText={`Open ${
            release.version.split("").pop() === "0" ? "video" : "notes"
          }`}
          onCancel={handleDismissAnnouncement}
          onOk={() => handleOpenNotes(release.url)}
        >
          <Typography.Paragraph>Hey {user?.firstname}!</Typography.Paragraph>
          <Typography.Paragraph>
            We're happy to announce we've made some exciting changes to Duome.
            Check out the{" "}
            <a href={release.url} target="_blank" rel="noreferrer">
              release notes
            </a>{" "}
            to see what's new.
          </Typography.Paragraph>
        </Modal>
      ) : null}
      <Footer>Thank you for being here. ❤️</Footer>
    </Layout>
  );
};

export default DashboardWrapper;
