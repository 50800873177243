import React from "react";
import { Form, Button, message, Select, Input, Typography, Space } from "antd";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  setTwitterSettingInStore,
  updateTwitterSettingInStore,
} from "../../redux/user/action";
import { NotificationPreference, User } from "../../types/User";
import { TwitterSettings } from "../../types/TwitterSetting";
import SettingsCard from "../SettingsCard";
import { CheckOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

const ManageNotification: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [connecting, setConnecting] = React.useState(false);
  const [startFlowLoading, setStartFlowLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [verifying, setVerifying] = React.useState(false);
  const [verifyUrl, setVerifyUrl] = React.useState("");
  const [mode, setMode] = React.useState<NotificationPreference | null>(null);
  const [form] = Form.useForm();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const email = useAppSelector((state) => state.user.user?.email)!;
  const canReceiveDM = useAppSelector(
    (state) => state.user.twitterSettings?.receive_dm_alerts,
  );
  const lastConnectedAt = useAppSelector(
    (state) => state.user.twitterSettings?.last_connected_at,
  );
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const [data, settings] = await Promise.all([
        API.get<User>(`/users/${userId}`),
        API.get<TwitterSettings>(`/users/${userId}/twitter-settings`),
      ]);
      dispatch(setTwitterSettingInStore(settings));
      form.setFieldsValue(data);
      setMode(data.notification_preference || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to load settings", error);
    }
  }, [API, form, userId, dispatch]);
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      await API.put(`/users/${userId}`, {
        phoneNumber: values.phone_number,
        notificationPreference: values.notification_preference,
        paused: values.paused,
      });
      setSubmitting(false);
      message.success("Successfully updated profile");
    } catch (error) {
      setSubmitting(false);
      console.error("Failed to save profile", error);
      message.error(
        "Failed to update profile. Please try again later or contact support.",
      );
    }
  };

  const onFinishConnecting = async (values: any) => {
    try {
      if (!verifyUrl) return;
      setVerifying(true);
      console.error("Received values of form:", values);
      await API.post("/twitter/setup/finish", values);
      setVerifying(false);
      dispatch(
        updateTwitterSettingInStore({
          last_connected_at: new Date().toISOString(),
        }),
      );
      message.success("Twitter account successfully connected!");
    } catch (err) {
      setVerifying(false);
      const error = err as any;
      const status = error.response?.status;
      console.error("failed to finish connecting", error);
      if (status !== 401) {
        message.error(
          "Failed to connect your Twitter account. Please try again or contact support.",
          5,
        );
      }
    }
  };
  const handleLinkDM = async () => {
    try {
      setConnecting(true);
      const data = await API.post(`/users/${userId}/twitter-connect/finish`);
      dispatch(updateTwitterSettingInStore(data));
      setConnecting(false);
    } catch (error) {
      setConnecting(false);
      console.error("Failed to link a connection with @duomeai", error);
      message.error(
        "Failed to connect to Twitter. Please try again later or contact support.",
      );
    }
  };
  const handleStartAuth = async () => {
    try {
      setStartFlowLoading(true);
      const data = await API.post(`/twitter/setup/start`);
      setVerifyUrl(data.authorizeUrl);
      setStartFlowLoading(false);
    } catch (error) {
      setStartFlowLoading(false);
      console.error("Failed to link a connection with @duomeai", error);
      message.error(
        "Failed to connect to Twitter. Please try again later or contact support.",
      );
    }
  };
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SettingsCard
      title="Notification preferences"
      subtitle="We can notify you about your batches however is convenient for you."
      loading={loading}
    >
      <Helmet>
        <title>Notification Preferences - Duome</title>
      </Helmet>
      <Form
        form={form}
        onFieldsChange={(changed) => {
          if (changed[0].name[0] === "notification_preference") {
            setMode(changed[0].value);
          }
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="notification_preference"
          label="Notification preference"
          required
        >
          <Select>
            <Select.Option value="email">Email</Select.Option>
            <Select.Option value="sms">SMS</Select.Option>
            <Select.Option value="dm">Twitter DM</Select.Option>
            <Select.Option value="none">None</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Email" hidden={mode !== "email"}>
          <Input placeholder="smart@duome.ai" value={email} disabled />
        </Form.Item>

        <Form.Item
          name="phone_number"
          label="Phone number"
          hidden={mode !== "sms"}
        >
          <Input placeholder="+1 123-456-7890" />
        </Form.Item>

        <Form.Item hidden={mode !== "none"}>
          <Typography.Text type="warning">
            Are you sure? You will not receive any notifications about your
            account's activity.
          </Typography.Text>
        </Form.Item>
        <Form.Item hidden={mode !== "dm" || !canReceiveDM}>
          <Typography.Text type="success">
            DM notifications are enabled and set up.
          </Typography.Text>
        </Form.Item>
        <Form.Item hidden={mode !== "dm" || canReceiveDM}>
          <Typography.Text type="secondary">
            If you want to receive notifications through DM and more, please
            press the below to get connected with the{" "}
            <a
              href="https://twitter.com/duomeai"
              target="_blank"
              rel="noreferrer"
            >
              @duomeai
            </a>{" "}
            account.
          </Typography.Text>
          <Button
            onClick={lastConnectedAt ? handleLinkDM : handleStartAuth}
            loading={startFlowLoading || connecting}
            disabled={canReceiveDM}
            icon={canReceiveDM ? <CheckOutlined /> : null}
            style={{ marginLeft: 8 }}
          >
            {canReceiveDM
              ? "Connected"
              : lastConnectedAt
              ? "Link to DM"
              : "Connect"}
          </Button>
        </Form.Item>
        {verifyUrl && !lastConnectedAt ? (
          <Form onFinish={onFinishConnecting}>
            <Typography.Text>
              Re-connect your Twitter account with higher permissions by
              clicking
              <a href={verifyUrl} target="_blank" rel="noreferrer">
                {" "}
                here.
              </a>
            </Typography.Text>
            <Space size="small">
              <Form.Item name="verifier" required>
                <Input
                  maxLength={10}
                  placeholder="042938"
                  disabled={!!lastConnectedAt && !verifyUrl}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={verifying}
                  disabled={!!lastConnectedAt && !verifyUrl}
                >
                  Verify
                </Button>
              </Form.Item>
            </Space>
          </Form>
        ) : null}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </SettingsCard>
  );
};

export default ManageNotification;
