import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  message,
  Row,
  Col,
  Divider,
  theme,
} from "antd";
import { useNavigate } from "react-router";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch } from "../../hooks/redux";
import { setUserInStore } from "../../redux/user/action";
import {
  AuthError,
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { User } from "../../types/User";
import mixpanel from "mixpanel-browser";
import { ThemedApp } from "../../theme";
import GoogleIcon from "../../icons/GoogleIcon";
import EmailIcon from "../../icons/EmailIcon";
import LockIcon from "../../icons/LockIcon";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const { useToken } = theme;

const LoginV2 = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const { token } = useToken();
  const [form] = Form.useForm();
  const handleLoginToBackend = async (token: string) => {
    try {
      const data = await API.post<User>("/auth/firebase", { token });
      dispatch(setUserInStore(data!));
      message.success(`Welcome back, ${data!.firstname}`);
      if (!data?.onboarded_at) {
        navigate("/onboarding");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Failed to login to backend API", error);
      throw error;
    }
  };
  const handleSignUpWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope("openid");
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
      const credential = await signInWithPopup(auth, provider);
      const idToken = await credential.user.getIdToken();
      await handleLoginToBackend(idToken);
      mixpanel.track("login", {
        method: "google",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const err = error as AuthError;
      console.error("Failed to login", err);
      if (err.code === "auth/invalid-credential") {
        form.setFields([
          {
            name: "password",
            errors: ["Invalid credentials provided"],
          },
        ]);
      } else {
        message.error("Login request was unsuccessful. Please try again");
      }
    }
  };
  const handleLogin = async (values: any) => {
    try {
      setLoading(true);
      const credential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      );
      const idToken = await credential.user.getIdToken();
      await handleLoginToBackend(idToken);
      setLoading(false);
      mixpanel.track("login", {
        method: "vanilla",
      });
    } catch (error) {
      setLoading(false);
      const err = error as AuthError;
      console.error("Failed to login", err);
      if (err.code === "auth/invalid-credential") {
        form.setFields([
          {
            name: "password",
            errors: ["Invalid credentials provided"],
          },
        ]);
      } else {
        message.error("Login request was unsuccessful. Please try again");
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Sign in to Duome</title>
      </Helmet>
      <Typography.Title style={{ textAlign: "center", marginBottom: 48 }}>
        Sign in to Duome
      </Typography.Title>
      <ThemedApp version="neutral">
        <Button
          icon={<GoogleIcon size={18} style={{ fontSize: 24 }} />}
          onClick={handleSignUpWithGoogle}
          block
          type="primary"
          size="large"
          style={{ color: "#7b7b7b" }}
        >
          Log in with Google
        </Button>
      </ThemedApp>
      <Divider plain>or</Divider>
      <Form form={form} onFinish={handleLogin} layout="vertical">
        <Form.Item
          name="email"
          label="Email:"
          rules={[
            { required: true, message: "Please enter your email" },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input
            type="email"
            name="email"
            placeholder="john.doe@gmail.com"
            prefix={<EmailIcon style={{ color: token.colorTextSecondary }} />}
            maxLength={80}
            required
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password:"
          rules={[{ required: true, message: "Please enter your password" }]}
        >
          <Input.Password
            placeholder="Password"
            name="password"
            required
            prefix={<LockIcon style={{ color: token.colorTextSecondary }} />}
          />
        </Form.Item>
        <Row justify="end">
          <Col>
            <Typography.Text>
              <Link to="/forgot-password">
                <Typography.Link>Forgot password?</Typography.Link>
              </Link>
            </Typography.Text>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="large"
            style={{ marginTop: 16 }}
          >
            Sign In
          </Button>
        </Form.Item>
        <Row style={{ marginTop: 8 }}>
          <Col>
            <Typography.Text>
              Don't have an account?{" "}
              <Link to="/signup">
                <Typography.Link strong>Sign up</Typography.Link>
              </Link>
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LoginV2;
