import React, { useState } from "react";
import { Form, Input, Button, Typography, message, theme, Space } from "antd";
import { AuthError, getAuth, sendPasswordResetEmail } from "firebase/auth";
import EmailIcon from "../../icons/EmailIcon";
import { Link } from "react-router-dom";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import { Helmet } from "react-helmet";

const { useToken } = theme;

const ForgotPasswordV2 = () => {
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const { token } = useToken();
  const [form] = Form.useForm();
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, values.email);
      setLoading(false);
      message.success(
        "If your email is registered, you will receive a reset link",
      );
    } catch (error) {
      setLoading(false);
      const err = error as AuthError;
      console.error("Failed to login", err);
      const errMessage = err.message || "Unknown error";
      message.error(`Failed to send reset email: ${errMessage}`);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Forgot password - Duome</title>
      </Helmet>
      <Space
        direction="vertical"
        size="small"
        style={{ width: "100%", marginBottom: 32 }}
      >
        <Typography.Title style={{ marginBottom: 0 }}>
          Forgot password?
        </Typography.Title>
        <Typography.Paragraph type="secondary">
          No worries, we'll send you reset instructions.
        </Typography.Paragraph>
      </Space>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="email"
          label="Email:"
          rules={[
            { required: true, message: "Please enter your email" },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input
            type="email"
            name="email"
            placeholder="john.doe@gmail.com"
            prefix={<EmailIcon style={{ color: token.colorTextSecondary }} />}
            maxLength={80}
            required
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="large"
            style={{ marginTop: 16 }}
          >
            Reset password
          </Button>
        </Form.Item>
        <Link to="/login">
          <Button
            type="link"
            block
            icon={<ArrowLeftIcon size={16} style={{ fontSize: 18 }} />}
          >
            Back to login
          </Button>
        </Link>
      </Form>
    </div>
  );
};

export default ForgotPasswordV2;
