import React from "react";
import { Result, Button, message, Spin, Row, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import useAPI from "../../hooks/useAPI";
import mixpanel from "mixpanel-browser";
import { useAppDispatch } from "../../hooks/redux";
import { updateUserInStore } from "../../redux/user/action";

const CheckoutReturn = () => {
  const navigate = useNavigate();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get("session_id");
  const [verifying, setVerifying] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const handleVerifySubscription = React.useCallback(async () => {
    try {
      const data = await API.get(`/billing/verify`);
      if (data.active) {
        message.success("Payment successful! Thank you for your purchase.", 3);
        mixpanel.track("subscription_verified", {
          plan: data?.user?.billing_plan,
        });
        // @ts-ignore
        window.gtag?.("event", "purchase_successful", {
          app_name: "duome",
          screen_name: "CheckoutReturn",
          plan: data?.user?.billing_plan || "N/A",
        });
        dispatch(updateUserInStore(data.user));
        navigate("/home");
      } else {
        message.error(data.message);
      }
    } catch (err) {
      console.error("Failed to check subscription status", err);
      throw err;
    }
  }, [API, dispatch, navigate]);
  const handleCheckSession = React.useCallback(async () => {
    try {
      setVerifying(true);
      const { success, session } = await API.get(
        `/billing/session/checkout/${sessionId}`,
      );
      setVerifying(false);
      mixpanel.track("checkout_session_verified", { status: session.status });
      if (session.status === "complete") {
        await handleVerifySubscription();
        return;
      }

      setInvalid(!success);
    } catch (e) {
      const err = e as any;
      const msg = err.response?.data?.message || "Unknown error";
      setVerifying(false);
      setInvalid(true);
      console.error("Failed to check session", err);
      messageApi.error(`Verification failed: ${msg}`);
    }
  }, [API, sessionId, messageApi, handleVerifySubscription]);
  React.useEffect(() => {
    handleCheckSession();
  }, [handleCheckSession]);

  return (
    <div className="settings-container">
      <Helmet>
        <title>Processing payments - Duome</title>
      </Helmet>
      <Row justify="center" style={{ marginTop: "5vh " }}>
        {verifying ? (
          <Empty
            image={<Spin size="large" />}
            description={"Verifying status..."}
          />
        ) : null}
        {invalid ? (
          <Result
            status="error"
            title="Ooops, something went wrong"
            subTitle="Sorry, the page you were expecting to see could not be shown. Please try again later or contact support"
            extra={[
              <Button
                key="home"
                type="primary"
                onClick={() => navigate("/login")}
              >
                Go Home
              </Button>,
            ]}
          />
        ) : null}
      </Row>
      {contextHolder}
    </div>
  );
};

export default CheckoutReturn;
