import React from "react";
import { Progress, Row, Typography, theme } from "antd";
import { BatchJob } from "../../types/BatchJob";
import moment from "moment";
import capitalize from "lodash/capitalize";
import { Link } from "react-router-dom";
import ChevronRightIcon from "../../icons/ChevronRightIcon";
import CheckIcon from "../../icons/CheckIcon";
import CloseIcon from "../../icons/CloseIcon";

const { useToken } = theme;

type Props = {
  batch: BatchJob;
  clickable?: boolean;
};
const BatchHeader = ({ batch, clickable }: Props) => {
  const timestamp =
    batch.approved_at || batch.last_progress_at || batch.updated_at;
  const inReview = batch.status === "requested_approval";
  const inProgress =
    batch.status === "in_progress" || batch.status === "pending";
  const chunksCount = batch.last_chunk_offset / 10;
  const reviewing = chunksCount > 0 && !batch.approved_at;
  const repliesCount = batch.tweetRepliesCount || 0;
  const totalReplies = batch.maxForBatch || 1;
  const totalChunks = Math.ceil(repliesCount / 10);
  const { token } = useToken();
  const lastChange = React.useMemo(() => {
    // format timestamp to abbreviated time ago such as 8d ago or 2h ago, etc
    if (!timestamp) return "";
    const time = moment(timestamp)
      .fromNow()
      .replace("a minute", "1m")
      .replace("an hour", "1h")
      .replace("a day", "1d")
      .replace("a month", "1mo")
      .replace(/\sminutes/, "mins")
      .replace(/\sminute/, "min")
      .replace(/\shours/, "hrs")
      .replace(/\shour/, "hr")
      .replace(/\sdays/, "d")
      .replace(/\sday/, "d")
      .replace(/\smonths?/, "mo");

    if (
      batch.status === "approved" ||
      batch.status === "sent" ||
      batch.status === "failed" ||
      batch.status === "ignored" ||
      batch.status === "expired"
    ) {
      return `${batch.status} ${time}`;
    } else if (
      batch.status === "requested_approval" ||
      batch.status === "in_progress" ||
      batch.status === "pending"
    ) {
      return `updated ${time}`;
    }
  }, [timestamp, batch.status]);
  const statusLabel = React.useMemo(() => {
    const text = capitalize(batch.status.replace(/_/g, " "));
    if (reviewing) {
      return `${chunksCount}/${totalChunks} chunks reviewed`;
    } else if (batch.status === "requested_approval") {
      return "Ready for review";
    } else if (inProgress) {
      return "In progress";
    }

    return text;
  }, [batch.status, inProgress, reviewing, chunksCount, totalChunks]);
  const statusType = React.useMemo(() => {
    if (reviewing) {
      return;
    } else if (
      batch.status === "failed" ||
      batch.status === "ignored" ||
      batch.status === "expired"
    ) {
      return "danger";
    } else if (
      inReview ||
      batch.status === "sent" ||
      batch.status === "approved"
    ) {
      return "success";
    }
  }, [inReview, batch.status, reviewing]);
  const percent = React.useMemo(() => {
    if (chunksCount > 0) {
      return Math.round((chunksCount / totalChunks) * 100);
    } else {
      return Math.round((repliesCount / totalReplies) * 100);
    }
  }, [chunksCount, totalChunks, repliesCount, totalReplies]);

  const Component = (
    <div className="batch-header-wrapper">
      <Row className={clickable ? "clickable" : ""}>
        <Row
          justify="space-between"
          align="middle"
          className={`batch-header ${inProgress ? "in-progress" : ""}`}
        >
          <Row align="middle">
            {inProgress || reviewing ? (
              <div className="column centered" style={{ marginRight: 8 }}>
                <Progress
                  type="circle"
                  percent={percent}
                  size={24}
                  strokeColor={token.colorTextBase}
                  trailColor="#ababab"
                  strokeWidth={10}
                  strokeLinecap="square"
                  showInfo={false}
                />
                <Typography.Text className="line-height-1 percent-text">
                  {percent}%
                </Typography.Text>
              </div>
            ) : null}
            <div className="column">
              <Typography.Text
                strong
                className="neutral-200-text line-height-1"
                style={{ marginBottom: 4 }}
                id={`batch-${batch.id}`}
              >
                BATCH #{batch.id}
              </Typography.Text>
              <Row align="middle">
                {statusType === "success" ? (
                  <CheckIcon style={{ marginRight: 4 }} />
                ) : null}
                {statusType === "danger" ? (
                  <CloseIcon style={{ marginRight: 4, color: "#FFA38A" }} />
                ) : null}
                <Typography.Text
                  className="text-medium line-height-1"
                  type={statusType}
                >
                  {statusLabel}
                </Typography.Text>
              </Row>
            </div>
          </Row>
          <div className="column">
            <Typography.Text className="align-text-right smaller-text neutral-300-text">
              {batch.goodRepliesCount === batch.tweetRepliesCount
                ? `${batch.tweetRepliesCount} replies`
                : `${batch.goodRepliesCount}/${batch.tweetRepliesCount} replies`}
            </Typography.Text>
            <Typography.Text className="align-text-right smaller-text neutral-300-text">
              {lastChange}
            </Typography.Text>
          </div>
        </Row>

        {clickable ? (
          <ChevronRightIcon
            size={28}
            style={{ marginLeft: 8, color: token.colorText }}
          />
        ) : null}
      </Row>
      {inProgress ? (
        <Typography.Paragraph
          type="secondary"
          className="smaller-text in-progress-text"
          strong
        >
          LOOKING FOR GOOD TWEETS...
        </Typography.Paragraph>
      ) : null}
    </div>
  );

  return clickable ? (
    <Link to={`/feed/history/${batch.id}`}>{Component}</Link>
  ) : (
    Component
  );
};

export default BatchHeader;
