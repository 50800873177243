import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Tag,
  Typography,
  message,
} from "antd";
import useAPI from "../../hooks/useAPI";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router";
import { updateUserInStore } from "../../redux/user/action";

const { Title, Text } = Typography;

const PromoterStart = () => {
  const API = useAPI();
  const [form] = Form.useForm();
  const [sending, setSending] = React.useState(false);
  const userId = useAppSelector((state) => state.user.user?.id);
  const promoter = useAppSelector((state) => state.user.user?.promoter);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const handleSendPost = React.useCallback(
    async (values: any) => {
      try {
        const hasMentions =
          values.content.includes("@duomeai") ||
          values.content.includes("duome.ai");

        if (!hasMentions) {
          return form.setFields([
            {
              name: "content",
              errors: ["Please mention @duomeai or duome.ai in your post"],
            },
          ]);
        }

        setSending(true);
        const data = await API.post(`/users/${userId}/promoter/post`, values);
        messageApi.success("Post sent successfully", 3);
        dispatch(updateUserInStore(data));
        setSending(false);
      } catch (e) {
        const err = e as any;
        const msg = err.response?.data?.message || "Failed to start promoter";
        setSending(false);
        messageApi.error(`Error occured: ${msg}`, 3);
        console.error("Failed to start promoter", err);
      }
    },
    [API, messageApi, userId, dispatch, form],
  );
  React.useEffect(() => {
    if (!promoter?.started_at) {
      navigate("/promoter/onboard");
    } else if (promoter?.sent_at) {
      navigate("/promoter/pending");
    }
  }, [promoter, navigate]);

  return (
    <div className="settings-container">
      <Helmet>
        <title>Earn starting credits - Duome</title>
      </Helmet>
      <Row justify="center">
        <Col xxl={10} xl={14} lg={18} sm={22} xs={23}>
          <Card>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                content: `So I'm getting started with duome.ai, which I've heard is a great tool to grow on Twitter. Let's see how it goes! @duomeai`,
              }}
              onFinish={handleSendPost}
            >
              <Title level={2}>Earn your starting credits</Title>
              <Form.Item>
                <Text style={{ lineHeight: 1.75 }}>
                  Here is a post teasing that you are getting started with
                  Duome. Please either mention the{" "}
                  <Tag style={{ marginRight: 4 }}>duome.ai</Tag> URL or tag the{" "}
                  <Tag style={{ marginRight: 4 }}>@duomeai</Tag> account before
                  posting.
                </Text>
              </Form.Item>
              <Form.Item
                name="content"
                rules={[
                  { required: true, message: "Please enter the post" },
                  { min: 10, message: "Post must be at least 10 characters" },
                  {
                    max: 140,
                    message: "Post must be less than 140 characters",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="I'm getting
                started with Duome, a great tool to manage your Twitter
                followers. Check it out at duome.ai"
                  rows={4}
                  maxLength={140}
                />
              </Form.Item>
              <Form.Item>
                <Text>
                  Once completed, you will earn <b>$10 in credits</b> towards
                  your first month bill.
                </Text>
              </Form.Item>
              <Form.Item style={{ marginTop: 24 }}>
                <Button
                  type="primary"
                  loading={sending}
                  htmlType="submit"
                  block
                >
                  Post
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
};

export default PromoterStart;
