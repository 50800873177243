import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import App from "./App";
//import JobsList from "./pages/JobsList";
//import ApproveJob from "./pages/ApproveJob";
//import AuthOnly from "./components/AuthOnly";
//import Login from "./pages/Login";
//import Settings from "./pages/Settings";
import Onboarding from "./pages/Onboarding";
//import SignUp from "./pages/SignUp";
//import LoginAsPage from "./pages/LoginAs/LoginAs";
import Analytics from "./pages/Analytics";
//import ForgotPassword from "./pages/ForgotPassword";
import { Card, Col } from "antd";
import PickTargetAudience from "./components/PickTargetAudience";
import ManageBilling from "./components/ManageBilling";
import WorkSchedule from "./components/WorkSchedule";
import TwitterSettings from "./components/TwitterSettings";
import CloneResponseCategories from "./components/CloneResponseCategories/CloneResponseCategories";
import UserAccountSettings from "./components/UserAccountSettings";
import WritingRules from "./components/WritingRules";
import SubscribeTwitterList from "./components/SubscribeTwitterList";
import { ThemedApp } from "./theme";
import LoginV2 from "./pages/Login/LoginV2";
import SignUpV2 from "./pages/SignUp/SignUpV2";
import AuthPageWrapper from "./components/AuthPageWrapper";
import ForgotPasswordV2 from "./pages/ForgotPassword/ForgotPasswordV2";
import GatedForLoggedUser from "./components/GatedForLoggedUser";
import DashboardWrapper from "./components/DashboardWrapper";
import RepliesFeed from "./pages/RepliesFeed";
import FocusedFeedPosts from "./pages/FocusedFeedPosts";
import ReferFriends from "./pages/ReferFriends";
import BatchPerformance from "./pages/BatchPerformance";
import BatchJobsList from "./pages/BatchJobsList";
import EngagementSettings from "./pages/EngagementSettings";
import SettingsCard from "./components/SettingsCard";
import ManageAccount from "./pages/ManageAccount";
import ManageNotification from "./components/ManageNotification";
import AcceptInvite from "./pages/AcceptInvite";
import GatedForGuestUser from "./components/GatedForGuestUser";
import Blacklists from "./components/Blacklists/Blacklists";
import CheckoutReturn from "./pages/CheckoutReturn";
import PromoterOnboard from "./pages/PromoterOnboard";
import PromoterStart from "./pages/PromoterStart";
import PromoterPending from "./pages/PromoterPending";
import GrowMore from "./pages/GrowMore";
import FinalApproveBatch from "./pages/FinalApproveBatch";

const SettingsPageWrapper = (children: any) => (
  <Col xxl={15} xl={20} lg={23} md={24}>
    {children}
  </Col>
);
const AccountPageWrapper = (children: any) => (
  <Col xxl={10} xl={12} lg={18} md={20} sm={24}>
    {children}
  </Col>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App>
        <ThemedApp version="primary" />
      </App>
    ),
    errorElement: (
      <ThemedApp version="primary">
        <ErrorPage />
      </ThemedApp>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/home" />,
      },
      {
        element: <AuthPageWrapper />,
        children: [
          {
            path: "login",
            element: <LoginV2 />,
          },
          {
            path: "signup",
            element: <SignUpV2 />,
          },
          {
            path: "forgot-password",
            element: <ForgotPasswordV2 />,
          },
        ],
      },
      {
        element: <GatedForLoggedUser />,
        children: [
          {
            element: <DashboardWrapper />,
            children: [
              {
                path: "home",
                element: <Navigate to="/feed" />,
              },
              {
                path: "refer-friends",
                element: <ReferFriends />,
              },
              {
                path: "grow-more",
                element: <GrowMore />,
              },
              {
                path: "analytics",
                element: <Analytics />,
              },
              {
                path: "promoter",
                element: <Navigate to="onboard" />,
              },
              {
                path: "promoter/onboard",
                element: <PromoterOnboard />,
              },
              {
                path: "promoter/start",
                element: <PromoterStart />,
              },
              {
                path: "promoter/pending",
                element: <PromoterPending />,
              },
              {
                path: "onboarding",
                element: <Onboarding />,
              },
              {
                path: "onboarding/:step",
                element: <Onboarding />,
              },
              {
                path: "feed",
                element: <RepliesFeed />,
                children: [
                  {
                    path: "",
                    element: <FocusedFeedPosts />,
                  },
                  {
                    path: "history",
                    element: <BatchJobsList />,
                  },
                  {
                    path: "history/:batchId",
                    element: <FocusedFeedPosts />,
                  },
                  {
                    path: ":batchId/approve",
                    element: <FinalApproveBatch />,
                  },
                  {
                    path: "performance",
                    element: <BatchPerformance />,
                  },
                ],
              },
              {
                path: "settings",
                element: <EngagementSettings path="settings" />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="audience" />,
                  },
                  {
                    path: "schedule",
                    element: SettingsPageWrapper(
                      <SettingsCard
                        title="Work schedule"
                        subtitle="
                        Control when your clone is actively checking Twitter for new posts to
                        engage"
                      >
                        <WorkSchedule />
                      </SettingsCard>,
                    ),
                  },
                  {
                    path: "response-categories",
                    element: SettingsPageWrapper(<CloneResponseCategories />),
                  },
                  {
                    path: "writing-rules",
                    element: SettingsPageWrapper(<WritingRules />),
                  },
                  {
                    path: "blacklists",
                    element: SettingsPageWrapper(<Blacklists />),
                  },
                  {
                    path: "automations",
                    element: SettingsPageWrapper(<TwitterSettings />),
                  },
                  {
                    path: "audience",
                    element: SettingsPageWrapper(
                      <Card title="Target audience">
                        <PickTargetAudience />
                      </Card>,
                    ),
                  },
                  {
                    path: "twitter-list",
                    element: SettingsPageWrapper(
                      <SettingsCard
                        title="Subscribe to Twitter List"
                        subtitle="
                        Subscribe to a Twitter List to get the latest tweets from the list
                        members. Ideal for keeping up with posts from large influencers"
                      >
                        <SubscribeTwitterList />
                      </SettingsCard>,
                    ),
                  },
                ],
              },
              {
                path: "account",
                element: <ManageAccount />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="profile" />,
                  },
                  {
                    path: "profile",
                    element: AccountPageWrapper(<UserAccountSettings />),
                  },
                  {
                    path: "notifications",
                    element: AccountPageWrapper(<ManageNotification />),
                  },
                  {
                    path: "billing",
                    element: AccountPageWrapper(<ManageBilling />),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "checkout/return",
        element: <CheckoutReturn />,
      },
      {
        element: <GatedForGuestUser />,
        children: [
          {
            path: "invite",
            element: <AcceptInvite />,
          },
        ],
      },
    ],
  },
]);

export default router;
