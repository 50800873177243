import { Col, Form, Grid, Input, Row, Select, Spin, message } from "antd";
import React from "react";
import { TweetReply } from "../../types/TweetReply";
import { useAppSelector } from "../../hooks/redux";
import useAPI from "../../hooks/useAPI";
import mixpanel from "mixpanel-browser";

const { useBreakpoint } = Grid;

type Props = {
  item: TweetReply;
  preview?: boolean;
  disabled?: boolean;
  onUpdate: (item: Partial<TweetReply>) => void;
};
const UpdateReplyForm = ({
  item,
  onUpdate,
  disabled,
  preview = false,
}: Props) => {
  const API = useAPI();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [generating, setGenerating] = React.useState(false);
  const [highlightField, setHighlightField] = React.useState(false);
  const [newCategory, setNewCategory] = React.useState<number | null>(null);
  const categories = useAppSelector((state) => state.user.categories);
  const screens = useBreakpoint();
  const isMobile = !screens.lg;
  const categoriesMap = React.useMemo(() => {
    // Convert array to map
    const map: Record<number, string> = {} as any;
    categories.forEach((category) => {
      map[category.id] = category.prompt;
    });
    return map;
  }, [categories]);
  const activeCategoryId =
    newCategory ||
    Number(item.user_category) ||
    Number(item.ai_category) ||
    item.user_category ||
    item.ai_category ||
    null;
  const generateNewReply = React.useCallback(
    async (categoryId: number) => {
      try {
        const category = categoriesMap[categoryId];
        mixpanel.track("generate_new_reply", {
          tweet_reply_id: item.id,
          category,
          original_category: item.ai_category,
        });
        setGenerating(true);
        const data = await API.post(`/replies/${item.id}/regenerate`, {
          category,
          categoryId,
        });

        if (data.aiResponse) {
          form.setFieldsValue({
            ai_response: data.aiResponse,
            user_response: data.aiResponse,
          });
        }
        message.success("Successfully generated a new reply");
        setGenerating(false);
        setHighlightField(true);
        setTimeout(() => {
          setHighlightField(false);
        }, 2500);
      } catch (err) {
        setGenerating(false);
        const error = err as any;
        const status = error.response?.status;
        console.error("Failed to generate new reply", err);
        if (status !== 401) {
          message.error(
            "Failed to generate new reply. Please try again later.",
          );
        }
      }
    },
    [item.id, form, API, categoriesMap, item.ai_category],
  );
  const handleFieldsChanged = React.useCallback(
    (fields: any[]) => {
      // See if the `user_category` field was updated
      const hasCategoryField = fields.find((field) =>
        field.name.find((name: string) => name === "user_category"),
      );
      if (hasCategoryField) {
        generateNewReply(hasCategoryField.value);
        setNewCategory(hasCategoryField.value);
        form.setFieldsValue({
          user_category: null,
        });
      }
    },
    [generateNewReply, form],
  );
  const handleSubmit = async (values: Record<string, any>) => {
    try {
      const modified = item.ai_response !== values.user_response;
      if (!newCategory && !modified) {
        console.log("Nothing changed, so nothing to update.");
        return;
      }
      mixpanel.track("persist_updates", {
        tweet_reply_id: item.id,
        original_category: item.ai_category,
        new_category: activeCategoryId,
        // TODO: Get diff of old and new response to see
        // how much users are changing the response
      });
      setLoading(true);
      const data = await API.put(`/replies/${item.id}/response`, {
        response: values.user_response,
        modified,
      });
      setLoading(false);
      delete data.loaded_at;
      onUpdate(data);
      setHighlightField(true);
      message.success("Successfully updated the scheduled reply!", 2, () =>
        setHighlightField(false),
      );
    } catch (err) {
      setLoading(false);
      const error = err as any;
      const status = error.response?.status;
      if (status !== 401) {
        message.error("Failed to update reply. Please try again later.");
      }
      console.error("Failed to update reply", err);
    }
  };
  React.useEffect(() => {
    if (preview) {
      form.setFieldsValue({
        user_response: item.ai_response,
      });
    }
  }, [item.ai_response, preview, form]);
  const wasUpdated = !!newCategory || !!item.user_category;
  const categoryHint = React.useMemo(() => {
    const value =
      activeCategoryId && typeof activeCategoryId === "number"
        ? categoriesMap[activeCategoryId]
        : null;
    const name = wasUpdated ? "Updated" : "Initial";
    const key =
      typeof activeCategoryId === "string" ? activeCategoryId : "Unknown";
    return !value ? key : `${name} category: ${value}`;
  }, [activeCategoryId, categoriesMap, wasUpdated]);

  return (
    <Form
      form={form}
      layout="vertical"
      disabled={disabled || loading}
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChanged}
      initialValues={{
        user_response: item.user_response || item.ai_response,
      }}
    >
      {!preview ? (
        <Row align="middle">
          <Spin
            spinning={generating}
            style={{ marginBottom: 24, marginRight: 12 }}
          />
          <Col xxl={8} xl={9} lg={12} md={18} sm={24}>
            <Form.Item name="user_category">
              <Select
                loading={generating}
                disabled={disabled}
                placeholder={
                  generating
                    ? "Working hard on this..."
                    : "Select category to re-generate reply"
                }
                style={{ width: "100%" }}
              >
                {categories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.prompt}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ) : null}
      <Form.Item
        label="Reply:"
        name="user_response"
        required
        help={categoryHint}
        rules={[
          {
            required: true,
            message: "Please enter a reply",
          },
          {
            max: 280,
            message: "Reply must be less than 280 characters",
          },
        ]}
      >
        <Input.TextArea
          value={item.user_response || item.ai_response}
          className={`highlight-field ${
            highlightField || loading ? "active" : ""
          }`}
          disabled={disabled}
          showCount
          maxLength={280}
          autoSize={{ minRows: isMobile ? 5 : 3 }}
          onBlur={() => form.submit()}
        />
      </Form.Item>
    </Form>
  );
};

export default UpdateReplyForm;
