import { AnyAction, Reducer } from "redux";
import { UserActionTypes, UserState } from "./action";

const initialState: UserState = {
  user: null,
  twitterSettings: null,
  categories: [],
};

const userReducer: Reducer<UserState, AnyAction> = (
  state = initialState,
  action,
): UserState => {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UserActionTypes.SET_TWITTER_SETTING:
      return {
        ...state,
        twitterSettings: action.payload,
      };
    case UserActionTypes.UPDATE_TWITTER_SETTING:
      return {
        ...state,
        twitterSettings: {
          ...state.twitterSettings,
          ...action.payload,
        },
      };
    case UserActionTypes.UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case UserActionTypes.LOG_OUT:
      return {
        ...state,
        user: null,
      };
    case UserActionTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case UserActionTypes.SET_LAST_SEEN_RELEASE:
      return {
        ...state,
        lastSeenRelease: action.payload,
      };
    case UserActionTypes.SET_PIPELINE_STATUS:
      return {
        ...state,
        pipelineIsFull: action.payload,
      };
    case UserActionTypes.SET_SCHEDULED_FOR_TODAY:
      return {
        ...state,
        scheduledForToday: action.payload,
      };
    case UserActionTypes.SET_ACTIVITY_OVERVIEW_COLLAPSED:
      return {
        ...state,
        activityOverviewCollapsed: action.payload,
      };
    case UserActionTypes.SET_ACTIVITY_DATA:
      return {
        ...state,
        activity: action.payload,
      };
    case UserActionTypes.SET_USAGE_LIMITS:
      return {
        ...state,
        usageLimits: action.payload,
      };
    case UserActionTypes.SET_IS_WITHIN_SCHEDULE:
      return {
        ...state,
        isWithinSchedule: action.payload,
      };
    case UserActionTypes.SET_LAST_HIDE_ACTIVITY:
      return {
        ...state,
        lastHideActivity: action.payload,
      };
    case UserActionTypes.SET_TWITTER_USER:
      return {
        ...state,
        twitterUser: action.payload,
      };
    case UserActionTypes.SET_DEFAULT_CHUNK_SIZE:
      return {
        ...state,
        defaultChunkSize: action.payload,
      };
    case UserActionTypes.SET_LAST_OUTAGE:
      return {
        ...state,
        lastOutage: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
