import React from "react";
import { Avatar, Button, Row, Skeleton, Tooltip, Typography } from "antd";
import { TwitterUser } from "../../types/TwitterUser";
import VolumeOffIcon from "../../icons/VolumeOffIcon";
import { ThemedApp } from "../../theme";

type Props = {
  author?: TwitterUser;
  loading?: boolean;
  interactive?: boolean;
  onMute: () => Promise<void>;
};
const UserPill = ({ author, loading, interactive, onMute }: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const [muting, setMuting] = React.useState(false);
  const handleMute = async () => {
    setMuting(true);
    try {
      await onMute();
    } catch (error) {
      console.error("Failed to mute user", error);
    }
    setMuting(false);
  };
  const formattedFollowers = React.useMemo(() => {
    if (author?.followers_count) {
      const count = author?.followers_count;
      if (count > 1000000) {
        return `${(count / 1000000).toFixed(1)}M`;
      } else if (count > 3000) {
        return `${(count / 1000).toFixed(1)}K`;
      } else {
        return count.toLocaleString();
      }
    }
    return 0;
  }, [author?.followers_count]);
  const canExpand = !loading && interactive;

  return (
    <div className="user-pill-wrapper">
      <div
        className="user-pill"
        onMouseEnter={canExpand ? () => setExpanded(true) : undefined}
        onMouseLeave={
          canExpand
            ? () => setTimeout(() => setExpanded(false), 3000)
            : undefined
        }
        onClick={canExpand ? () => setExpanded(!expanded) : undefined}
      >
        {loading ? (
          <Skeleton.Avatar active />
        ) : (
          <Avatar src={author?.avatar_url} alt="User" className="avatar" />
        )}
        <div className="meta">
          <Row align="middle">
            <Row align="middle">
              <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: 110 }}
                loading={loading}
              >
                <Typography.Text className="name" style={{ margin: 0 }}>
                  {author?.name}
                </Typography.Text>
              </Skeleton>
              {author?.is_verified ? (
                <img
                  src="/img/verified.svg"
                  alt="Verified"
                  width="15"
                  height="15"
                  style={{ marginLeft: 6 }}
                />
              ) : null}
            </Row>
            {!loading && formattedFollowers ? (
              <Typography.Text
                className="smaller-text follower-count space-grotesk"
                style={{ marginLeft: 8 }}
              >
                <span className="count">{formattedFollowers}</span>{" "}
                <span className="label">foll.</span>
              </Typography.Text>
            ) : null}
          </Row>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 1, width: 60 }}
            loading={loading}
            style={{ marginTop: 4 }}
          >
            <Typography.Text
              style={{ margin: 0 }}
              type="secondary"
              className="smaller-text"
            >
              @{author?.handle}
            </Typography.Text>
          </Skeleton>
        </div>
      </div>
      {expanded ? (
        <Typography.Paragraph className="bio smaller-text space-grotesk">
          <ThemedApp version="neutral">
            <Tooltip title="Mute posts from user" placement="top">
              <Button
                type="link"
                shape="circle"
                size="small"
                icon={<VolumeOffIcon style={{ color: "#656565" }} />}
                style={{ backgroundColor: "#f8f8f8", marginRight: 8 }}
                onClick={handleMute}
                loading={muting}
              />
            </Tooltip>
          </ThemedApp>{" "}
          {author?.bio}
        </Typography.Paragraph>
      ) : null}
    </div>
  );
};

export default UserPill;
