import React, { useState } from "react";
import { Steps, Row, Col, Card } from "antd";
import PickTargetAudience from "../../components/PickTargetAudience";
import UploadTrainingData from "../../components/UploadTrainingData/UploadTrainingData";
import TwitterPostingPermission from "../../components/TwitterPostingPermission";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useNavigate, useParams } from "react-router";
import useAPI from "../../hooks/useAPI";
import { setUserInStore } from "../../redux/user/action";
import BillingSubscription from "../../components/BillingSubscription";
import { User } from "../../types/User";
import { Helmet } from "react-helmet";

const { Step } = Steps;

const Onboarding: React.FC = () => {
  const { step } = useParams();
  const index = parseInt(step || "0");
  const [currentStep, setCurrentStep] = useState(0);
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const steps = ["Audience", "Training", "Rights to Post", "Billing"];
  const TOTAL_STEPS = steps.length;
  const goToStep = React.useCallback(
    (step: number, persist = true) => {
      if (persist) API.put(`/users/me`, { lastOnboardingStep: step });
      navigate(`/onboarding/${step}`);
      setCurrentStep(step);
    },
    [API, navigate],
  );
  const handlePrev = React.useCallback(() => {
    setCurrentStep((v) => v - 1);
  }, []);
  const handleNext = React.useCallback(() => {
    if (currentStep === TOTAL_STEPS - 1) {
      // is the last step
      return;
    }

    goToStep(currentStep + 1);
  }, [currentStep, goToStep, TOTAL_STEPS]);
  const loadLatestUser = React.useCallback(async () => {
    try {
      const data = await API.get<User>(`/users/me`);
      dispatch(setUserInStore(data));
      const step = data.last_onboarding_step;
      if (step && !data.onboarded_at) {
        goToStep(step, false);
      }
    } catch (error) {
      console.error("Failed to load user", error);
    }
  }, [API, dispatch, goToStep]);
  React.useEffect(() => {
    if (!isNaN(index) && index >= 0 && index <= TOTAL_STEPS - 1) {
      setCurrentStep(index);
    }
  }, [index, TOTAL_STEPS]);
  React.useEffect(() => {
    loadLatestUser();
  }, [loadLatestUser]);
  React.useEffect(() => {
    if (user?.onboarded_at) {
      console.log("already onboarded!");
      //message.warning("You have already completed onboarding", 3);
      // TOOD: This message shows again after we refresh the user on completion of onboarding
      navigate(`/`);
    }
  }, [user?.onboarded_at, navigate]);

  const onboardingSteps = React.useMemo(() => {
    return [
      <PickTargetAudience onboarding onBack={handlePrev} onNext={handleNext} />,
      <UploadTrainingData onBack={handlePrev} onNext={handleNext} />,
      <TwitterPostingPermission onBack={handlePrev} onNext={handleNext} />,
      <BillingSubscription onBack={handlePrev} onNext={handleNext} />,
    ];
  }, [handleNext, handlePrev]);

  return (
    <div className="settings-container" style={{ padding: 24 }}>
      <Helmet>
        <title>Onboarding - Duome</title>
      </Helmet>
      <Row justify="center">
        <Col md={24} lg={22} xl={19} xxl={15}>
          <Card title="Onboarding">
            <Steps current={currentStep}>
              {steps.map((item) => (
                <Step key={item} title={item} />
              ))}
            </Steps>
            <div style={{ marginTop: 32 }}>{onboardingSteps[currentStep]}</div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Onboarding;
