import React from "react";
import { Form, Button, message, Input } from "antd";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setTwitterSettingInStore } from "../../redux/user/action";
import { User } from "../../types/User";
import { TwitterSettings } from "../../types/TwitterSetting";
import SettingsCard from "../SettingsCard";
import PauseAccount from "../PauseAccount/PauseAccount";
import { Helmet } from "react-helmet";

const UserAccountSettings: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [form] = Form.useForm();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.user?.id)!;
  const email = useAppSelector((state) => state.user.user?.email)!;
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const [data, settings] = await Promise.all([
        API.get<User>(`/users/${userId}`),
        API.get<TwitterSettings>(`/users/${userId}/twitter-settings`),
      ]);
      dispatch(setTwitterSettingInStore(settings));
      form.setFieldsValue(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to load settings", error);
    }
  }, [API, form, userId, dispatch]);
  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      await API.put(`/users/${userId}`, {
        firstname: values.firstname,
        lastname: values.lastname,
        phoneNumber: values.phone_number,
        paused: values.paused,
      });
      setSubmitting(false);
      message.success("Successfully updated profile");
    } catch (error) {
      setSubmitting(false);
      console.error("Failed to save profile", error);
      message.error(
        "Failed to update profile. Please try again later or contact support.",
      );
    }
  };
  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SettingsCard
      title="My Account"
      subtitle="Manage all your account information and profile here."
      loading={loading}
    >
      <Helmet>
        <title>My Account - Duome</title>
      </Helmet>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Email">
          <Input placeholder="smart@duome.ai" value={email} disabled />
        </Form.Item>
        <Form.Item
          name="firstname"
          label="First name"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
            {
              max: 50,
              message: "First name cannot be longer than 50 characters",
            },
            {
              min: 2,
              message: "First name cannot be shorter than 2 characters",
            },
          ]}
        >
          <Input placeholder="Jonathan" />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last name"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
            {
              max: 50,
              message: "Last name cannot be longer than 50 characters",
            },
            {
              min: 2,
              message: "Last name cannot be shorter than 2 characters",
            },
          ]}
        >
          <Input placeholder="Smith" />
        </Form.Item>
        <Form.Item name="phone_number" label="Phone number">
          <Input placeholder="+1 123-456-7890" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Update
          </Button>
        </Form.Item>
      </Form>
      <PauseAccount />
    </SettingsCard>
  );
};

export default UserAccountSettings;
