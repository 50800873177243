import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const SubRightDownIcon = ({ size = 18, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.04167 5.95831L7.33333 8.24998M7.33333 8.24998L9.625 5.95831M7.33333 8.24998V4.67356C7.33333 4.16119 7.33333 3.90461 7.23352 3.70872C7.14564 3.53624 7.00513 3.39611 6.83265 3.30822C6.63656 3.20831 6.38005 3.20831 5.86667 3.20831H1.375"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {...props}
    />
  );
};

export default SubRightDownIcon;
