import React from "react";
import { Row, Col, theme, Typography, Grid } from "antd";
import { Outlet } from "react-router";
import BrandMark from "../BrandMark";
import DuomeLogo from "../../icons/DuomeLogo";
import { Helmet } from "react-helmet";

const { useToken } = theme;

const AuthPageWrapper = () => {
  const { token } = useToken();
  const screens = Grid.useBreakpoint();
  return (
    <Row justify="center" style={{ height: "100vh" }}>
      <Helmet>
        <title>Get access to Duome</title>
      </Helmet>
      <Col
        style={{ backgroundColor: token.colorPrimary, alignItems: "center" }}
        md={12}
        sm={0}
        xs={0}
      >
        <div className="brand-intro">
          <Row style={{ alignSelf: "start" }}>
            {screens.lg ? <BrandMark style={{ height: 28 }} /> : <DuomeLogo />}
          </Row>
          <Col xxl={14} xl={16} lg={18} md={22} className="inner-brand">
            <Typography.Title level={2} className="text">
              Clone yourself and grow your audience faster than ever
            </Typography.Title>
            <img
              src="/img/banner.webp"
              alt="Happy customer"
              className="banner-image"
            />
          </Col>
          <Typography.Text className="text">
            Copyright {new Date().getFullYear()} - All Rights Reserved.
          </Typography.Text>
        </div>
      </Col>
      <Col md={12} sm={13} xs={22}>
        <Row justify="center">
          <Col md={0} xs={24}>
            <Row style={{ alignSelf: "start", marginTop: 8 }}>
              <a href="https://duome.ai">
                <BrandMark textColor="#212121" style={{ height: 28 }} />
              </a>
            </Row>
          </Col>
          <Col
            xxl={11}
            xl={14}
            lg={18}
            md={21}
            style={{ marginTop: "10vh", paddingBottom: "7vh" }}
          >
            <Outlet />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuthPageWrapper;
