import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const ArrowLeftIcon = ({ size = 18, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Arrow / Arrow_Left_MD">
            <path
              id="Vector"
              d="M19 12H5M5 12L11 18M5 12L11 6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      )}
      {...props}
    />
  );
};

export default ArrowLeftIcon;
