import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  message,
  Row,
  Col,
  Divider,
  theme,
} from "antd";
import { useNavigate } from "react-router";
import useAPI from "../../hooks/useAPI";
import { useAppDispatch } from "../../hooks/redux";
import { setUserInStore } from "../../redux/user/action";
import {
  AuthError,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { User } from "../../types/User";
import mixpanel from "mixpanel-browser";
import { ThemedApp } from "../../theme";
import GoogleIcon from "../../icons/GoogleIcon";
import EmailIcon from "../../icons/EmailIcon";
import LockIcon from "../../icons/LockIcon";
import { Link } from "react-router-dom";
import GiftIcon from "../../icons/GiftIcon";
import { Helmet } from "react-helmet";

const { useToken } = theme;

const SignUpV2 = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const API = useAPI();
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const { token } = useToken();
  const [form] = Form.useForm();
  const { timeZone: timezone } = Intl.DateTimeFormat().resolvedOptions();
  const handleRegisterToBackend = async (
    token: string,
    profile: Record<string, any> = {},
  ) => {
    try {
      const referralCode = localStorage.getItem("referralCode");
      const data = await API.post<User>("/auth/firebase", {
        token,
        timezone,
        referralCode,
        ...profile,
      });
      localStorage.removeItem("referralCode");
      dispatch(setUserInStore(data!));
      message.success(`Welcome in, ${data!.firstname}`);
      if (!data?.onboarded_at) {
        navigate("/onboarding");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Failed to login to backend API", error);
      throw error;
    }
  };
  const handleSignUpWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope("openid");
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
      const credential = await signInWithPopup(auth, provider);
      const idToken = await credential.user.getIdToken();
      await handleRegisterToBackend(idToken);
      mixpanel.track("register", {
        method: "google",
      });
      // @ts-ignore
      window.gtag?.("event", "sign_up", {
        app_name: "duome",
        screen_name: "SignUp",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const err = error as AuthError;
      console.error("Failed to register", err);
      if (err.code === "auth/invalid-credential") {
        form.setFields([
          {
            name: "password",
            errors: ["Invalid credentials provided"],
          },
        ]);
      } else {
        message.error("Register request was unsuccessful. Please try again");
      }
    }
  };
  const handleRegister = async (values: any) => {
    try {
      setLoading(true);
      const credential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password,
      );
      const idToken = await credential.user.getIdToken();
      await handleRegisterToBackend(idToken, {
        firstname: values.firstname,
        lastname: values.lastname,
      });
      setLoading(false);
      mixpanel.track("register", {
        method: "vanilla",
      });
    } catch (error) {
      setLoading(false);
      const err = error as AuthError;
      console.error("Failed to register", err);
      if (err.code === "auth/invalid-credential") {
        form.setFields([
          {
            name: "password",
            errors: ["Invalid credentials provided"],
          },
        ]);
      } else {
        message.error("Register request was unsuccessful. Please try again");
      }
    }
  };
  const promoCode = localStorage.getItem("referralCode");
  const TERMS_URL = "https://app.duome.ai/terms.html";
  const PRIVACY_URL = "https://app.duome.ai/privacy.html";

  return (
    <div>
      <Helmet>
        <title>Sign Up - Duome</title>
      </Helmet>
      <Typography.Title style={{ textAlign: "center", marginBottom: 48 }}>
        Create your account
      </Typography.Title>
      <ThemedApp version="neutral">
        <Button
          icon={<GoogleIcon size={18} style={{ fontSize: 24 }} />}
          onClick={handleSignUpWithGoogle}
          block
          type="primary"
          size="large"
          style={{ color: "#7b7b7b" }}
        >
          Sign up with Google
        </Button>
      </ThemedApp>
      <Divider plain>or</Divider>
      <Form form={form} onFinish={handleRegister} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstname"
              label="First name:"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                },
                {
                  max: 50,
                  message: "First name cannot be longer than 50 characters",
                },
                {
                  min: 2,
                  message: "First name cannot be shorter than 2 characters",
                },
              ]}
            >
              <Input
                type="text"
                name="firstname"
                placeholder="Aubrey"
                maxLength={50}
                required
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastname"
              label="Last name:"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name",
                },
                {
                  max: 50,
                  message: "Last name cannot be longer than 50 characters",
                },
                {
                  min: 2,
                  message: "Last name cannot be shorter than 2 characters",
                },
              ]}
            >
              <Input
                type="text"
                name="lastname"
                placeholder="McRunmill"
                maxLength={50}
                required
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="email"
          label="Email:"
          rules={[
            { required: true, message: "Please enter your email" },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input
            type="email"
            name="email"
            placeholder="john.doe@gmail.com"
            prefix={<EmailIcon style={{ color: token.colorTextSecondary }} />}
            maxLength={80}
            required
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password:"
          rules={[
            { required: true, message: "Please enter your password" },
            { min: 8, message: "Password must be at least 8 characters long" },
          ]}
        >
          <Input.Password
            placeholder="Password"
            name="password"
            required
            prefix={<LockIcon style={{ color: token.colorTextSecondary }} />}
          />
        </Form.Item>
        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Typography.Text className="smaller-text">
              By signing up, you agree to our{" "}
              <a href={TERMS_URL} target="_blank" rel="noreferrer">
                <Typography.Link className="smaller-text">
                  Terms of Service
                </Typography.Link>
              </a>{" "}
              and{" "}
              <a href={PRIVACY_URL} target="_blank" rel="noreferrer">
                <Typography.Link className="smaller-text">
                  Privacy Policy
                </Typography.Link>
              </a>
            </Typography.Text>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="large"
            style={{ marginTop: 16 }}
          >
            Sign Up
          </Button>
        </Form.Item>
        {promoCode ? (
          <Form.Item>
            <Typography.Text className="smaller-text">
              <GiftIcon
                size={14}
                style={{ marginRight: 8, color: token.colorPrimary }}
              />
              Your referral credit will be applied to your account.
            </Typography.Text>
          </Form.Item>
        ) : null}
        <Row style={{ marginTop: 8 }}>
          <Col>
            <Typography.Text>
              Already have an account?{" "}
              <Link to="/login">
                <Typography.Link strong>Log in</Typography.Link>
              </Link>
            </Typography.Text>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SignUpV2;
