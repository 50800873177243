import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

type Props = {
  size?: number;
} & Partial<CustomIconComponentProps>;
const GoogleIcon = ({ size = 16, ...props }: Props) => {
  return (
    <Icon
      component={() => (
        <svg
          width={size}
          height={size}
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3 10.7083C19.3 10.0583 19.2417 9.43333 19.1333 8.83333H10.5V12.3792H15.4333C15.2208 13.525 14.575 14.4958 13.6042 15.1458V17.4458H16.5667C18.3 15.85 19.3 13.5 19.3 10.7083Z"
            fill="#4285F4"
          />
          <path
            d="M10.4998 19.6667C12.9748 19.6667 15.0498 18.8458 16.5664 17.4458L13.6039 15.1458C12.7831 15.6958 11.7331 16.0208 10.4998 16.0208C8.11227 16.0208 6.09144 14.4083 5.3706 12.2417H2.30811V14.6167C3.81644 17.6125 6.91644 19.6667 10.4998 19.6667Z"
            fill="#34A853"
          />
          <path
            d="M5.371 12.2417C5.18766 11.6917 5.0835 11.1042 5.0835 10.5C5.0835 9.89583 5.18766 9.30833 5.371 8.75833V6.38333H2.3085C1.66683 7.66071 1.33293 9.07051 1.3335 10.5C1.3335 11.9792 1.68766 13.3792 2.3085 14.6167L5.371 12.2417Z"
            fill="#FBBC05"
          />
          <path
            d="M10.4998 4.97916C11.8456 4.97916 13.0539 5.44166 14.0039 6.34999L16.6331 3.72083C15.0456 2.24166 12.9706 1.33333 10.4998 1.33333C6.91644 1.33333 3.81644 3.38749 2.30811 6.38333L5.37061 8.75833C6.09144 6.59166 8.11227 4.97916 10.4998 4.97916Z"
            fill="#EA4335"
          />
        </svg>
      )}
      {...props}
    />
  );
};

export default GoogleIcon;
