import React from "react";
import { Button, Card, Space, Typography } from "antd";
import useAPI from "../../hooks/useAPI";
import { ExportOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

const ManageBilling: React.FC = () => {
  const API = useAPI();
  const [loading, setLoading] = React.useState(false);
  const [portalUrl, setPortalUrl] = React.useState<string | null>(null);
  const createSession = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get("/billing/session/portal");
      setPortalUrl(data.url);
      setLoading(false);
    } catch (error) {
      console.error("Failed to create stripe session", error);
      setLoading(false);
    }
  }, [API]);
  React.useEffect(() => {
    createSession();
  }, [createSession]);

  return (
    <Card title="Manage Billing">
      <Helmet>
        <title>Manage Billing - Duome</title>
      </Helmet>
      <Space direction="vertical" align="center" style={{ width: "100%" }}>
        <a href={portalUrl || "#"}>
          <Button
            type="primary"
            size="large"
            icon={<ExportOutlined />}
            loading={loading}
            disabled={!portalUrl}
          >
            Open Billing Portal
          </Button>
        </a>
        {!loading && !portalUrl ? (
          <div>
            <Typography.Paragraph type="danger">
              Sorry, we were unable to load your billing portal link. Please try
              again or contact support if issues persist.
            </Typography.Paragraph>
            <Button onClick={createSession}>Reload</Button>
          </div>
        ) : (
          <div>
            <Typography.Paragraph>
              The button will take you to Stripe where you can manage all your
              billing and subscription settings.
            </Typography.Paragraph>
          </div>
        )}
      </Space>
    </Card>
  );
};

export default ManageBilling;
