import React from "react";
import { Grid } from "@giphy/react-components";
import { GifResult, GiphyFetch } from "@giphy/js-fetch-api";
import { Button, Form, Input, Row } from "antd";
import { useInterval } from "../../hooks/useInterval";
import { AttachedGif } from "../../types/AttachedGif";
import { CreationMode } from "../../types/CreationMode";

const { REACT_APP_GIPHY_API_KEY: apiKey } = process.env;
const gf = new GiphyFetch(apiKey!);

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (result: CreationMode<AttachedGif>) => void;
};
const GifSelectorModal = ({ open, onClose, onSelect }: Props) => {
  const [width, setWidth] = React.useState(500);
  const [query, setQuery] = React.useState("rick roll");
  const [input, setInput] = React.useState("");
  const fetchGifs = (offset: number) => {
    return gf.search(query, { lang: "en", sort: "relevant", limit: 10 });
  };
  const handleSelect = (
    gif: GifResult["data"],
    e: React.SyntheticEvent<HTMLElement, Event>,
  ) => {
    const { size, url } = gif.images.downsized;
    e.preventDefault();
    onSelect({
      query,
      bytes_size: Number(size),
      url,
    });
    onClose();
  };
  const onSearch = (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined,
  ) => {
    event?.preventDefault();
    setQuery(value);
  };
  useInterval(() => {
    const tweetItem = document.querySelector(".tweet-item .final-footer");
    if (tweetItem) {
      setWidth(tweetItem.clientWidth - 48);
    }
  }, 1000);
  React.useEffect(() => {
    if (!open) {
      setQuery("rick roll");
      setInput("");
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <div style={{ padding: "0 24px", paddingBottom: 24 }}>
      <Row justify="space-between">
        <Form.Item name="search">
          <Input.Search
            placeholder="Search GIFs"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onSearch={onSearch}
            defaultValue={input}
            name="new-gif-search"
            autoComplete="one-time-code"
          />
        </Form.Item>
        <Button onClick={onClose}>Close</Button>
      </Row>
      <Grid
        key={query}
        width={width}
        columns={3}
        fetchGifs={fetchGifs}
        onGifClick={handleSelect}
        hideAttribution
      />
    </div>
  );
};

export default GifSelectorModal;
