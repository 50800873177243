import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import React from "react";
import RevertButton from "../RevertButton";
import { useAppSelector } from "../../hooks/redux";
import useAPI from "../../hooks/useAPI";
import { TweetReply } from "../../types/TweetReply";
import GifSelectorModal from "../GifSelectorModal/GifSelectorModal";
import GifIcon from "../../icons/GifIcon";
import mixpanel from "mixpanel-browser";
import { AttachedGif } from "../../types/AttachedGif";
import { CreationMode } from "../../types/CreationMode";

const { Text } = Typography;

type Props = {
  tweet?: Pick<
    TweetReply,
    | "id"
    | "user_category"
    | "ai_response"
    | "user_response"
    | "ai_category"
    | "attachedGif"
  >;
  disabled?: boolean;
  onUpdate?: (tweet: Partial<TweetReply>) => void;
};
const TweetReplyForm = ({ tweet, onUpdate, disabled }: Props) => {
  const [showRevert, setShowRevert] = React.useState(false);
  const [regenerating, setRegenerating] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [persisting, setPersisting] = React.useState(false);
  const [highlightField, setHighlightField] = React.useState(false);
  const [showGifModal, setShowGifModal] = React.useState(false);
  const [previewGifUrl, setPreviewGifUrl] = React.useState(
    tweet?.attachedGif?.url || "",
  );
  const [beforeRevert, setBeforeRevert] = React.useState("");
  const [form] = Form.useForm();
  const twitterUser = useAppSelector((state) => state.user.twitterUser);
  const API = useAPI();
  const tweetId = tweet?.id;
  const handleRegenerate = async () => {
    try {
      setRegenerating(true);
      setShowRevert(false);
      setBeforeRevert(form.getFieldValue("response"));
      const data = await API.post(`/replies/${tweetId}/regenerate`, {
        category: tweet?.user_category || tweet?.ai_category,
      });
      onUpdate?.({
        ai_response: data.aiResponse,
        user_response: null,
      });
      form.setFieldValue("response", data.aiResponse);
      setRegenerating(false);
      setShowRevert(true);
      setHighlightField(true);
      setTimeout(() => setHighlightField(false), 1000);
    } catch (error) {
      setRegenerating(false);
      console.error("Failed to regenerate tweet reply", error);
    }
  };
  const onRevertTimeout = React.useCallback(() => {
    setShowRevert(false);
    setBeforeRevert("");
  }, []);
  const persistUpdate = React.useCallback(
    (data: Partial<TweetReply>, showAlert: boolean) => {
      const fieldValue = form.getFieldValue("response");
      const updatedValue = data?.user_response || data?.ai_response;
      if (updatedValue !== fieldValue) {
        return;
      }

      onUpdate?.(data);
      if (showAlert) {
        messageApi.success("Successfully updated the reply!", 2);
      }
    },
    [form, onUpdate, messageApi],
  );
  const handleAttachGif = React.useCallback(
    async (attached_gif: CreationMode<AttachedGif>) => {
      mixpanel.track("attached_gif", { query: attached_gif.query });
      setPreviewGifUrl(attached_gif.url);

      try {
        const data = await API.put(`/replies/${tweetId}/gif`, {
          url: attached_gif.url,
          query: attached_gif.query,
          bytesSize: attached_gif.bytes_size || null,
        });
        onUpdate?.(data);
      } catch (error) {
        console.error("Failed to attach GIF", error);
        messageApi.error("Failed to attach GIF");
        setPreviewGifUrl("");
      }
    },
    [API, tweetId, onUpdate, messageApi],
  );
  const handleUpdate = React.useCallback(
    async (values: any, showAlert = true) => {
      const modified = tweet?.ai_response !== values.response;
      if (!modified) {
        return;
      }

      try {
        setPersisting(true);
        setShowRevert(false);
        setHighlightField(true);
        values.modified = true;
        const data = await API.put(`/replies/${tweetId}/response`, values);
        delete data.loaded_at;
        persistUpdate?.(data, showAlert);
        setPersisting(false);
        setTimeout(() => setHighlightField(false), 2000);
      } catch (error) {
        setPersisting(false);
        setHighlightField(false);
        console.error("Failed to update tweet reply", error);
      }
    },
    [API, tweetId, tweet?.ai_response, persistUpdate],
  );
  const handleRevert = React.useCallback(() => {
    setShowRevert(false);
    form.setFieldsValue({ response: beforeRevert });
    handleUpdate({ response: beforeRevert }, false);
    onUpdate?.({
      ai_response: beforeRevert,
      user_response: null,
    });
    setBeforeRevert("");
    messageApi.success("Reverted to the previous response");
  }, [beforeRevert, form, messageApi, onUpdate, handleUpdate]);
  React.useEffect(() => {
    const content = tweet?.user_response || tweet?.ai_response;
    if (content) {
      form.setFieldsValue({
        response: content,
      });
    }
  }, [form, tweet?.user_response, tweet?.ai_response]);

  return (
    <Form
      form={form}
      disabled={disabled}
      className="reply-form-container"
      onFinish={handleUpdate}
      onFieldsChange={(changed) => {
        const hasErrors = changed.some(
          (field) => (field.errors?.length || 0) > 0,
        );
        setHasError(hasErrors);
      }}
    >
      <Row
        className={`editor-area ${
          hasError ? "has-error" : ""
        } highlight-field-v2 ${highlightField || persisting ? "active" : ""}`}
      >
        <div className="user-avatar-container">
          <div
            style={{
              width: 1,
              height: 8,
              backgroundColor: "#DDDDDD",
            }}
          />
          <Avatar
            src={twitterUser?.avatar_url}
            size={35}
            style={{ backgroundColor: "gray", border: "1px solid #dddddd" }}
          />
          <div
            style={{
              width: 1,
              flex: 1,
              minHeight: 18,
              backgroundColor: "#DDDDDD",
            }}
          />
        </div>
        <div className="editor-inner">
          <Row align="middle">
            <Text strong className="smaller-text">
              {twitterUser?.name || "Twitter User"}
            </Text>
            <Text
              className="space-grotesk smaller-text"
              type="secondary"
              style={{ marginLeft: 8 }}
            >
              @{twitterUser?.handle || "twitteruser"}
            </Text>
          </Row>
          <Col style={{ flex: 1, width: "100%" }}>
            <Form.Item
              name="response"
              style={{ margin: 0, width: "100%" }}
              required
              rules={[
                {
                  required: true,
                  message: "A reply message is required.",
                },
              ]}
            >
              <Input.TextArea
                variant="borderless"
                placeholder="Write a reply..."
                showCount
                maxLength={260}
                autoSize={{ minRows: 2 }}
                onBlur={() => form.submit()}
                disabled={disabled}
              />
            </Form.Item>

            {previewGifUrl ? (
              <Row>
                <img src={previewGifUrl} alt="GIF" className="user-gif" />
              </Row>
            ) : null}
          </Col>
        </div>
      </Row>
      <Row
        align="middle"
        justify="space-between"
        className="footer final-footer"
      >
        <Space align="center">
          <Button
            type="primary"
            onClick={handleRegenerate}
            loading={regenerating}
            disabled={disabled}
          >
            Re-generate
          </Button>
          {showRevert ? (
            <RevertButton onClick={handleRevert} onExpire={onRevertTimeout} />
          ) : null}
          <Button
            type="text"
            shape="circle"
            icon={
              <GifIcon
                size={24}
                style={{ fill: showGifModal ? "#212121" : "#7B7B7B" }}
              />
            }
            onClick={() => setShowGifModal(!showGifModal)}
          />
        </Space>
        <Typography.Text
          type="secondary"
          className="smaller-text space-grotesk"
        >
          {tweet?.user_response ? "User" : "AI"} written
        </Typography.Text>
      </Row>
      {contextHolder}
      <GifSelectorModal
        open={showGifModal}
        onClose={() => setShowGifModal(false)}
        onSelect={handleAttachGif}
      />
    </Form>
  );
};

export default TweetReplyForm;
