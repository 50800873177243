import React from "react";
import { Layout, Menu, MenuProps } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Helmet } from "react-helmet";

type Props = {
  path: string;
};
const EngagementSettings = ({ path }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const items: MenuProps["items"] = [
    {
      key: "#who-to-engage",
      label: "Who to engage",
      children: [
        {
          key: "audience",
          label: "Target audience",
        },
        {
          key: "twitter-list",
          label: "Twitter list",
        },
        {
          key: "blacklists",
          label: "Blacklists",
        },
      ],
    },
    {
      key: "#how-to-engage",
      label: "How to engage",
      children: [
        {
          key: "response-categories",
          label: "Response strategy",
        },
        {
          key: "writing-rules",
          label: "Writing rules",
        },
      ],
    },
    {
      key: "schedule",
      label: "Schedule",
    },
    {
      key: "automations",
      label: "Automations",
    },
  ];
  const handleNavigate = (key: string) => {
    if (key.startsWith("#")) return;
    navigate(`/${path}/${key}`);
  };

  return (
    <div>
      <Layout>
        <Helmet>
          <title>Settings - Duome</title>
        </Helmet>
        <Layout.Sider
          breakpoint="md"
          collapsedWidth={0}
          width={300}
          style={{ height: "100vh" }}
        >
          <Menu
            mode="inline"
            style={{ height: "100%", borderRight: 0, paddingTop: 40 }}
            selectedKeys={[location.pathname.split("/").pop() || ""]}
            onClick={({ key }) => handleNavigate(key)}
            items={items}
          />
        </Layout.Sider>
        <Layout.Content className="layout-container settings-container">
          <Outlet />
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default EngagementSettings;
