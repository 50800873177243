import React, { useState } from "react";
import useAPI from "../../hooks/useAPI";
import {
  Button,
  Col,
  Divider,
  Layout,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { BatchAnalytic } from "../../types/BatchAnalytic";
import moment from "moment";
import { useAppSelector } from "../../hooks/redux";
import { User } from "../../types/User";
import UserSelect from "../../components/UserSelect";
import { ReplyMetric } from "../../types/ReplyMetric";
import BatchQualityChart from "../../components/BatchQualityChart";
import ReplyMetrics from "../../components/ReplyMetrics";
import { Helmet } from "react-helmet";

export type AnalyticPerDay = Record<
  string,
  Required<
    Pick<
      BatchAnalytic,
      | "replies_generated_count"
      | "sent_count"
      | "ai_approved_count"
      | "removed_by_user_count"
      | "edited_by_user_count"
      | "approved_for_posting_count"
    > & {
      date: string;
    }
  >
>;
const Analytics = () => {
  const API = useAPI();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BatchAnalytic[]>([]);
  const [replies, setReplies] = useState<ReplyMetric[]>([]);
  const user = useAppSelector((state) => state.user.user);
  const userId = user?.id;
  const loadReplyData = React.useCallback(async () => {
    try {
      const data = await API.get<ReplyMetric[]>(
        `/users/${userId}/reply-metrics`,
      );
      setReplies(data);
    } catch (error) {
      console.error("Failed to load reply metrics", error);
    }
  }, [API, userId]);
  const sumOfData = React.useMemo(() => {
    // combine all the counts into one object
    const sum = replies.reduce(
      (acc, item) => {
        return {
          like_count: acc.like_count + item.like_count,
          retweet_count: acc.retweet_count + item.retweet_count,
          impression_count: acc.impression_count + item.impression_count,
          reply_count: acc.reply_count + item.reply_count,
          profile_clicks: acc.profile_clicks + item.profile_clicks,
        };
      },
      {
        like_count: 0,
        retweet_count: 0,
        impression_count: 0,
        reply_count: 0,
        profile_clicks: 0,
      },
    );
    return sum;
  }, [replies]);
  const startDate = replies?.[0]?.created_at;
  const endDate = replies?.[replies.length - 1]?.created_at;
  const loadData = React.useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.get<BatchAnalytic[]>(
        `/users/${user?.id}/analytics`,
      );
      await loadReplyData();
      setData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = err as any;
      const status = error.response?.status;
      if (status !== 401) {
        message.error("Sorry something went wrong. Please try again later.");
      }
      console.error("Failed to load analytics data", error);
    }
  }, [API, user?.id, loadReplyData]);
  const loadDataForUser = React.useCallback(
    async (targetUser: User) => {
      try {
        const userId = targetUser.id;
        setLoading(true);
        const data = await API.get<BatchAnalytic[]>(
          `/users/${userId}/analytics`,
        );
        setData(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const error = err as any;
        const status = error.response?.status;
        if (status !== 401) {
          message.error("Sorry something went wrong. Please try again later.");
        }
        console.error("Failed to load analytics data", error);
      }
    },
    [API],
  );
  React.useEffect(() => {
    loadData();
  }, [loadData]);
  const groupByDay = React.useCallback(
    (data: BatchAnalytic[], maxDays?: number) => {
      const repliesByDay: AnalyticPerDay = {};
      data.forEach((item) => {
        const date = moment(item.created_at).format("YYYY-MM-DD");
        if (!repliesByDay[date]) {
          repliesByDay[date] = {
            date,
            replies_generated_count: 0,
            sent_count: 0,
            removed_by_user_count: 0,
            ai_approved_count: 0,
            edited_by_user_count: 0,
            approved_for_posting_count: 0,
          };
        }
        repliesByDay[date].replies_generated_count +=
          item.replies_generated_count || 0;
        repliesByDay[date].sent_count += item.sent_count || 0;
        repliesByDay[date].removed_by_user_count +=
          item.removed_by_user_count || 0;
        repliesByDay[date].edited_by_user_count +=
          item.edited_by_user_count || 0;
        repliesByDay[date].ai_approved_count += item.ai_approved_count || 0;
        repliesByDay[date].approved_for_posting_count +=
          item.approved_for_posting_count || 0;
      });

      if (maxDays && Object.keys(repliesByDay).length > maxDays) {
        // only keep the last maxDays
        const keys = Object.keys(repliesByDay);
        const sortedKeys = keys.sort((a, b) => moment(b).diff(moment(a)));
        const lastKeys = sortedKeys.slice(0, maxDays);
        const lastKeysSet = new Set(lastKeys);
        return Object.entries(repliesByDay).reduce((acc, [key, value]) => {
          if (lastKeysSet.has(key)) {
            acc[key] = value;
          }
          return acc;
        }, {} as AnalyticPerDay);
      }

      return repliesByDay;
    },
    [],
  );
  const showReplies =
    user?.usage_mode === "active" || user?.usage_mode === "refining_responses";

  return (
    <Layout.Content style={{ padding: 16 }}>
      <Helmet>
        <title>Analytics - Duome</title>
      </Helmet>
      <Space align="baseline">
        <Typography.Title level={2}>Analytics</Typography.Title>
        {user?.isAdmin ? (
          <UserSelect
            placeholder="View analytics for a user"
            onSelect={loadDataForUser}
          />
        ) : null}
        <Button
          onClick={loadData}
          loading={loading}
          style={{ marginBottom: "0.5em" }}
        >
          Refresh
        </Button>
      </Space>
      {showReplies ? (
        <div>
          <Typography.Title level={3}>Model Performance</Typography.Title>
          <Typography.Paragraph>
            This section is about capacities of your AI clone. It aims to answer
            the following questions: How good are the replies it generates? How
            much editing is required to make them satisfactory?
          </Typography.Paragraph>
          <Divider />
          <Row>
            <Col lg={12} xs={24}>
              <ReplyMetrics
                data={sumOfData}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>

            <Col lg={12} xs={24}>
              <BatchQualityChart
                card={false}
                data={data}
                groupByDay={groupByDay}
              />
            </Col>
          </Row>
        </div>
      ) : null}
    </Layout.Content>
  );
};

export default Analytics;
