import React from "react";
import { Col, Row, Statistic, Typography } from "antd";
import { ReplyMetric } from "../../types/ReplyMetric";
import {
  EyeOutlined,
  HeartOutlined,
  MessageOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Paragraph } = Typography;

type Props = {
  data: Pick<
    ReplyMetric,
    | "like_count"
    | "retweet_count"
    | "impression_count"
    | "reply_count"
    | "profile_clicks"
  >;
  startDate?: string;
  endDate?: string;
};
const ReplyMetrics = ({ data, startDate, endDate }: Props) => {
  return (
    <div>
      <Typography.Title level={4} style={{ marginTop: 0, marginBottom: 12 }}>
        Twitter Metrics
      </Typography.Title>
      <Paragraph>
        From <strong>{moment(startDate).format("YYYY-MM-DD")}</strong> to{" "}
        <strong>{moment(endDate).format("YYYY-MM-DD")}</strong>, your AI clone
        has helped you earn the following engagement:
      </Paragraph>
      <Row>
        <Col span={12}>
          <Statistic
            title="Impressions"
            value={data.impression_count}
            prefix={<EyeOutlined />}
          />
        </Col>
        {/*<Col span={12}>
          <Statistic
            title="Profile visits"
            value={84}
            valueStyle={{ color: "#3f8600" }}
            prefix={<ArrowUpOutlined />}
          />
  </Col>*/}
        <Col span={12}>
          <Statistic
            title="Replies"
            value={data.reply_count}
            prefix={<MessageOutlined />}
          />
        </Col>

        <Col span={12}>
          <Statistic
            title="New followers"
            value="TBD"
            prefix={<UsergroupAddOutlined />}
          />
        </Col>

        <Col span={12}>
          <Statistic
            title="Likes"
            value={data.like_count}
            prefix={<HeartOutlined />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReplyMetrics;
