import React from "react";
import { Progress, Row, Skeleton, Typography, theme } from "antd";
import startCase from "lodash/startCase";

const { useToken } = theme;

export type QuotaType = "daily" | "weekly";
export type QuotaConsumptionRange = "good" | "warning" | "danger" | "maxed";
type Props = {
  count?: number;
  max?: number;
  type: QuotaType;
  loading?: boolean;
};
const QuotaBar = ({ count = 0, max = 1, type, loading }: Props) => {
  const { token } = useToken();
  const percent = Math.round((count / max) * 100);
  const range: QuotaConsumptionRange = React.useMemo(() => {
    if (percent < 60) {
      return "good";
    } else if (percent < 80) {
      return "warning";
    } else if (percent < 100) {
      return "danger";
    } else {
      return "maxed";
    }
  }, [percent]);
  const description = React.useMemo(() => {
    const currentPeriod = type === "daily" ? "today" : "this week";
    const nextPeriod = type === "daily" ? "new day" : "new week";
    if (range === "good") {
      return `Still able to generate more replies ${currentPeriod}`;
    } else if (range === "warning") {
      return `Approaching the limit for ${currentPeriod}`;
    } else if (range === "danger") {
      return `Quota is close to hit the limit. Once there, new replies will not be added until a ${nextPeriod} starts`;
    } else {
      return `Quota is maxed out. New replies will not be added until a ${nextPeriod} starts`;
    }
  }, [range, type]);
  const barColor = React.useMemo(() => {
    if (range === "good") {
      return token.colorPrimary;
    } else if (range === "warning") {
      return "#005E10";
    } else {
      return token.colorTextBase;
    }
  }, [range, token]);

  return (
    <div className="quota-bar">
      <Skeleton
        round
        paragraph={false}
        loading={loading}
        active
        style={{ marginBottom: 4, marginTop: 4 }}
      >
        <Progress
          percent={percent}
          strokeColor={barColor}
          trailColor="#DDD"
          showInfo={false}
        />
      </Skeleton>
      <Row justify="space-between" className="quota-explained" align="middle">
        <Typography.Text type="secondary">
          {startCase(type)} quota
        </Typography.Text>
        <Skeleton
          loading={loading}
          paragraph={false}
          avatar={false}
          active
          style={{ width: 100 }}
        >
          <Typography.Text
            type={
              range === "maxed" || range === "danger" ? undefined : "secondary"
            }
            className={`smaller-text range-${range}`}
            style={{ fontWeight: 500 }}
          >
            {count}/{max} replies
          </Typography.Text>
        </Skeleton>
      </Row>
      <Skeleton
        loading={loading}
        paragraph={false}
        active
        style={{ marginTop: 4 }}
      >
        <Typography.Text
          type={
            range === "maxed" || range === "danger" ? undefined : "secondary"
          }
          className={`smaller-text description range-${range}`}
        >
          {description}
        </Typography.Text>
      </Skeleton>
    </div>
  );
};

export default QuotaBar;
